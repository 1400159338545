import NotAuthorisedImg from '../../assets/illustrations/403NotAuthorised.png';
import './styles.scss';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const NotAuthorised = () => {
    const { l } = useLocale();
    useTitle(l('_error'));

    return (
        <div className="h-100 flex-grow-1 position-relative">
            <div className="d-flex align-items-center flex-column justify-content-center text-center mt-5">
                <img
                    src={NotAuthorisedImg as string}
                    alt={l('_laptopNotWorking')}
                    className="main-image"
                />
                <h1 className="px-2 py-1 mt-2">{l('_accessDenied')}</h1>
                <p>{l('_accessDeniedDescription')} </p>
            </div>
        </div>
    );
};

export default NotAuthorised;
