import { useState } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import SuperAdminCreateBusinessRuleForm, {
    FormValues,
} from './SuperAdminCreateBusinessRuleForm';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import {
    PostV1BusinessRuleMutationError,
    getGetV1BusinessRuleQueryKey,
    usePostV1BusinessRule,
} from '../../api/generated/endpoint';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import { useQueryClient } from '@tanstack/react-query';

interface ISuperAdminCreateBusinessRuleModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
}

const SuperAdminCreateBusinessRuleModal = (
    props: ISuperAdminCreateBusinessRuleModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createNewBusinessRule = usePostV1BusinessRule();
    const queryClient = useQueryClient();

    const postNewBusinessRuleRequest = (values: FormValues) => {
        createNewBusinessRule.mutate(
            {
                data: {
                    name: values.name,
                    rules: values.rules,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_businessRuleCreated'),
                        'success'
                    );

                    props.setIsVisible(false);

                    queryClient.invalidateQueries(
                        getGetV1BusinessRuleQueryKey()
                    );
                },
                onError: (error: PostV1BusinessRuleMutationError) => {
                    showGenericErrorToast(error);
                    props.setIsVisible(false);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_createNewBusinessRule')}
                        modalStyle={{
                            width: '75vw',
                            maxWidth: '750px',
                            minWidth: '500px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        closeOnPressEscape
                        onCancel={() => props.setIsVisible(false)}
                    >
                        <SuperAdminCreateBusinessRuleForm
                            submitFunc={postNewBusinessRuleRequest}
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminCreateBusinessRuleModal;
