import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Constants';
import GenericErrorImage from '../../assets/illustrations/GenericError.png';
import './styles.scss';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const GenericError = ({
    message,
    displayHomeBtn,
}: {
    message: string;
    displayHomeBtn?: boolean;
}) => {
    const navigate = useNavigate();
    const { l } = useLocale();
    useTitle(l('_anUnexpectedErrorHasOccurred'));

    return (
        <div className="h-100 position-relative">
            <div className="d-flex align-items-center flex-column justify-content-center text-center mt-3">
                <img
                    src={GenericErrorImage as string}
                    alt={l('_laptopNotWorking')}
                    className="w-75"
                />
                <h1 className="px-2 py-1 mt-2">
                    {l('_anUnexpectedErrorHasOccurred')}
                </h1>
                <p>{message}</p>
                {(displayHomeBtn === true || displayHomeBtn === null) && (
                    <div className="d-flex">
                        <Button onClick={() => navigate(AppRoutes.HomePage)}>
                            <i className="Appkit4-icon icon-home-fill" />
                            {l('_home')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default GenericError;
