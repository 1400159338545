import React, { useState, FC } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { AppRoutes } from '../../Constants';
import Roles from '../../auth/roles';
import useLocale from '../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../api/generated/endpoint';

type AdminTabsProps = { children?: JSX.Element };

const AdminTabs: FC<AdminTabsProps> = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { l } = useLocale();

    const defaultTabIndex = 0;

    const [activeTab, setActiveTab] = useState<number>(() => {
        switch (location.pathname) {
            case AppRoutes.UserManagement:
                return 0;
            case AppRoutes.BusinessUnitManagement:
                return 1;
            case AppRoutes.BusinessRuleManagement:
                return 2;
            default:
                return -1;
        }
    });

    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <>
            {/* Only super admin users have access to the tabs to navigate between user management and business unit management */}
            {userData && userData.roleId >= Roles.SuperAdminId && (
                <>
                    <h1>{l('_adminPanel')}</h1>
                    <Tabs
                        type="underline"
                        stretched={false}
                        activeIndex={activeTab}
                        defaultActiveIndex={defaultTabIndex}
                        onTabChange={(i: number, value: string) => {
                            setActiveTab(i);
                            navigate(value);
                        }}
                        responsive
                        showBothIndicators
                        className="mb-6 mt-2"
                    >
                        <Tab
                            label={l('_userManagementTitleCase')}
                            value={AppRoutes.UserManagement}
                        />

                        <Tab
                            label={l('_businessUnitManagementTitleCase')}
                            value={AppRoutes.BusinessUnitManagement}
                        />

                        <Tab
                            label={l('_businessRuleManagementTitleCase')}
                            value={AppRoutes.BusinessRuleManagement}
                        />
                    </Tabs>
                </>
            )}

            {children || <Outlet />}
        </>
    );
};

export default AdminTabs;
