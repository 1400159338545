import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { FC } from 'react';
import useLocale from '../locale/useLocale';

interface DeleteHistoricalAllocationModal {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    onDelete: () => void;
    selectedAllocationNames: string[];
}

const DeleteHistoricalAllocationModal: FC<DeleteHistoricalAllocationModal> = (
    props
) => {
    const { l } = useLocale();

    const handleDeleteClick = () => {
        props.setIsVisible(false);
        props.onDelete();
    };

    const renderModalTitle = () => {
        if (props.selectedAllocationNames.length === 1) {
            return l('_deleteHistoricalAllocationModalTitle');
        } else {
            return l('_deleteHistoricalAllocationsModalTitle');
        }
    };

    const renderModalText = () => {
        if (props.selectedAllocationNames.length === 1) {
            return l('_deleteHistoricalAllocationModalText');
        } else {
            return l('_deleteHistoricalAllocationsModalText');
        }
    };

    const renderAllocationList = () => {
        const MAX_DISPLAY_COUNT = 5;

        const displayedAllocations = props.selectedAllocationNames.slice(
            0,
            MAX_DISPLAY_COUNT
        );
        const remainingCount =
            props.selectedAllocationNames.length - MAX_DISPLAY_COUNT;

        return (
            <>
                <ul>
                    {displayedAllocations.map((fileName, id) => (
                        <li key={id}>{fileName}</li>
                    ))}
                </ul>
                {props.selectedAllocationNames.length > 5 && (
                    <p> and {remainingCount} more.</p>
                )}
            </>
        );
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible={props.isVisible}
                    title={renderModalTitle()}
                    ariaLabel={renderModalTitle()}
                    onCancel={() => props.setIsVisible(false)}
                    modalStyle={{ width: '33.75rem' }}
                    footerStyle={{
                        paddingTop: '8px',
                        marginTop: '-8px',
                        minHeight: '64px',
                    }}
                    header={''}
                    icons={''}
                    footer={
                        <>
                            <Button
                                onClick={() => props.setIsVisible(false)}
                                kind="secondary"
                            >
                                {l('_cancel')}
                            </Button>
                            <Button
                                icon="icon-delete-outline"
                                onClick={() => {
                                    handleDeleteClick();
                                }}
                            >
                                {l('_delete')}
                            </Button>
                        </>
                    }
                    bodyStyle={{ minHeight: '92px' }}
                >
                    <p>{renderModalText()}</p>
                    {renderAllocationList()}
                </Modal>
            )}
        </>
    );
};

export default DeleteHistoricalAllocationModal;
