import React, { useEffect, useState } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import {
    LandingPage,
    Home,
    NotFound,
    Logout,
    NotAuthorised,
    Cookies,
    TandC,
    Privacy,
    Contact,
    Accessibility,
    UserManagement,
    LoginCallback,
    ReviewAllocation,
    NewAllocation,
    PreAllocation,
    AllocationHistory,
    AllocationLoading,
    AllocationDashboard,
} from './pages';

import { BusinessRuleManagement, BusinessUnitManagement } from './pages/admin';
import { BusinessMetrics } from './pages/metrics';

import AdminTabs from './components/layout/AdminTabs';

import ProtectedRedirect from './components/ProtectedRedirect';
import AdminRedirect from './components/AdminRedirect';
import SuperAdminRedirect from './components/SuperAdmin/SuperAdminRedirect';
import Layout from './components/layout/Layout';

import UserInfoWrapper from './helpers/UserInfoWrapper';

import { AppRoutes } from './Constants';
import i18nSetup from './locale/i18nSetup';

const App = () => {
    i18nSetup();

    const colourTheme: 'orange' | 'pink' | 'red' | 'black' = 'orange';

    useEffect(() => {
        document.body.setAttribute('data-theme', colourTheme);
        return () => document.body.removeAttribute('data-theme');
    }, [colourTheme]);
    const [
        adminUserManagementSelectedBusinessUnitId,
        setAdminUserManagementSelectedBusinessUnitId,
    ] = useState<number | undefined>(undefined);

    const router = createBrowserRouter([
        {
            path: AppRoutes.LandingPage,
            element: <LandingPage />,
            index: true,
        },
        {
            // Routes accessible to authenticated users
            element: <Layout />,
            children: [
                {
                    element: (
                        <ProtectedRedirect>
                            <UserInfoWrapper />
                        </ProtectedRedirect>
                    ),
                    children: [
                        {
                            path: AppRoutes.HomePage,
                            element: <Home />,
                        },
                        {
                            path: AppRoutes.AllocationDashboard,
                            element: <AllocationDashboard />,
                        },
                        {
                            path: AppRoutes.NewAllocation,
                            element: <NewAllocation />,
                        },
                        {
                            path: AppRoutes.PreAllocation,
                            element: <PreAllocation />,
                        },
                        {
                            path: AppRoutes.AllocationHistory,
                            element: <AllocationHistory />,
                        },
                        {
                            path: AppRoutes.AllocatingLoading,
                            element: <AllocationLoading />,
                        },
                        {
                            path: AppRoutes.ReviewAllocation,
                            element: <ReviewAllocation />,
                        },
                        {
                            // Routes accessible to admins and super admins
                            element: <AdminRedirect />,
                            children: [
                                {
                                    element: <AdminTabs />,
                                    children: [
                                        {
                                            path: AppRoutes.UserManagement,
                                            element: (
                                                <UserManagement
                                                    selectedBusinessUnitId={
                                                        adminUserManagementSelectedBusinessUnitId
                                                    }
                                                    setSelectedBusinessUnitId={
                                                        setAdminUserManagementSelectedBusinessUnitId
                                                    }
                                                />
                                            ),
                                        },
                                        {
                                            element: <SuperAdminRedirect />,
                                            children: [
                                                {
                                                    path: AppRoutes.BusinessUnitManagement,
                                                    element: (
                                                        <BusinessUnitManagement />
                                                    ),
                                                },
                                            ],
                                        },
                                        {
                                            element: <SuperAdminRedirect />,
                                            children: [
                                                {
                                                    path: AppRoutes.BusinessRuleManagement,
                                                    element: (
                                                        <BusinessRuleManagement />
                                                    ),
                                                },
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            element: <SuperAdminRedirect />,
                            children: [
                                {
                                    path: AppRoutes.BusinessMetrics,
                                    element: <BusinessMetrics />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: AppRoutes.CookiesPage,
                    element: <Cookies />,
                },
                {
                    path: AppRoutes.TandC,
                    element: <TandC />,
                },
                {
                    path: AppRoutes.PrivacyPage,
                    element: <Privacy />,
                },
                {
                    path: AppRoutes.ContactPage,
                    element: <Contact />,
                },
                {
                    path: AppRoutes.AccessibilityStatement,
                    element: <Accessibility />,
                },
                {
                    path: AppRoutes.LogoutPage,
                    element: <Logout />,
                },
                {
                    path: '/login/callback',
                    element: <LoginCallback />,
                },
                {
                    path: '/403',
                    element: <NotAuthorised />,
                },
                {
                    path: '*',
                    element: <NotFound />,
                },
            ],
        },
    ]);

    return (
        <div className="d-flex flex-column h-100 min-vh-100">
            <RouterProvider router={router} />
        </div>
    );
};

export default App;
