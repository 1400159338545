import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import {
    DeleteV1BusinessUnitSuperAdminBusinessUnitIdMutationError,
    getGetV1BusinessUnitSuperAdminQueryKey,
    useDeleteV1BusinessUnitSuperAdminBusinessUnitId,
} from '../../api/generated/endpoint';
import { SuperAdminBusinessUnitResponse } from '../../api/generated/models';
import { useQueryClient } from '@tanstack/react-query';

interface SuperAdminConfirmDeleteBusinessUnitModalProps {
    visible: boolean;
    businessUnit: SuperAdminBusinessUnitResponse | undefined;
    setNotVisible: () => void;
}

const SuperAdminConfirmDeleteBusinessUnitModal = (
    props: SuperAdminConfirmDeleteBusinessUnitModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const deleteBusinessUnit =
        useDeleteV1BusinessUnitSuperAdminBusinessUnitId();
    const queryClient = useQueryClient();

    const handleConfirmDelete = () => {
        deleteBusinessUnit.mutate(
            { businessUnitId: props.businessUnit?.id ?? -1 },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_businessUnitHasBeenDeleted'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                },
                onError: (
                    error: DeleteV1BusinessUnitSuperAdminBusinessUnitIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        maskCloseable
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_confirmBusinessUnitDeletion')}
                        aria-label={`${l(
                            '_confirmBusinessUnitDeletetionWithName'
                        )} ${props.businessUnit?.name ?? '-'}`}
                        onCancel={props.setNotVisible}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={props.setNotVisible}
                                    className="focus-margin"
                                >
                                    {l('_cancel')}
                                </Button>
                                <Button
                                    kind="negative"
                                    onClick={() => handleConfirmDelete()}
                                    className="focus-margin"
                                >
                                    {l('_delete')}
                                </Button>
                            </>
                        }
                    >
                        <p>{l('_areYouSureYouWantToDeleteBusinessUnit')}</p>
                        <div className="row mt-2">
                            <div className="col">
                                {props.businessUnit?.name ?? '-'}
                            </div>
                        </div>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminConfirmDeleteBusinessUnitModal;
