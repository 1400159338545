import { FC, createContext } from 'react';
import { reactPlugin, setSessionId } from './AppInsights';
import jwt_decode, { JwtPayload } from 'jwt-decode';
import { useAuth } from 'react-oidc-context';

const AppInsightsContext = createContext(reactPlugin);

type AppInsightsContextProps = { children?: JSX.Element };

const AppInsightsContextProvider: FC<AppInsightsContextProps> = ({
    children,
}) => {
    const auth = useAuth();
    const appInsightsEnabled =
        process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING &&
        process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING.trim() !==
            '';

    if (!appInsightsEnabled) {
        return <> {children} </>;
    }

    const token = auth.user?.access_token;
    if (token) {
        const jti = jwt_decode<JwtPayload>(token).jti;
        if (jti) {
            setSessionId(jti);
        }
    }

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            {children}
        </AppInsightsContext.Provider>
    );
};

export { AppInsightsContext, AppInsightsContextProvider };
