import React, { useState } from 'react';
import { useAuth } from 'react-oidc-context';
import { List, ListItem } from '@appkit4/react-components/list';
import { Loading } from '@appkit4/react-components/loading';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import { ContactPersonResponse } from '../../api/generated/models';
import Roles from '../../auth/roles';
import { Button } from '@appkit4/react-components';
import SuperAdminCreateApplicationContactModal from '../../components/SuperAdmin/SuperAdminCreateApplicationContactModal';
import SuperAdminEditApplicationContactModal from '../../components/SuperAdmin/SuperAdminEditApplicationContactModal';
import useLocale from '../../locale/useLocale';
import {
    useGetV1ContactPerson,
    useGetV1ContactPersonApplication,
    useGetV1IdentityUserInfo,
} from '../../api/generated/endpoint';
import SuperAdminUpdateBusinessUnitContactModal from '../../components/SuperAdmin/SuperAdminUpdateBusinessUnitContactModal';
import useTitle from '../../helpers/useTitle';

type ContactItem = {
    id: number;
    key: string;
    value: string;
    action?: string;
};

const Contact = () => {
    const auth = useAuth();
    const showGenericErrorToast = useGenericErrorToast();
    const { l } = useLocale();
    useTitle(l('_contactUsTitleCase'));

    const [
        isCreateApplicationContactModalVisible,
        setIsCreateApplicationContactModalVisible,
    ] = useState(false);
    const [
        isUpdateBusinessUnitContactModalVisible,
        setIsUpdateBusinessUnitContactModalVisible,
    ] = useState(false);
    const [
        isEditApplicationContactModalVisible,
        setIsEditApplicationContactModalVisible,
    ] = useState(false);
    const [applicationContactToEdit, setApplicationContactToEdit] = useState<
        ContactPersonResponse | undefined
    >(undefined);

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            enabled: auth.isAuthenticated,
            staleTime: Infinity,
        },
    });

    const {
        data: applicationContacts,
        isLoading: isLoadingApplicationContacts,
    } = useGetV1ContactPersonApplication({
        query: {
            onError: showGenericErrorToast,
        },
    });

    const { data: contactPersons, isLoading: isLoadingContactPersons } =
        useGetV1ContactPerson({
            query: {
                enabled: auth.isAuthenticated,
                onError: showGenericErrorToast,
            },
        });

    const renderApplicationContact = (item: ContactItem, index: number) => {
        return (
            <div className="row">
                <div className="col">
                    <ListItem
                        key={index}
                        role="option"
                        aria-label={`${item.key},${item.value}`}
                    >
                        <span className="primary-text">{item.key}</span>
                        <span className="secondary-text">{item.value}</span>
                    </ListItem>
                </div>
                {/* Super admin can edit application contacts */}
                {userInfo?.roleId === Roles.SuperAdminId && (
                    <div className="col-auto" style={{ paddingTop: '12px' }}>
                        <Button
                            className="col m-0 p-0 h-50"
                            kind="text"
                            icon="icon-edit-outline"
                            type="button"
                            role="button"
                            compact
                            onClick={() => {
                                setApplicationContactToEdit(
                                    applicationContacts?.find(
                                        (ac) => ac.id == item.id
                                    )
                                );
                                setIsEditApplicationContactModalVisible(true);
                            }}
                            title={`${l('_editContactPerson')} ${item.key}`}
                        />
                    </div>
                )}
            </div>
        );
    };

    const renderBusinessUnitContact = (item: ContactItem, index: number) => {
        return (
            <ListItem
                key={index}
                role="option"
                aria-label={`${item.key},${item.value}`}
            >
                <span className="primary-text">{item.key}</span>
                <span className="secondary-text">{item.value}</span>
            </ListItem>
        );
    };

    return (
        <>
            <h1 data-cy="contact-us-title">{l('_contactUsTitleCase')}</h1>
            <div className="mt-4">
                <p>{l('_contactUsWithFeedback')}</p>

                <div className="mb-4">
                    <div className="d-flex flex-row align-items-end">
                        <h2>{l('_applicationContact')}</h2>
                        {/* If no application contact exists, super admins can create one */}
                        {userInfo?.roleId === Roles.SuperAdminId &&
                            !isLoadingApplicationContacts &&
                            applicationContacts &&
                            applicationContacts.length < 1 && (
                                <Button
                                    kind="primary"
                                    icon="icon-plus-outline"
                                    type="button"
                                    role="button"
                                    onClick={() =>
                                        setIsCreateApplicationContactModalVisible(
                                            true
                                        )
                                    }
                                    className="mb-3 ms-auto"
                                >
                                    {l('_addApplicationContact')}
                                </Button>
                            )}
                    </div>
                    {isLoadingApplicationContacts && (
                        <Loading
                            loadingType="circular"
                            indeterminate
                            compact={false}
                        />
                    )}
                    {!isLoadingApplicationContacts &&
                        (!applicationContacts ||
                            applicationContacts.length < 1) && (
                            <p>{l('_noContactsConfiguredForApplication')}</p>
                        )}
                    {!isLoadingApplicationContacts &&
                        applicationContacts &&
                        applicationContacts.length > 0 && (
                            <List
                                aria-label={l('_applicationContacts')}
                                itemKey="id"
                                bordered
                                data={applicationContacts.map((contact) => ({
                                    id: contact.id,
                                    key: `${contact.forenames} ${contact.surname}`,
                                    value: contact.email,
                                }))}
                                renderItem={renderApplicationContact}
                                className="d-inline-block"
                                style={{ width: '100%' }}
                            />
                        )}
                </div>

                {/* Only display businessUnit contacts for logged-in users */}
                {auth.isAuthenticated && (
                    <div className="mb-2">
                        <div className="d-flex flex-row align-items-end">
                            <h2>{l('_businessUnitContacts')}</h2>
                            {/* If no businessUnit contact exists, super admins can create one */}
                            {userInfo?.roleId === Roles.SuperAdminId &&
                                !isLoadingContactPersons &&
                                contactPersons && (
                                    <Button
                                        kind="primary"
                                        icon={
                                            contactPersons &&
                                            contactPersons.length > 0
                                                ? 'icon-edit-outline'
                                                : 'icon-plus-outline'
                                        }
                                        type="button"
                                        role="button"
                                        onClick={() =>
                                            setIsUpdateBusinessUnitContactModalVisible(
                                                true
                                            )
                                        }
                                        className="mb-3 ms-auto"
                                    >
                                        {contactPersons &&
                                        contactPersons.length > 0
                                            ? l('_editBusinessUnitContacts')
                                            : l('_addBusinessUnitContacts')}
                                    </Button>
                                )}
                        </div>
                        {isLoadingContactPersons && (
                            <Loading
                                loadingType="circular"
                                indeterminate
                                compact={false}
                            />
                        )}
                        {!isLoadingContactPersons &&
                            (!contactPersons || contactPersons.length < 1) && (
                                <p>
                                    {l('_noContactsConfiguredForBusinessUnit')}
                                </p>
                            )}
                        {!isLoadingContactPersons &&
                            contactPersons &&
                            contactPersons.length > 0 && (
                                <List
                                    aria-label={l('_businessUnitContacts')}
                                    itemKey="id"
                                    bordered
                                    data={contactPersons.map((contact) => ({
                                        id: contact.id,
                                        key: `${contact.forenames} ${contact.surname}`,
                                        value: contact.email,
                                    }))}
                                    renderItem={renderBusinessUnitContact}
                                    className="d-inline-block"
                                    style={{ width: '100%' }}
                                />
                            )}
                    </div>
                )}
            </div>

            {userInfo?.roleId === Roles.SuperAdminId && (
                <>
                    <SuperAdminCreateApplicationContactModal
                        isVisible={isCreateApplicationContactModalVisible}
                        setNotVisible={() =>
                            setIsCreateApplicationContactModalVisible(false)
                        }
                    />
                    <SuperAdminUpdateBusinessUnitContactModal
                        isVisible={isUpdateBusinessUnitContactModalVisible}
                        setNotVisible={() =>
                            setIsUpdateBusinessUnitContactModalVisible(false)
                        }
                        businessUnitId={userInfo.businessUnitId}
                    />
                    {applicationContactToEdit && (
                        <SuperAdminEditApplicationContactModal
                            applicationContactToEdit={applicationContactToEdit}
                            isVisible={isEditApplicationContactModalVisible}
                            setNotVisible={() => {
                                setIsEditApplicationContactModalVisible(false);
                                setApplicationContactToEdit(undefined);
                            }}
                        />
                    )}
                </>
            )}
        </>
    );
};

export default Contact;
