import React from 'react';
import { Panel } from '@appkit4/react-components/panel';
import './Policy.scss';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const Accessibility = () => {
    const { l } = useLocale();
    useTitle(l('_accessibilityStatementTitleCase'));

    return (
        <>
            <h1 data-cy="accessibility-statement-title">
                {l('_accessibilityStatementTitleCase')}
            </h1>
            <Panel className="panel pb-0">
                <p className="m-0">
                    {l('_PwCOnlinePresenceCommitment')}&nbsp;
                    <a
                        href="https://pwc.co.uk"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {l('_PwCUrl')}
                    </a>
                    {l('_accesibilityGuidelines')}
                </p>
            </Panel>
        </>
    );
};

export default Accessibility;
