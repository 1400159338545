import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Switch } from '@appkit4/react-components/switch';
import { Panel } from '@appkit4/react-components/panel';
import { List, ListItem } from '@appkit4/react-components/list';
import { Loading } from '@appkit4/react-components/loading';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import './Settings.scss';
import { AppRoutes } from '../../Constants';
import useLocale from '../../locale/useLocale';
import {
    getGetV1IdentityUserInfoQueryKey,
    getGetV1UserPreferencesQueryKey,
    useGetV1IdentityUserInfo,
    useGetV1UserPreferences,
    usePutV1UserPreferences,
    PutV1UserPreferencesMutationError,
} from '../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import { UserPreferencesRequest } from '../../api/generated/models';
import { ThemeMode, useStoredDarkMode } from '../../helpers/DarkModeDetector';
import useTitle from '../../helpers/useTitle';

const Settings = () => {
    const showToast = useToast();
    const { l } = useLocale();
    useTitle(l('_settings'));

    const showGenericErrorToast = useGenericErrorToast();
    const queryClient = useQueryClient();

    const [isUpdatingAnalytics, setIsUpdatingAnalytics] =
        useState<boolean>(false);
    const [isUpdatingMarketing, setIsUpdatingMarketing] =
        useState<boolean>(false);
    const [isUpdatingEmailNotifications, setIsUpdatingEmailNotifications] =
        useState<boolean>(false);

    const { data: userPreferences, isLoading: loadingUserPreferences } =
        useGetV1UserPreferences({
            query: {
                onError: showGenericErrorToast,
            },
        });

    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    const UpdateUserPreferencesRequest = usePutV1UserPreferences({
        mutation: {
            onMutate: (variables: { data: UserPreferencesRequest }) => {
                const {
                    acceptedAnalyticsCookies,
                    acceptedMarketingCookies,
                    emailNotifications,
                } = variables.data;
                if (acceptedAnalyticsCookies != null) {
                    setIsUpdatingAnalytics(true);
                }
                if (acceptedMarketingCookies != null) {
                    setIsUpdatingMarketing(true);
                }
                if (emailNotifications != null) {
                    setIsUpdatingEmailNotifications(true);
                }
            },
            onSuccess: (data: UserPreferencesRequest) => {
                const {
                    acceptedAnalyticsCookies,
                    acceptedMarketingCookies,
                    emailNotifications,
                } = data;

                showToast(l('_success'), l('_preferencesSaved'), 'success');
                setTimeout(() => {
                    if (acceptedAnalyticsCookies != null) {
                        setIsUpdatingAnalytics(false);
                    }
                    if (acceptedMarketingCookies != null) {
                        setIsUpdatingMarketing(false);
                    }
                    if (emailNotifications != null) {
                        setIsUpdatingEmailNotifications(false);
                    }
                }, 500); // sometimes bugs when changed too quick)
                queryClient.invalidateQueries(
                    getGetV1UserPreferencesQueryKey()
                );
                queryClient.invalidateQueries(
                    getGetV1IdentityUserInfoQueryKey()
                );
            },
            onError: (error: PutV1UserPreferencesMutationError) => {
                showGenericErrorToast(error);
            },
        },
    });

    const sendUpdatePreferencesRequest = (
        updateOptions: UserPreferencesRequest
    ) => {
        UpdateUserPreferencesRequest.mutate({
            data: updateOptions,
        });
    };

    const renderItem = (item: { [key: string]: string }, index: number) => (
        <ListItem
            key={index}
            role="option"
            aria-label={`${item.title},${item.value}`}
        >
            <strong>
                <span className="primary-text">{item.title}</span>
            </strong>
            <span className="secondary-text">{item.value}</span>
        </ListItem>
    );

    enum TabIndex {
        Browser = 0,
        Light = 1,
        Dark = 2,
    }

    function convertToThemeTabIndex(storedColourMode: ThemeMode | null) {
        if (storedColourMode == 'dark') {
            return TabIndex.Dark;
        } else if (storedColourMode == 'light') {
            return TabIndex.Light;
        } else {
            return TabIndex.Browser;
        }
    }

    const storedColourMode = useStoredDarkMode();

    const [activeThemeIndex, setActiveThemeIndex] = useState<number>(() => {
        return convertToThemeTabIndex(storedColourMode);
    });
    useEffect(() => {
        setActiveThemeIndex(convertToThemeTabIndex(storedColourMode));
    }, [storedColourMode]);
    const onThemeTabChange = (i: number, value: string) => {
        let newValue: string | null = null;
        if (value === 'default') {
            window.localStorage.removeItem('data-mode');
        } else {
            window.localStorage.setItem('data-mode', value);
            newValue = value;
        }
        const event = new StorageEvent('storage', {
            key: 'data-mode',
            newValue,
        });
        window.dispatchEvent(event);

        setActiveThemeIndex(i);
    };

    const themeToggleTooltipOptions = {
        trigger: 'hover',
        position: 'top',
        appendAfterTarget: true,
    };

    const userInfoListData = [
        {
            id: 1,
            title: l('_forename(s)'),
            value: userInfo?.forenames || '-',
        },
        {
            id: 2,
            title: l('_surname'),
            value: userInfo?.surname || '-',
        },
        {
            id: 3,
            title: l('_email'),
            value: userInfo?.email || '-',
        },
        {
            id: 4,
            title: l('_role'),
            value: userInfo?.roleName || '-',
        },
        {
            id: 5,
            title: l('_businessUnit'),
            value: userInfo?.businessUnitName || '-',
        },
    ];

    const themeTabData = [
        {
            icon: 'icon-desktop-outline',
            value: 'default',
            tooltipOptions: {
                ...themeToggleTooltipOptions,
                content: l('_defaultBrowserSettings'),
            },
        },
        {
            icon: 'icon-brightness-outline',
            value: 'light',
            tooltipOptions: {
                ...themeToggleTooltipOptions,
                content: l('_lightMode'),
            },
        },
        {
            icon: 'icon-moon-outline',
            value: 'dark',
            tooltipOptions: {
                ...themeToggleTooltipOptions,
                content: l('_darkMode'),
            },
        },
    ];

    return (
        <>
            <h1>{l('_settings')}</h1>
            <div className="ap-simple-panel-container">
                <Panel className="panel">
                    <h2 className="ap-mt-spacing-6 ap-typography-heading">
                        {l('_yourDetails')}
                    </h2>
                    <List
                        data-testid="user-information"
                        className="py-0 w-100 d-inline-block"
                        aria-label={l('_userInformation')}
                        itemKey="title"
                        data={userInfoListData}
                        renderItem={renderItem}
                    />
                </Panel>

                <Panel className="panel">
                    <h2 className="ap-mt-spacing-6 ap-typography-heading">
                        {l('_theme')}
                    </h2>
                    <div className="row w-100 ap-mt-spacing-6">
                        <div className="col-auto ms-1">
                            {l('_lightOrDark')}:
                        </div>
                        <div className="col-auto ms-auto">
                            <Tabs
                                type="filled"
                                activeIndex={activeThemeIndex}
                                onTabChange={onThemeTabChange}
                            >
                                {themeTabData.map((tabData) => (
                                    <Tab
                                        key={tabData.value}
                                        icon={tabData.icon}
                                        value={tabData.value}
                                        tooltipOptions={tabData.tooltipOptions}
                                    />
                                ))}
                            </Tabs>
                        </div>
                    </div>
                </Panel>

                <Panel className="panel">
                    <h2 className="ap-mt-spacing-6 ap-typography-heading">
                        {l('_notifications')}
                    </h2>
                    <div className="row w-100 ap-mt-spacing-6">
                        <div className="col-auto ms-1">
                            <label htmlFor="emailNotificationSwitch">
                                {l('_emailNotifications')}
                            </label>
                        </div>
                        <div className="col-auto ms-auto">
                            {loadingUserPreferences ||
                            isUpdatingEmailNotifications ? (
                                <Loading
                                    loadingType="circular"
                                    indeterminate
                                    compact
                                />
                            ) : (
                                <Switch
                                    inputId="emailNotificationSwitch"
                                    checked={
                                        userPreferences?.emailNotifications ??
                                        false
                                    }
                                    onChange={(newValue) =>
                                        sendUpdatePreferencesRequest({
                                            tcVersionAccepted: null,
                                            acceptedAnalyticsCookies: null,
                                            acceptedMarketingCookies: null,
                                            emailNotifications: newValue,
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Panel>

                <Panel className="panel">
                    <h2 className="ap-mt-spacing-6 ap-typography-heading">
                        {l('_cookies')}
                    </h2>
                    <p>
                        {l('_cookieInformation')}{' '}
                        <Link to={AppRoutes.CookiesPage}>
                            {l('_cookiesPolicy')}
                        </Link>
                        .
                    </p>
                    <div className="row w-100 ap-mt-spacing-6">
                        <div className="col-auto ms-1">
                            <label htmlFor="analyticsCookiePreferenceSwitch">
                                {l('_analyticsCookies')}
                            </label>
                        </div>
                        <div className="col-auto ms-auto">
                            {loadingUserPreferences || isUpdatingAnalytics ? (
                                <Loading
                                    loadingType="circular"
                                    indeterminate
                                    compact
                                />
                            ) : (
                                <Switch
                                    inputId="analyticsCookiePreferenceSwitch"
                                    checked={
                                        userPreferences?.acceptedAnalyticsCookies ??
                                        false
                                    }
                                    onChange={(newValue) =>
                                        sendUpdatePreferencesRequest({
                                            tcVersionAccepted: null,
                                            acceptedAnalyticsCookies: newValue,
                                            acceptedMarketingCookies: null,
                                            emailNotifications: null,
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>
                    <div className="row w-100 ap-mt-spacing-6">
                        <div className="col-auto ms-1">
                            <label htmlFor="marketingCookiesSwitch">
                                {l('_marketingCookies')}
                            </label>
                        </div>
                        <div className="col-auto ms-auto">
                            {loadingUserPreferences || isUpdatingMarketing ? (
                                <Loading
                                    loadingType="circular"
                                    indeterminate
                                    compact
                                />
                            ) : (
                                <Switch
                                    inputId="marketingCookiesSwitch"
                                    checked={
                                        userPreferences?.acceptedMarketingCookies ??
                                        false
                                    }
                                    onChange={(newValue) =>
                                        sendUpdatePreferencesRequest({
                                            tcVersionAccepted: null,
                                            acceptedAnalyticsCookies: null,
                                            acceptedMarketingCookies: newValue,
                                            emailNotifications: null,
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>
                </Panel>
            </div>
        </>
    );
};

export default Settings;
