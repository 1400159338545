import React, { useState, useEffect, FC } from 'react';
import { Link } from 'react-router-dom';
import { Modal } from '@appkit4/react-components/modal';
import { Switch } from '@appkit4/react-components/switch';
import { Button } from '@appkit4/react-components/button';
import { UserPreferencesRequest } from '../api/generated/models';
import { AppRoutes } from '../Constants';
import useGenericErrorToast from '../helpers/useGenericErrorToast';
import useToast from '../helpers/useToast';
import {
    useGetV1IdentityUserInfo,
    usePutV1UserPreferences,
    PutV1UserPreferencesMutationError,
    getGetV1UserPreferencesQueryKey,
    getGetV1IdentityUserInfoQueryKey,
} from '../api/generated/endpoint';
import useLocale from '../locale/useLocale';
import ModalContainer from './ModalContainer';
import { useQueryClient } from '@tanstack/react-query';

interface CookiesModalProps {
    visible: boolean;
    setVisible: (newValue: boolean) => void;
}

const CookiesModal: FC<CookiesModalProps> = (props) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const [analyticsCookies, setAnalyticsCookies] = useState(false);
    const [marketingCookies, setMarketingCookies] = useState(false);
    const updateUserPreferences = usePutV1UserPreferences();
    const queryClient = useQueryClient();

    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    useEffect(() => {
        if (
            userData?.acceptedAnalyticsCookies == null ||
            userData?.acceptedMarketingCookies == null
        ) {
            props.setVisible(true);
            setAnalyticsCookies(userData?.acceptedAnalyticsCookies ?? false);
            setMarketingCookies(userData?.acceptedMarketingCookies ?? false);
        }
    }, [userData]);

    const handleAcceptCookies = () => {
        const updatedPreference: UserPreferencesRequest = {
            tcVersionAccepted: null, // cannot change tcVersionAccepted here
            emailNotifications: null, // cannot change emailNotifications here
            acceptedAnalyticsCookies: analyticsCookies,
            acceptedMarketingCookies: marketingCookies,
        };
        updateUserPreferences.mutate(
            { data: updatedPreference },
            {
                onSuccess: () => {
                    props.setVisible(false);
                    showToast(
                        l('_success'),
                        l('_updatedCookiePreferences'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1UserPreferencesQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1IdentityUserInfoQueryKey()
                    );
                },
                onError: (error: PutV1UserPreferencesMutationError) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        maskCloseable={false}
                        closable={false}
                        title={l('_cookiesTheChoiceIsYours')}
                        aria-label={l(
                            '_acceptNecessaryAnalyticsAndMarketingCookies'
                        )}
                        onCancel={() => props.setVisible(false)}
                        modalStyle={{ width: '60vw' }}
                        footerStyle={{ marginTop: '20px' }}
                        footer={
                            <Button onClick={() => handleAcceptCookies()}>
                                {l('_saveCookiePreferences')}
                            </Button>
                        }
                    >
                        <p>
                            {l('_cookiesDisclaimer')}{' '}
                            <Link to={AppRoutes.CookiesPage}>
                                {l('_cookiesDisclaimerContinued')}
                            </Link>
                            .
                        </p>
                        <br />
                        <b>{l('_necessaryCookies')}</b>
                        <p>{l('_necessaryCookiesStatement')}</p>
                        <br />
                        <b>
                            <label htmlFor="analyticsCookiesSwitch">
                                {l('_analyticsCookies')}
                            </label>
                        </b>
                        <Switch
                            inputId="analyticsCookiesSwitch"
                            checked={analyticsCookies}
                            onChange={(newValue) =>
                                setAnalyticsCookies(newValue)
                            }
                            style={{ float: 'right' }}
                        />
                        <p>{l('_analyticsCookiesStatement')}</p>
                        <br />
                        <p>{l('_analyticsDisclaimer')}</p>
                        <br />
                        <b>
                            <label htmlFor="marketingCookiesSwitch">
                                {l('_marketingCookies')}
                            </label>
                        </b>
                        <Switch
                            inputId="marketingCookiesSwitch"
                            checked={marketingCookies}
                            onChange={(newValue) =>
                                setMarketingCookies(newValue)
                            }
                            style={{ float: 'right' }}
                        />
                        <p>{l('_marketingCookiesStatement')}</p>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default CookiesModal;
