import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import {
    DeleteV1BusinessRuleBusinessRuleIdMutationError,
    getGetV1BusinessRuleQueryKey,
    useDeleteV1BusinessRuleBusinessRuleId,
} from '../../api/generated/endpoint';
import { UploadValidationRule } from '../../api/generated/models';
import { useQueryClient } from '@tanstack/react-query';

interface SuperAdminConfirmDeleteBusinessRuleModalProps {
    visible: boolean;
    setNotVisible: () => void;
    businessRule: UploadValidationRule | undefined;
}

const SuperAdminConfirmDeleteBusinessRuleModal = (
    props: SuperAdminConfirmDeleteBusinessRuleModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();

    const deleteBusinessUnit = useDeleteV1BusinessRuleBusinessRuleId();

    const queryClient = useQueryClient();

    const handleConfirmDelete = () => {
        deleteBusinessUnit.mutate(
            { businessRuleId: props.businessRule?.id ?? -1 },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_businessRuleDeleted'),
                        'success'
                    );

                    props.setNotVisible();

                    queryClient.invalidateQueries(
                        getGetV1BusinessRuleQueryKey()
                    );
                },
                onError: (
                    error: DeleteV1BusinessRuleBusinessRuleIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        maskCloseable
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_confirmBusinessRuleDeletion')}
                        onCancel={props.setNotVisible}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={props.setNotVisible}
                                    className="focus-margin"
                                >
                                    {l('_cancel')}
                                </Button>
                                <Button
                                    kind="negative"
                                    onClick={() => handleConfirmDelete()}
                                    className="focus-margin"
                                >
                                    {l('_delete')}
                                </Button>
                            </>
                        }
                    >
                        <p>{l('_areYouSureYouWantToDeleteBusinessRule')}</p>
                        <div className="row mt-2">
                            <div className="col">
                                {props.businessRule?.name ?? '-'}
                            </div>
                        </div>
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminConfirmDeleteBusinessRuleModal;
