import { Panel } from '@appkit4/react-components/panel';
import { Button } from '@appkit4/react-components/button';
import { useNavigate } from 'react-router-dom';
import useLocale from '../../locale/useLocale';
import { AppRoutes } from '../../Constants';
import './NoAllocations.scss';

const NoAllocations = () => {
    const { l } = useLocale();
    const navigate = useNavigate();

    return (
        <div className="allocations-empty-container">
            <Panel className="allocations-empty-panels">
                <h2 className="allocations-empty-panel-text">
                    {l('_noSavedAllocations')}
                </h2>
                <Button
                    className="allocations-empty-button"
                    kind="primary"
                    icon="icon-configuration-outline"
                    onClick={() => {
                        navigate(AppRoutes.NewAllocation);
                    }}
                >
                    {l('_createAllocation')}
                </Button>
            </Panel>
        </div>
    );
};

export default NoAllocations;
