import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { FC } from 'react';
import useLocale from '../locale/useLocale';
import { Input } from '@appkit4/react-components/field';
import { useState } from 'react';

interface RenameHistoricalAllocationModal {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    onSave: (value: string) => void;
    selectedAllocationName: string;
    selectedAllocationStatus: string;
}

const RenameHistoricalAllocationModal: FC<RenameHistoricalAllocationModal> = (
    props
) => {
    const { l } = useLocale();
    const [value, setValue] = useState('');

    const handleSaveClick = () => {
        props.onSave(value);
        props.setIsVisible(false);
    };

    const onChange = (value: string) => {
        setValue(value);
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible={props.isVisible}
                    title={l('_renameAllocationModalTitle')}
                    ariaLabel={''}
                    onCancel={() => props.setIsVisible(false)}
                    modalStyle={{ width: '33.75rem' }}
                    footerStyle={{
                        paddingTop: '8px',
                        marginTop: '-8px',
                        minHeight: '64px',
                    }}
                    header={''}
                    icons={''}
                    footer={
                        <>
                            <Button
                                onClick={() => props.setIsVisible(false)}
                                kind="secondary"
                            >
                                {l('_cancel')}
                            </Button>
                            <Button
                                icon="icon-save-outline"
                                onClick={() => {
                                    handleSaveClick();
                                }}
                            >
                                {l('_save')}
                            </Button>
                        </>
                    }
                    bodyStyle={{ minHeight: '92px' }}
                >
                    <p style={{ color: '#E0301E' }}>
                        {l('_renameAllocationModalBody')}
                    </p>
                    <Input
                        style={{ marginTop: '0.5rem' }}
                        type={'text'}
                        title={props.selectedAllocationName}
                        value={value}
                        onChange={onChange}
                    ></Input>
                </Modal>
            )}
        </>
    );
};

export default RenameHistoricalAllocationModal;
