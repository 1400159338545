import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Avatar } from '@appkit4/react-components/avatar';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import { ContactPersonResponse } from '../../api/generated/models';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import {
    DeleteV1ContactPersonSuperAdminContactPersonIdMutationError,
    getGetV1ContactPersonApplicationQueryKey,
    getGetV1ContactPersonQueryKey,
    getGetV1ContactPersonSuperAdminQueryKey,
    getGetV1BusinessUnitSuperAdminQueryKey,
    useDeleteV1ContactPersonSuperAdminContactPersonId,
} from '../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';

interface SuperAdminConfirmDeleteContactPersonModalProps {
    visible: boolean;
    contactPerson: ContactPersonResponse | undefined;
    setNotVisible: () => void;
}

const SuperAdminConfirmDeleteContactPersonModal = (
    props: SuperAdminConfirmDeleteContactPersonModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const deleteBusinessUnitContactPerson =
        useDeleteV1ContactPersonSuperAdminContactPersonId();
    const queryClient = useQueryClient();

    const handleConfirmDelete = () => {
        deleteBusinessUnitContactPerson.mutate(
            {
                contactPersonId: props.contactPerson?.id ?? -1,
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_contactPersonHasBeenDeleted'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonSuperAdminQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonApplicationQueryKey()
                    );
                },
                onError: (
                    error: DeleteV1ContactPersonSuperAdminContactPersonIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_confirmContactPersonDeletion')}
                        aria-label={`${l(
                            '_confirmDeletionOfContactPersonWithEmail'
                        )} ${props.contactPerson?.email ?? '-'}`}
                        onCancel={props.setNotVisible}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        footer={
                            <>
                                <Button
                                    kind="secondary"
                                    onClick={props.setNotVisible}
                                    className="focus-margin"
                                >
                                    {l('_cancel')}
                                </Button>
                                <Button
                                    kind="negative"
                                    onClick={() => handleConfirmDelete()}
                                    className="focus-margin"
                                >
                                    {l('_delete')}
                                </Button>
                            </>
                        }
                    >
                        <p>{l('_deleteContactPersonConfirmation')}</p>
                        <div className="row mt-2">
                            <div className="col-auto">
                                <Avatar
                                    label={
                                        props.contactPerson
                                            ? `${props.contactPerson.forenames.charAt(
                                                  0
                                              )}${props.contactPerson.surname.charAt(
                                                  0
                                              )}`
                                            : '-'
                                    }
                                    compact
                                />
                            </div>
                            <div className="col" style={{ paddingTop: '2px' }}>
                                {props.contactPerson?.email}
                            </div>
                        </div>
                        {!props.contactPerson?.businessUnits ||
                            props.contactPerson.businessUnits.length <= 1 || (
                                <>
                                    <p className="mt-3">
                                        {l(
                                            '_contactPersonIsAssignedToMultipleBusinessUnit'
                                        )}
                                    </p>
                                    <div className="row mt-2">
                                        <div className="col-auto">
                                            <ul>
                                                {props.contactPerson.businessUnits.map(
                                                    (businessUnit) => (
                                                        <li
                                                            key={
                                                                businessUnit.id
                                                            }
                                                        >
                                                            {businessUnit.name}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </>
                            )}
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminConfirmDeleteContactPersonModal;
