import { useAuth } from 'react-oidc-context';
import { setSessionId } from '../api/monitoring/AppInsights';
import { usePostV1AuthLogout } from '../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';

const useLogout = () => {
    const auth = useAuth();
    const postLogout = usePostV1AuthLogout();
    const queryClient = useQueryClient();

    const logout = async () => {
        if (auth && auth.isAuthenticated) {
            const idToken = auth.user?.id_token;
            setSessionId(undefined);
            window.sessionStorage.clear();
            postLogout.mutate(undefined, {
                onSettled: async () => {
                    auth.removeUser();
                    queryClient.removeQueries();
                    await auth.signoutRedirect({ id_token_hint: idToken });
                },
            });
        }
    };

    return logout;
};

export default useLogout;
