import { useEffect, useState } from 'react';
import { Tab, Tabs, Button } from '@appkit4/react-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { IndexedCoacheeData } from '../../models/indexedCoacheeData';
import { IndexedCoachData } from '../../models/indexedCoachData';
import { PartialCoachee } from '../../models/PartialCoachee';
import CoacheesTable from '../../components/tables/preallocation/CoacheesTable';
import CoachesTable from '../../components/tables/preallocation/CoachesTable';
import AdjustCoachCapacityModal from '../../components/AdjustCoachCapacityModal';
import RunAllocationModal from '../../components/RunAllocationModal';
import useLocale from '../../locale/useLocale';
import useToast from '../../helpers/useToast';
import {
    useGetV1BusinessUnit,
    usePostV1AllocationRunAllocationId,
    useGetV1CareerCoachCapacityCareerCoachCapacityId,
} from '../../api/generated/endpoint';
import { AppRoutes } from '../../Constants';

import './PreAllocation.scss';

const PreAllocation = () => {
    const { l } = useLocale();
    const location = useLocation();
    const showToast = useToast();
    const navigate = useNavigate();

    const runAllocationApi = usePostV1AllocationRunAllocationId();
    const { isLoading: isAllocationLoading } = runAllocationApi;

    /* load current business unit info */
    const {
        data: userBusinessUnitInfo,
        isSuccess: loadedUserBusinessUnitInfo,
    } = useGetV1BusinessUnit();

    const careerCoachCapacityId =
        userBusinessUnitInfo?.careerCoachCapacityId ?? 0;

    const {
        data: careerCoachCapacityData,
        isSuccess: loadedCareerCoachCapacityData,
    } = useGetV1CareerCoachCapacityCareerCoachCapacityId(careerCoachCapacityId);

    const defaultTabLabel = 0;
    const [activeTab, setActiveTab] = useState<number>(defaultTabLabel);

    const [selectedCoachees, setSelectedCoachees] = useState<
        IndexedCoacheeData[]
    >([]);
    const [selectedCoaches, setSelectedCoaches] = useState<IndexedCoachData[]>(
        []
    );

    const [coacheeData, setCoacheeData] = useState<IndexedCoacheeData[]>([]);
    const [coachData, setCoachData] = useState<IndexedCoachData[]>([]);

    const [
        displayAdjustCareerCoachCapacityModal,
        setDisplayAdjustCareerCoachCapacityModal,
    ] = useState(false);
    const [
        displayAdjustRunAllocationModal,
        setDisplayAdjustRunAllocationModal,
    ] = useState(false);

    useEffect(() => {
        let index = 1;
        const indexCoacheeList: IndexedCoacheeData[] = [];

        location.state.allocationData.coachees?.forEach(
            (coachee: IndexedCoacheeData) => {
                coachee.index = index;
                indexCoacheeList.push(coachee);

                index++;
            }
        );

        setCoacheeData(indexCoacheeList);

        index = 1;
        const indexCoachList: IndexedCoachData[] = [];

        location.state.allocationData.coaches?.forEach(
            (coach: IndexedCoachData) => {
                coach.index = index;

                const partialCoacheesList: PartialCoachee[] = [];

                indexCoacheeList.forEach((coachee: IndexedCoacheeData) => {
                    if (coachee.careerCoach?.id == coach.id) {
                        const partialCoachee: PartialCoachee = {
                            name: coachee.name,
                            warnings: coachee.warnings,
                        };

                        partialCoacheesList.push(partialCoachee);
                    }
                });

                coach.coachees = partialCoacheesList;

                indexCoachList.push(coach);

                index++;
            }
        );

        setCoachData(indexCoachList);
    }, []);

    const runAllocation = async () => {
        const selectedCoacheeData = markSelectedCoachees();
        const selectedCoachData = markSelectedCoaches();

        const maxCoacheesProperties = careerCoachCapacityData
            ? {
                  maxCoacheesAssociate:
                      careerCoachCapacityData.maxCoacheesAssociate,
                  maxCoacheesDirector:
                      careerCoachCapacityData.maxCoacheesDirector,
                  maxCoacheesManager:
                      careerCoachCapacityData.maxCoacheesManager,
                  maxCoacheesSeniorAssociate:
                      careerCoachCapacityData.maxCoacheesSeniorAssociate,
                  maxCoacheesSeniorManager:
                      careerCoachCapacityData.maxCoacheesSeniorManager,
              }
            : {
                  maxCoacheesAssociate: 0,
                  maxCoacheesDirector: 0,
                  maxCoacheesManager: 0,
                  maxCoacheesSeniorAssociate: 0,
                  maxCoacheesSeniorManager: 0,
              };

        await runAllocationApi.mutateAsync(
            {
                allocationId: location.state.allocationId,
                data: {
                    allocationData: {
                        coachees: selectedCoacheeData,
                        coaches: selectedCoachData,
                        properties: maxCoacheesProperties,
                    },
                },
            },
            {
                onSuccess: () => {
                    navigate(AppRoutes.AllocatingLoading, {
                        replace: true,
                        state: {
                            allocationId: location.state.allocationId,
                        },
                    });
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    const markSelectedCoachees = () => {
        const updatedCoacheeData = [...coacheeData];
        selectedCoachees.forEach((selectedCoachee) => {
            const index = updatedCoacheeData.findIndex(
                (coachee) => coachee.index === selectedCoachee.index
            );

            if (index !== -1) {
                updatedCoacheeData[index] = {
                    ...updatedCoacheeData[index],
                    selected: true,
                };
            }
        });

        return updatedCoacheeData;
    };

    const markSelectedCoaches = () => {
        const updatedCoachData = [...coachData];
        selectedCoaches.forEach((selectedCoach) => {
            const index = updatedCoachData.findIndex(
                (coach) => coach.index === selectedCoach.index
            );

            if (index !== -1) {
                updatedCoachData[index] = {
                    ...updatedCoachData[index],
                    selected: true,
                };
            }
        });

        return updatedCoachData;
    };

    const handleUpdateCoacheeNotes = (coacheeId: string, newNotes: string) => {
        const updatedCoachees = coacheeData.map((coachee) =>
            coachee.id === coacheeId ? { ...coachee, notes: newNotes } : coachee
        );
        setCoacheeData(updatedCoachees);
    };

    const handleUpdateCoachNotes = (coachId: string, newNotes: string) => {
        const updatedCoaches = coachData.map((coach) =>
            coach.id === coachId ? { ...coach, notes: newNotes } : coach
        );
        setCoachData(updatedCoaches);
    };

    return (
        <div className="preallocation-page-wrapper">
            <h2 className="preallocation-title">{l('_preallocationTitle')}</h2>
            <Tabs
                className="preallocation-tabs"
                type="underline"
                stretched={false}
                activeIndex={activeTab}
                defaultActiveIndex={defaultTabLabel}
                onTabChange={(i: number) => {
                    setActiveTab(i);
                }}
                showBothIndicators
            >
                <Tab label={l('_coachees')} />
                <Tab label={l('_careerCoach')} />
            </Tabs>
            <div className={`${activeTab == 0 ? '' : 'hidden'}`}>
                <CoacheesTable
                    coachees={coacheeData}
                    onSelectedCoacheesChange={(
                        updatedCoacheeSelectedIndexes
                    ) => {
                        setSelectedCoachees(updatedCoacheeSelectedIndexes);
                    }}
                    allocationId={location.state.allocationId}
                    onUpdateNotes={handleUpdateCoacheeNotes}
                />
            </div>
            <div className={`${activeTab == 1 ? '' : 'hidden'}`}>
                <CoachesTable
                    coaches={coachData}
                    onSelectedCoachesChange={(updatedCoachSelectedIndexes) => {
                        setSelectedCoaches(updatedCoachSelectedIndexes);
                    }}
                    loadedUserBusinessUnitInfo={loadedUserBusinessUnitInfo}
                    setDisplayAdjustCareerCoachCapacityModal={
                        setDisplayAdjustCareerCoachCapacityModal
                    }
                    allocationId={location.state.allocationId}
                    onUpdateNotes={handleUpdateCoachNotes}
                />
            </div>
            <div className="preallocation-button-wrapper">
                <Button
                    className="preallocation-back-button-component"
                    kind="secondary"
                    icon="icon-left-chevron-outline"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    {l('_back')}
                </Button>
                <Button
                    className="preallocation-run-button-component"
                    kind="primary"
                    icon="icon-configuration-outline"
                    loading={isAllocationLoading}
                    onClick={() => {
                        setDisplayAdjustRunAllocationModal(true);
                    }}
                    disabled={
                        coacheeData.length === 0 || coachData.length === 0
                    }
                >
                    {l('_runAllocation')}
                </Button>
            </div>
            {displayAdjustCareerCoachCapacityModal && (
                <AdjustCoachCapacityModal
                    isVisible={true}
                    setIsVisible={setDisplayAdjustCareerCoachCapacityModal}
                    careerCoachCapacityId={
                        userBusinessUnitInfo?.careerCoachCapacityId ?? 0
                    }
                />
            )}
            {displayAdjustRunAllocationModal && (
                <RunAllocationModal
                    isVisible={true}
                    setIsVisible={setDisplayAdjustRunAllocationModal}
                    onRun={runAllocation}
                />
            )}
        </div>
    );
};

export default PreAllocation;
