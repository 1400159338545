import { resources, supportedLocales } from './translations';
import i18next, { InitOptions } from 'i18next';
import languageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export default async function i18nSetup() {
    const initOptions: InitOptions = {
        resources: resources,
        fallbackLng: 'en',
        load: 'all',
        supportedLngs: supportedLocales,
        defaultNS: 'strings',
        debug: true,
    };

    await i18next.use(initReactI18next).use(languageDetector).init(initOptions);

    document.dir = i18next.dir();
}

// Extract a type from the en locale keys as that should have all valid keys.
export type translationKeys = keyof typeof resources.en.strings;
