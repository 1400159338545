import { useEffect } from 'react';

interface PaginatedData {
    totalCount: number | undefined;
}

type SetPageNumFunction = (value: number) => void;

const usePaginationControl = (
    paginatedData: PaginatedData | undefined,
    pageSize: number,
    setPageNum: SetPageNumFunction,
    pageNum: number
) => {
    useEffect(() => {
        const maxPageNum =
            paginatedData && paginatedData.totalCount !== undefined
                ? Math.ceil(paginatedData.totalCount / pageSize)
                : null;

        if (pageNum <= 0 || !pageNum) {
            setPageNum(1);
        } else if (paginatedData && maxPageNum && pageNum > maxPageNum) {
            setPageNum(maxPageNum);
        }
    }, [paginatedData, pageSize, setPageNum, pageNum]);
};

export default usePaginationControl;
