// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import { useAuth } from 'react-oidc-context';
import { AppRoutes } from '../Constants';
import { Button, Modal } from '@appkit4/react-components';
import ModalContainer from './ModalContainer';
import useLocale from '../locale/useLocale';

export const Timeout = () => {
    const navigate = useNavigate();
    const auth = useAuth();
    const { l } = useLocale();

    const modalTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const [modalVisible, setModalVisible] = useState(false);

    const onLogOut = () => {
        clearTimeout(modalTimeoutRef.current as NodeJS.Timeout);

        navigate(AppRoutes.LogoutPage);
    };

    const onResumeSession = () => {
        setModalVisible(false);

        clearTimeout(modalTimeoutRef.current as NodeJS.Timeout);
    };

    const onIdle = () => {
        // Only trigger inactivity modal if user is authenticated.
        if (auth.isAuthenticated) {
            setModalVisible(true);

            // Set timeout which triggers after 5 mins of inaction. If triggered, log out the user.
            modalTimeoutRef.current = setTimeout(onLogOut, 5 * 60 * 1000);
        }
    };

    useIdleTimer({
        onIdle,
        timeout: 10 * 60 * 1000, // 10 mins idle time to trigger modal
    });

    return (
        <ModalContainer isVisible={modalVisible}>
            <Modal
                visible
                onCancel={onResumeSession}
                title={l('_sessionTimeoutWarning')}
                aria-label={l('_sessionTimeoutWarning')}
                modalStyle={{
                    width: '50vw',
                    maxWidth: '500px',
                    minWidth: '250px',
                }}
                footerStyle={{ marginTop: '1rem' }}
                footer={
                    <>
                        <Button
                            kind="secondary"
                            onClick={onResumeSession}
                            className={'focus-margin'}
                        >
                            {l('_resumeSession')}
                        </Button>
                        <Button
                            kind="primary"
                            onClick={onLogOut}
                            className={'focus-margin'}
                        >
                            {l('_logout')}
                        </Button>
                    </>
                }
            >
                <p>{l('_sessionTimeoutDescription')}</p>
            </Modal>
        </ModalContainer>
    );
};

export default Timeout;
