import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { FC } from 'react';
import useLocale from '../locale/useLocale';

interface RunAllocationModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    onRun: () => void;
}

const RunAllocationModal: FC<RunAllocationModalProps> = (props) => {
    const { l } = useLocale();

    const handleYesClick = () => {
        props.setIsVisible(false);
        props.onRun();
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible={props.isVisible}
                    title={l('_runAllocationModalTitle')}
                    ariaLabel={l('_runAllocationModalTitle')}
                    onCancel={() => props.setIsVisible(false)}
                    modalStyle={{ width: '33.75rem' }}
                    footerStyle={{
                        paddingTop: '8px',
                        marginTop: '-8px',
                        minHeight: '64px',
                    }}
                    header={''}
                    icons={''}
                    footer={
                        <>
                            <Button
                                onClick={() => props.setIsVisible(false)}
                                kind="secondary"
                            >
                                {l('_cancel')}
                            </Button>
                            <Button
                                onClick={() => {
                                    handleYesClick();
                                }}
                            >
                                {l('_yes')}
                            </Button>
                        </>
                    }
                    bodyStyle={{ minHeight: '92px' }}
                >
                    <p>{l('_runAllocationModalText')}</p>
                </Modal>
            )}
        </>
    );
};

export default RunAllocationModal;
