import { useQueryClient } from '@tanstack/react-query';
import { Modal } from '@appkit4/react-components/modal';
import SuperAdminEditBusinessRuleForm, {
    FormValues,
} from './SuperAdminEditBusinessRuleForm';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import {
    PostV1BusinessRuleMutationError,
    getGetV1BusinessRuleQueryKey,
    usePostV1BusinessRule,
    usePutV1BusinessRuleBusinessRuleId,
} from '../../api/generated/endpoint';
import { UploadValidationRule } from '../../api/generated/models';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';

interface ISuperAdminEditBusinessRuleModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    existingRule: UploadValidationRule | undefined;
}

const SuperAdminCreateBusinessRuleModal = (
    props: ISuperAdminEditBusinessRuleModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();

    const showGenericErrorToast = useGenericErrorToast();
    const createNewBusinessRule = usePutV1BusinessRuleBusinessRuleId();
    const queryClient = useQueryClient();

    const editBusinessRuleRequest = (values: FormValues) => {
        createNewBusinessRule.mutate(
            {
                businessRuleId: props.existingRule?.id ?? -1,
                data: {
                    name: values.name,
                    rules: values.rules,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_businessRuleUpdated'),
                        'success'
                    );

                    props.setIsVisible(false);

                    queryClient.invalidateQueries(
                        getGetV1BusinessRuleQueryKey()
                    );
                },
                onError: (error: PostV1BusinessRuleMutationError) => {
                    showGenericErrorToast(error);
                    props.setIsVisible(false);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_editBusinessRule')}
                        aria-label={l('_editBusinessRule')}
                        modalStyle={{
                            width: '75vw',
                            maxWidth: '750px',
                            minWidth: '500px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        closeOnPressEscape
                        onCancel={() => props.setIsVisible(false)}
                    >
                        <SuperAdminEditBusinessRuleForm
                            submitFunc={editBusinessRuleRequest}
                            existingRule={
                                props.existingRule as UploadValidationRule
                            }
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminCreateBusinessRuleModal;
