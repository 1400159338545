import React, { useEffect, FC } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { UserPreferencesRequest } from '../api/generated/models';
import useGenericErrorToast from '../helpers/useGenericErrorToast';
import { AppRoutes } from '../Constants';
import useToast from '../helpers/useToast';
import {
    useGetV1PolicyTermsVersion,
    usePutV1UserPreferences,
    PutV1UserPreferencesMutationError,
    getGetV1UserPreferencesQueryKey,
    getGetV1IdentityUserInfoQueryKey,
    getGetV1PolicyTermsVersionQueryKey,
} from '../api/generated/endpoint';
import useLocale from '../locale/useLocale';
import ModalContainer from './ModalContainer';
import { useQueryClient } from '@tanstack/react-query';

interface TandCModalProps {
    visible: boolean;
    setVisible: (newValue: boolean) => void;
    setTandcReady: (newValue: boolean) => void;
}

const TandCModal: FC<TandCModalProps> = (props) => {
    const navigate = useNavigate();
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const updateUserPreferences = usePutV1UserPreferences();
    const queryClient = useQueryClient();

    const { data: tandCVersion, isLoading: loadingTandCVersion } =
        useGetV1PolicyTermsVersion({
            query: {
                onError: showGenericErrorToast,
                onSuccess: () => {
                    props.setTandcReady(true);
                },
                staleTime: Infinity,
            },
        });

    useEffect(() => {
        // if the version has been fetched and user has accepted latest version
        const isVisible =
            !loadingTandCVersion &&
            tandCVersion != null &&
            (tandCVersion.acceptedVersion == null ||
                Math.floor(tandCVersion.acceptedVersion) <
                    Math.floor(tandCVersion.latestVersion));
        props.setVisible(isVisible);
    }, [tandCVersion, loadingTandCVersion]);

    const handleDenyTandC = () => {
        navigate(AppRoutes.LogoutPage);
    };

    const handleAcceptTandC = () => {
        const updatedPreference: UserPreferencesRequest = {
            tcVersionAccepted: tandCVersion?.latestVersion || null,
            emailNotifications: null, // cannot update emailNotification preference here
            acceptedAnalyticsCookies: null, // cannot update cookie preferences here
            acceptedMarketingCookies: null, // cannot update cookie preferences here
        };
        updateUserPreferences.mutate(
            { data: updatedPreference },
            {
                onSuccess: () => {
                    props.setVisible(false);
                    showToast(
                        l('_success'),
                        l('_updatedTermsAndConditionsPreference'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1UserPreferencesQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1IdentityUserInfoQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1PolicyTermsVersionQueryKey()
                    );
                },
                onError: (error: PutV1UserPreferencesMutationError) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            {props.visible && (
                <ModalContainer isVisible={props.visible}>
                    <Modal
                        visible
                        maskCloseable={false}
                        closable={false}
                        title={l('_termsAndConditions')}
                        aria-label={l('_acceptTermsAndConditions')}
                        onCancel={() => props.setVisible(false)}
                        modalStyle={{ width: '50vw' }}
                        footer={
                            <>
                                <Button
                                    onClick={() => handleDenyTandC()}
                                    kind="secondary"
                                >
                                    {l('_deny')}
                                </Button>
                                <Button onClick={() => handleAcceptTandC()}>
                                    {l('_acceptAndContinue')}
                                </Button>
                            </>
                        }
                        footerStyle={{ marginTop: '20px' }}
                    >
                        {tandCVersion?.acceptedVersion === null ? (
                            <p>
                                {l('_youAreRequiredToAcceptOur')}{' '}
                                <Link to={AppRoutes.TandC}>
                                    {l('_termsAndConditions')}
                                </Link>{' '}
                                {l('_beforeUsingThisSite')}
                            </p>
                        ) : (
                            <p>
                                {l('_our')}{' '}
                                <Link to={AppRoutes.TandC}>
                                    {l('_termsAndConditions')}
                                </Link>{' '}
                                {l(
                                    '_haveBeenUpdatedPleaseReviewThemAndAcceptBeforeContinuingToTheSite'
                                )}
                            </p>
                        )}
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default TandCModal;
