import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import useLocale from '../locale/useLocale';

import './AdjustCoachCapacityForm.scss';

interface CreateCareerCoachCapacityFormProps {
    maxCoacheesD: number;
    maxCoacheesSM: number;
    maxCoacheesM: number;
    maxCoacheesSA: number;
    maxCoacheesA: number;
    submitFunc: (values: FormValues) => void;
    cancelFunc: () => void;
}

export interface FormValues {
    maxCoacheesD: number;
    maxCoacheesSM: number;
    maxCoacheesM: number;
    maxCoacheesSA: number;
    maxCoacheesA: number;
}

const AdjustCoachCapacityForm = (props: CreateCareerCoachCapacityFormProps) => {
    const { l } = useLocale();

    const numericCapacityValidation = Yup.number()
        .typeError(l('_pleaseEnterAPositiveValue'))
        .min(0, l('_pleaseEnterAPositiveValue'))
        .required(l('_pleaseEnterAPositiveValue'));

    const newCareerCoachCapacitySchema = Yup.object({
        maxCoacheesD: numericCapacityValidation,
        maxCoacheesSM: numericCapacityValidation,
        maxCoacheesM: numericCapacityValidation,
        maxCoacheesSA: numericCapacityValidation,
        maxCoacheesA: numericCapacityValidation,
    });

    return (
        <Formik
            initialValues={{
                maxCoacheesD: props.maxCoacheesD,
                maxCoacheesSM: props.maxCoacheesSM,
                maxCoacheesM: props.maxCoacheesM,
                maxCoacheesSA: props.maxCoacheesSA,
                maxCoacheesA: props.maxCoacheesA,
            }}
            validationSchema={newCareerCoachCapacitySchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form>
                    <p>{l('_careerCoachCapacityAdjustmentDescription')}</p>
                    <div className="row mt-3">
                        <div className="coachee-type col">
                            <span className="ap-typography-body ap-font-weight-2">
                                {l('_director')}
                            </span>
                        </div>
                        {touched.maxCoacheesD && errors.maxCoacheesD && (
                            <div className="col ap-text-states-error-04">
                                {errors.maxCoacheesD}
                            </div>
                        )}
                        <div className="col-3">
                            <Input
                                type="text"
                                error={errors.maxCoacheesD}
                                value={values.maxCoacheesD}
                                onChange={(value: string) => {
                                    setFieldTouched('maxCoacheesD');
                                    validateField('maxCoacheesD');
                                    setFieldValue('maxCoacheesD', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="coachee-type col">
                            <span className="ap-typography-body ap-font-weight-2">
                                {l('_seniorManager')}
                            </span>
                        </div>
                        {touched.maxCoacheesSM && errors.maxCoacheesSM && (
                            <div className="col ap-text-states-error-04">
                                {errors.maxCoacheesSM}
                            </div>
                        )}
                        <div className="col-3">
                            <Input
                                type="text"
                                error={errors.maxCoacheesSM}
                                value={values.maxCoacheesSM}
                                onChange={(value: string) => {
                                    setFieldTouched('maxCoacheesSM');
                                    validateField('maxCoacheesSM');
                                    setFieldValue('maxCoacheesSM', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="coachee-type col">
                            <span className="ap-typography-body ap-font-weight-2">
                                {l('_manager')}
                            </span>
                        </div>
                        {touched.maxCoacheesM && errors.maxCoacheesM && (
                            <div className="col ap-text-states-error-04">
                                {errors.maxCoacheesM}
                            </div>
                        )}
                        <div className="col-3">
                            <Input
                                type="text"
                                error={errors.maxCoacheesM}
                                value={values.maxCoacheesM}
                                onChange={(value: string) => {
                                    setFieldTouched('maxCoacheesM');
                                    validateField('maxCoacheesM');
                                    setFieldValue('maxCoacheesM', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="coachee-type col">
                            <span className="ap-typography-body ap-font-weight-2">
                                {l('_seniorAssociate')}
                            </span>
                        </div>
                        {touched.maxCoacheesSA && errors.maxCoacheesSA && (
                            <div className="col ap-text-states-error-04">
                                {errors.maxCoacheesSA}
                            </div>
                        )}
                        <div className="col-3">
                            <Input
                                type="text"
                                error={errors.maxCoacheesSA}
                                value={values.maxCoacheesSA}
                                onChange={(value: string) => {
                                    setFieldTouched('maxCoacheesSA');
                                    validateField('maxCoacheesSA');
                                    setFieldValue('maxCoacheesSA', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="row mt-3">
                        <div className="coachee-type col">
                            <span className="ap-typography-body ap-font-weight-2">
                                {l('_associate')}
                            </span>
                        </div>
                        {touched.maxCoacheesA && errors.maxCoacheesA && (
                            <div className="col ap-text-states-error-04">
                                {errors.maxCoacheesA}
                            </div>
                        )}
                        <div className="col-3">
                            <Input
                                type="text"
                                error={errors.maxCoacheesA}
                                value={values.maxCoacheesA}
                                onChange={(value: string) => {
                                    setFieldTouched('maxCoacheesA');
                                    validateField('maxCoacheesA');
                                    setFieldValue('maxCoacheesA', value);
                                }}
                            />
                        </div>
                    </div>
                    <div className="confirmation-button-container">
                        <Button
                            className="mt-5"
                            kind="secondary"
                            onClick={props.cancelFunc}
                        >
                            {l('_cancel')}
                        </Button>
                        <Button
                            className="ms-3 mt-5"
                            kind="primary"
                            type="submit"
                        >
                            {l('_apply')}
                        </Button>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AdjustCoachCapacityForm;
