import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import SuperAdminEditContactPersonForm from './SuperAdminEditContactPersonForm';
import useLocale from '../../locale/useLocale';
import {
    usePutV1ContactPersonSuperAdminApplicationApplicationContactId,
    getGetV1ContactPersonApplicationQueryKey,
    PutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationError,
    getGetV1ContactPersonSuperAdminQueryKey,
} from '../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import {
    EditContactPersonRequest,
    ContactPersonResponse,
} from '../../api/generated/models';

interface ISuperAdminEditApplicationContactModalProps {
    applicationContactToEdit: ContactPersonResponse;
    isVisible: boolean;
    setNotVisible: () => void;
}

const SuperAdminEditApplicationContactModal = (
    props: ISuperAdminEditApplicationContactModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const queryClient = useQueryClient();

    const UpdateContactPersonRequest =
        usePutV1ContactPersonSuperAdminApplicationApplicationContactId({
            mutation: {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_applicationcContactUpdated'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonApplicationQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonSuperAdminQueryKey()
                    );
                    props.setNotVisible();
                },
                onError: (
                    error: PutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            },
        });

    const putEditApplicationContactRequest = (
        applicationContactId: number,
        editedApplicationContact: EditContactPersonRequest
    ) => {
        UpdateContactPersonRequest.mutate({
            applicationContactId,
            data: editedApplicationContact,
        });
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible
                    closable
                    closeAriaLabel={l('_close')}
                    title={l('_editApplicationContact')}
                    aria-label={l('_editApplicationContact')}
                    modalStyle={{
                        width: '50vw',
                        maxWidth: '500px',
                        minWidth: '250px',
                    }}
                    footerStyle={{ marginTop: '1rem' }}
                    closeOnPressEscape
                    onCancel={() => props.setNotVisible()}
                >
                    <SuperAdminEditContactPersonForm
                        currentContactPersonValues={
                            props.applicationContactToEdit
                        }
                        submitFunc={putEditApplicationContactRequest}
                    />
                </Modal>
            )}
        </>
    );
};

export default SuperAdminEditApplicationContactModal;
