import { useState } from 'react';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Tab, Tabs } from '@appkit4/react-components';
import OptimisedCoacheesTable from '../../components/tables/postallocation/OptimisedCoacheesTable';
import { useGetV1AllocationOptimisedAllocationId } from '../../api/generated/endpoint';
import {
    CareerCoach,
    OptimisedAllocationResponse,
    OptimisedCoachee,
} from '../../api/generated/models';
import { PartialCoachee } from '../../models/PartialCoachee';
import { IndexedCoachData } from '../../models/indexedCoachData';
import { AppRoutes } from '../../Constants';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import OptimisedCoachesTable from '../../components/tables/postallocation/OptimisedCoachesTable';

import './ReviewAllocation.scss';
import ExportAllocationResults from '../../components/ExportAllocationResults';

const ReviewAllocation = () => {
    const location = useLocation();
    const navigate = useNavigate();

    // Check if accessed without allocationId
    if (!location.state.allocationId) {
        navigate(AppRoutes.HomePage, { replace: true });
    }

    const { l } = useLocale();
    const showToast = useToast();

    const defaultTabLabel = 1;
    const [activeTab, setActiveTab] = useState<number>(defaultTabLabel);

    const [allocationName, setAllocationName] = useState('');

    const [coacheeData, setCoacheeData] = useState<OptimisedCoachee[]>([]);
    const [filteredCoacheeData, setFilteredCoacheeData] = useState<
        OptimisedCoachee[]
    >([]);

    const [coachData, setCoachData] = useState<IndexedCoachData[]>([]);
    const [filteredCoachData, setFilteredCoachData] = useState<
        IndexedCoachData[]
    >([]);

    const { isFetching: isAllocationsLoading, refetch } =
        useGetV1AllocationOptimisedAllocationId(location.state.allocationId, {
            query: {
                refetchOnWindowFocus: false,
                onSuccess: (data: OptimisedAllocationResponse) => {
                    if (
                        data.optimisedAllocationData != null ||
                        data.optimisedAllocationData != undefined
                    ) {
                        setAllocationName(
                            data.optimisedAllocationData.name as string
                        );

                        const coachees = data.optimisedAllocationData
                            .coachees as OptimisedCoachee[];

                        setCoacheeData(coachees);
                        setFilteredCoacheeData(coachees);

                        const coachList: IndexedCoachData[] = [];

                        data.optimisedAllocationData.coaches?.forEach(
                            (coach: CareerCoach) => {
                                const indexedCoach = coach as IndexedCoachData;

                                const partialCoacheesList: PartialCoachee[] =
                                    [];

                                coachees.forEach(
                                    (coachee: OptimisedCoachee) => {
                                        if (
                                            coachee.careerCoach?.id &&
                                            coach.id &&
                                            parseInt(coachee.careerCoach?.id) ==
                                                parseInt(coach.id)
                                        ) {
                                            const partialCoachee: PartialCoachee =
                                                {
                                                    name: coachee.name,
                                                    warnings: coachee.warnings,
                                                };

                                            partialCoacheesList.push(
                                                partialCoachee
                                            );
                                        }
                                    }
                                );

                                indexedCoach.coachees = partialCoacheesList;

                                coachList.push(indexedCoach);
                            }
                        );

                        setCoachData(coachList);
                        setFilteredCoachData(coachList);
                    }
                },
                onError: (error: AxiosError<string>) => {
                    navigate(-1);

                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            },
        });
    return (
        <div className="postallocation-page-wrapper">
            <h2 className="postallocation-title">{l('_reviewAllocations')}</h2>
            <Tabs
                className="postallocation-tabs"
                type="underline"
                stretched={false}
                activeIndex={activeTab}
                defaultActiveIndex={defaultTabLabel}
                onTabChange={(i: number) => {
                    setActiveTab(i);
                }}
                showBothIndicators
            >
                <Tab label={l('_coachees')} />
                <Tab label={l('_careerCoach')} />
            </Tabs>
            <div className={`${activeTab == 0 ? '' : 'hidden'}`}>
                <OptimisedCoacheesTable
                    coachees={coacheeData}
                    filteredCoachees={filteredCoacheeData}
                    isLoadingCoachees={isAllocationsLoading}
                    onFilterUpdated={(coachees) =>
                        setFilteredCoacheeData(coachees)
                    }
                    allocationId={location.state.allocationId}
                    refetchData={() => refetch()}
                />
            </div>
            <div className={`${activeTab == 1 ? '' : 'hidden'}`}>
                <OptimisedCoachesTable
                    coaches={coachData}
                    filteredCoaches={filteredCoachData}
                    isLoadingCoaches={isAllocationsLoading}
                    onFilterUpdated={(coaches) => setFilteredCoachData(coaches)}
                    allocationId={location.state.allocationId}
                    refetchData={() => refetch()}
                />
            </div>
            <div className="postallocation-button-wrapper">
                <Button
                    className="postallocation-left-button-component"
                    kind="secondary"
                    icon="icon-left-chevron-outline"
                    onClick={() => {
                        navigate(-1);
                    }}
                >
                    {l('_back')}
                </Button>
                <span className="postallocation-right-button-component">
                    <ExportAllocationResults
                        optimisedCoacheeData={filteredCoacheeData}
                        optimisedCoachData={coachData}
                        fileName={`${allocationName}_${new Date().toISOString()}`}
                    />
                    <Button
                        kind="primary"
                        icon="icon-bargraph-outline"
                        onClick={() => {
                            navigate(AppRoutes.AllocationDashboard, {
                                replace: true,
                                state: {
                                    allocationId: location.state.allocationId,
                                    fromPage: AppRoutes.ReviewAllocation,
                                },
                            });
                        }}
                    >
                        {l('_viewDashboards')}
                    </Button>
                </span>
            </div>
        </div>
    );
};

export default ReviewAllocation;
