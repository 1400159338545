import React, { useState } from 'react';
import { Button, Input } from '@appkit4/react-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import useLocale from '../../locale/useLocale';

interface SuperAdminCreateBusinessRuleFormProps {
    submitFunc: (values: FormValues) => void;
}

export interface FormValues {
    name: string;
    rules: string;
}

const SuperAdminCreateBusinessRuleForm = (
    props: SuperAdminCreateBusinessRuleFormProps
) => {
    const { l } = useLocale();

    const NewBusinessUnitSchema = Yup.object({
        name: Yup.string()
            .trim()
            .max(50, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterABusinessRuleName')),
        rules: Yup.string()
            .trim()
            .max(200, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterABusinessRule')),
    });

    return (
        <Formik
            initialValues={{
                name: '',
                rules: '',
            }}
            validationSchema={NewBusinessUnitSchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
                values,
            }) => (
                <Form>
                    <Input
                        type="text"
                        title={l('_businessRuleName')}
                        placeholder={l('_businessRuleName')}
                        required
                        onChange={(value: string) => {
                            setFieldTouched('name');
                            validateField('name');
                            setFieldValue('name', value);
                        }}
                    />
                    {touched.name && errors.name && (
                        <div className="ap-text-states-error-04">
                            {errors.name}
                        </div>
                    )}
                    <Input
                        type="text"
                        className="mt-3"
                        title={l('_businessRules')}
                        placeholder="{rule}|{rule}|{rule}..."
                        required
                        onChange={(value: string) => {
                            setFieldTouched('rules');
                            validateField('rules');
                            setFieldValue('rules', value);
                        }}
                    />
                    {touched.rules && errors.rules && (
                        <div className="ap-text-states-error-04">
                            {errors.rules}
                        </div>
                    )}
                    <Button className="ms-auto mt-3" type="submit">
                        {l('_createNewBusinessRule')}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};

export default SuperAdminCreateBusinessRuleForm;
