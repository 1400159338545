import React, { useEffect } from 'react';
import { Button } from '@appkit4/react-components/button';
import { useAuth } from 'react-oidc-context';
import './LandingPage.scss';
import { Navigate, useLocation } from 'react-router-dom';
import { AppRoutes, LoginRedirectStorageKey } from '../../Constants';
import PwCLogoWhite from '../../assets/PwC_Outline_Logo_White.png';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const LandingPage = () => {
    const auth = useAuth();
    const location = useLocation();
    const { l } = useLocale();

    useTitle(l('_applicationName'));

    useEffect(() => {
        document.body.classList.add('landing');

        return () => {
            document.body.classList.remove('landing');
        };
    }, []);

    if (auth.isAuthenticated) {
        return <Navigate to={AppRoutes.HomePage} />;
    }

    const handleLogIn = () => {
        const returnRoute = location.state?.returnRoute;

        if (returnRoute) {
            window.sessionStorage.setItem(LoginRedirectStorageKey, returnRoute);
        }

        auth.signinRedirect();
    };

    return (
        <div>
            <img
                src={PwCLogoWhite as string}
                alt={l('_PwCLogo')}
                className="logo"
            />
            <div className="content">
                <div>
                    <h1 className="title text-wrap">{l('_applicationName')}</h1>
                </div>
                {!auth.isAuthenticated && (
                    <div>
                        <Button
                            onClick={handleLogIn}
                            className="login-btn ap-mt-spacing-8"
                        >
                            {l('_logInUsingPwCSSO')}
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default LandingPage;
