import { useAuth } from 'react-oidc-context';
import Sidebar from './Sidebar';

const RestrictedSidebar = () => {
    const auth = useAuth();

    return auth.isAuthenticated ? <Sidebar /> : <></>;
};

export default RestrictedSidebar;
