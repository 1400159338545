import React, { useState } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { Table, Column } from '@appkit4/react-components/table';
import { Button, Input, Select, TextArea } from '@appkit4/react-components';
import { useQueryClient } from '@tanstack/react-query';
import {
    PutV1BusinessUnitSuperAdminBusinessUnitIdMutationError,
    getGetV1BusinessUnitSuperAdminQueryKey,
    usePutV1BusinessUnitSuperAdminBusinessUnitId,
    useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitId,
    usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitId,
    usePutV1BusinessUnitSuperAdminReportBusinessUnitId,
    usePutV1BusinessUnitSuperAdminRulesBusinessUnitId,
} from '../../api/generated/endpoint';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import AdminEditUserModal from '../AdminEditUserModal';
import AdminConfirmDeleteUserModal from '../AdminConfirmDeleteUserModal';
import SuperAdminRenameBusinessUnitForm, {
    FormValues,
} from './SuperAdminRenameBusinessUnitForm';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import UserManagementActionsCell from '../UserManagementActionsCell';
import {
    BusinessUnitConfigurationResponse,
    SuperAdminBusinessUnitResponse,
    UploadValidationRule,
    UserResponse,
} from '../../api/generated/models';
import AdminCreateUserModal from '../AdminCreateUserModal';

export interface ISuperAdminRenameBusinessUnitModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
    businessUnit: SuperAdminBusinessUnitResponse | undefined;
}

const SuperAdminEditBusinessUnitModal = (
    props: ISuperAdminRenameBusinessUnitModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();

    const renameBusinessUnitRequest =
        usePutV1BusinessUnitSuperAdminBusinessUnitId();

    const updateReportIdRequest =
        usePutV1BusinessUnitSuperAdminReportBusinessUnitId();

    const updateLandingPageContentRequest =
        usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitId();

    const updateBusinessUnitUploadRuleRequest =
        usePutV1BusinessUnitSuperAdminRulesBusinessUnitId();

    const queryClient = useQueryClient();

    const [displayCreateUserModal, setDisplayCreateUserModal] = useState(false);

    const [reportId, setReportId] = useState('');
    const [objectives, setObjectives] = useState('');
    const [rules, setRules] = useState('');
    const [currentUploadRule, setCurrentUploadRule] =
        useState<UploadValidationRule>();
    const [selectedUploadRuleId, setSelectedUploadRuleId] = useState<
        number | undefined
    >(0);

    const [uploadRules, setUploadRules] = useState<UploadValidationRule[]>([]);

    const [updatingUploadRule, setUpdatingUploadRule] = useState(false);
    const [updatingReportId, setUpdatingReportId] = useState(false);
    const [updatingLandingContent, setUpdatingLandingContent] = useState(false);

    type ValueType = string | number;
    type SelectValue = ValueType | ValueType[];

    const [
        showDeleteUserConfirmationForId,
        setShowDeleteUserConfirmationForId,
    ] = useState<number | null>(null);

    const [displayEditUserModalForId, setDisplayEditUserModalForId] = useState<
        number | null
    >(null);

    const updateUploadRuleAsync = async () => {
        setUpdatingUploadRule(true);

        await updateBusinessUnitUploadRuleRequest.mutateAsync(
            {
                businessUnitId: props?.businessUnit?.id ?? -1,
                data: {
                    businessRuleId: selectedUploadRuleId ?? -1,
                },
            },
            {
                onSuccess: () => {
                    setUpdatingUploadRule(false);
                },
                onError: (error: any) => {
                    setUpdatingUploadRule(false);

                    props.setNotVisible();

                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    const updateReportIdAsync = async () => {
        setUpdatingReportId(true);

        await updateReportIdRequest.mutateAsync(
            {
                businessUnitId: props?.businessUnit?.id as number,
                data: { reportId },
            },
            {
                onSuccess: () => {
                    setUpdatingReportId(false);
                },
                onError: (error: any) => {
                    setUpdatingReportId(false);

                    props.setNotVisible();

                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    const updateLandingPageContent = async () => {
        setUpdatingLandingContent(true);

        await updateLandingPageContentRequest.mutateAsync(
            {
                businessUnitId: props?.businessUnit?.id as number,
                data: { objectives, rules },
            },
            {
                onSuccess: () => {
                    setUpdatingLandingContent(false);
                },
                onError: (error: any) => {
                    setUpdatingLandingContent(false);

                    props.setNotVisible();

                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    const putRenameBusinessUnitRequest = (values: FormValues) => {
        renameBusinessUnitRequest.mutate(
            {
                businessUnitId: props.businessUnit?.id ?? -1,
                data: { name: values.name },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_businessUnitNameUpdated'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                },
                onError: (
                    error: PutV1BusinessUnitSuperAdminBusinessUnitIdMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitId<BusinessUnitConfigurationResponse>(
        props.businessUnit?.id as number,
        {
            query: {
                enabled: true,
                retry: false,
                refetchOnWindowFocus: false,
                onSuccess: (data: BusinessUnitConfigurationResponse) => {
                    setObjectives(data.objectives as string);
                    setRules(data.rules as string);

                    setReportId(data.reportId as string);

                    setCurrentUploadRule(data.currentUploadRule);
                    setSelectedUploadRuleId(data.currentUploadRule?.id);
                    setUploadRules(data.uploadRules ?? []);
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            },
        }
    );

    const actionsColumnStyle: React.CSSProperties = {
        width: '6rem',
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_editBusinessUnit')}
                        aria-label={l('_editBusinessUnit')}
                        modalStyle={{
                            width: '75vw',
                            maxWidth: '750px',
                            minWidth: '500px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        <SuperAdminRenameBusinessUnitForm
                            submitFunc={putRenameBusinessUnitRequest}
                            initialName={props.businessUnit?.name ?? '-'}
                        />
                        <Table
                            className="admin-table-container"
                            originalData={props.businessUnit?.admins}
                            condensed
                            striped
                            hasTitle
                        >
                            <Column field="forenames">
                                {l('_forename(s)')}
                            </Column>
                            <Column field="surname">{l('_surname')}</Column>
                            <Column field="email">{l('_email')}</Column>
                            <Column field="roleName">{l('_role')}</Column>
                            <Column
                                renderCell={(row: UserResponse) => (
                                    <UserManagementActionsCell
                                        user={row}
                                        displayEditUserModal={() =>
                                            setDisplayEditUserModalForId(row.id)
                                        }
                                        displayDeleteUserModal={() =>
                                            setShowDeleteUserConfirmationForId(
                                                row.id
                                            )
                                        }
                                    />
                                )}
                                field="id"
                                style={actionsColumnStyle}
                            >
                                {l('_actions')}
                            </Column>
                        </Table>
                        <h2 className="ap-typography-heading-s">
                            {l('_businessRules')}
                        </h2>
                        <div className="row focus-margin">
                            <div className="col">
                                <Select
                                    data={uploadRules ?? []}
                                    valueKey="id"
                                    labelKey="name"
                                    disabled={uploadRules.length <= 1}
                                    searchable
                                    placeholder={currentUploadRule?.name ?? ''}
                                    noResultFound={l('_noResultsFound')}
                                    onSelect={(value: SelectValue) => {
                                        setSelectedUploadRuleId(
                                            value as number
                                        );
                                    }}
                                />
                            </div>
                            <div className="col-auto">
                                <Button
                                    className="ms-auto align-top h-100"
                                    style={{ paddingTop: '12px' }}
                                    disabled={(selectedUploadRuleId ?? 0) <= 0}
                                    type="submit"
                                    loading={updatingUploadRule}
                                    onClick={updateUploadRuleAsync}
                                >
                                    {l('_editBusinessRule')}
                                </Button>
                            </div>
                        </div>
                        <h2 className="ap-typography-heading-s">
                            {l('_dashboardReport')}
                        </h2>
                        <div className="row focus-margin">
                            <div className="col">
                                <Input
                                    type="text"
                                    title={l('_dashboardReport')}
                                    placeholder="00000000-0000-0000-0000-000000000000"
                                    value={reportId}
                                    onChange={(data: string) => {
                                        setReportId(data);
                                    }}
                                />
                            </div>
                            <div className="col-auto">
                                <Button
                                    className="ms-auto align-top h-100"
                                    style={{ paddingTop: '12px' }}
                                    type="submit"
                                    loading={updatingReportId}
                                    onClick={updateReportIdAsync}
                                >
                                    {l('_updateReport')}
                                </Button>
                            </div>
                        </div>
                        <h2 className="ap-typography-heading-s">
                            {l('_landingPageContent')}
                        </h2>
                        <TextArea
                            title={l('_objectives')}
                            value={objectives}
                            onChange={(data) => {
                                setObjectives(data);
                            }}
                            maxLength={500}
                        ></TextArea>
                        <TextArea
                            style={{ marginTop: '1rem' }}
                            title={l('_businessRules')}
                            value={rules}
                            onChange={(data) => {
                                setRules(data);
                            }}
                            maxLength={500}
                        ></TextArea>
                        <Button
                            kind="primary"
                            type="button"
                            role="button"
                            onClick={updateLandingPageContent}
                            className="ms-auto align-top h-75"
                            loading={updatingLandingContent}
                            style={{ marginTop: '1rem' }}
                        >
                            {l('_updateLandingPageContent')}
                        </Button>
                    </Modal>
                </ModalContainer>
            )}

            {displayCreateUserModal && (
                <AdminCreateUserModal
                    isVisible={displayCreateUserModal}
                    setIsVisible={setDisplayCreateUserModal}
                    businessUnits={
                        props.businessUnit ? [props.businessUnit] : []
                    }
                    currentBusinessUnitId={props.businessUnit?.id}
                />
            )}

            {displayEditUserModalForId && props.businessUnit && (
                <AdminEditUserModal
                    isVisible={displayEditUserModalForId != null}
                    setNotVisible={() => {
                        setDisplayEditUserModalForId(null);
                    }}
                    user={props.businessUnit.admins?.find(
                        (a) => a.id == displayEditUserModalForId
                    )}
                />
            )}

            {showDeleteUserConfirmationForId && props.businessUnit && (
                <AdminConfirmDeleteUserModal
                    visible={showDeleteUserConfirmationForId != null}
                    userInfo={props.businessUnit.admins?.find(
                        (a) => a.id == showDeleteUserConfirmationForId
                    )}
                    setNotVisible={() => {
                        setShowDeleteUserConfirmationForId(null);
                    }}
                />
            )}
        </>
    );
};

export default SuperAdminEditBusinessUnitModal;
