import { Search } from '@appkit4/react-components';
import React, { useState } from 'react';

interface GlobalSearchProps {
    onSearch: (searchString: string) => void;
}

const GlobalSearch: React.FC<GlobalSearchProps> = ({ onSearch }) => {
    const [searchString, setSearchString] = useState('');

    const handleSearchChange = (
        value: string,
        event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>
    ) => {
        const newSearchString = (event.target as HTMLInputElement).value;
        setSearchString(newSearchString);
        onSearch(newSearchString);
    };

    return (
        <Search
            className="search-bar"
            placeholder="Search..."
            value={searchString}
            onChange={handleSearchChange}
        />
    );
};

export default GlobalSearch;
