import React, { useState, useEffect, FC } from 'react';
import {
    Table,
    Column,
    Tooltip,
    Badge,
    Panel,
    Loading,
} from '@appkit4/react-components';
import useLocale from '../../../locale/useLocale';
import GlobalSearch from '../../GlobalSearch';
import GlobalPagination from '../../GlobalPagination';
import { usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationId } from '../../../api/generated/endpoint';
import useToast from '../../../helpers/useToast';

import { IndexedCoachData } from '../../../models/indexedCoachData';

import './OptimisedCoachesTable.scss';
import EditNotesModal from '../../EditNotesModal';

type ValueType = string | number;
type SelectValue = ValueType | ValueType[];

interface OptimisedCoachTableProps {
    coaches: IndexedCoachData[];
    filteredCoaches: IndexedCoachData[];
    isLoadingCoaches: boolean;
    onFilterUpdated: (values: IndexedCoachData[]) => void;
    allocationId: number;
    refetchData: () => void;
}

const OptimisedCoachesTable: FC<OptimisedCoachTableProps> = ({
    coaches,
    filteredCoaches,
    isLoadingCoaches,
    onFilterUpdated,
    allocationId,
    refetchData,
}) => {
    const { l } = useLocale();

    const showToast = useToast();

    const [dropdownValue, setDropdownValue] = React.useState<SelectValue>(10);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(10);
    const [total, setTotal] = useState(0);

    const [searchString, setSearchString] = useState<string>('');

    const [showWithoutCoachees, setShowWithoutCoachees] = useState(false);
    const [showWithWarning, setShowWithWarning] = useState(false);

    const updateNotes =
        usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationId();

    const [selectedCoach, setSelectedCoach] = useState<IndexedCoachData>();

    const [displayEditNotesModal, setDisplayEditNotesModal] = useState(false);

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const onPageSizeSelect = (value: SelectValue) => {
        const selectedPageSize = Number(value);

        setItemsPerPage(selectedPageSize);
        setCurrentPage(1);
    };

    const renderTooltip = (
        row: any | IndexedCoachData,
        field: keyof IndexedCoachData
    ) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-${field}`}
                content={`${row[field]}`}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row[field]}</span>
            </Tooltip>
        );
    };

    const renderCoacheesCell = (row: IndexedCoachData) => {
        // If no coachees, represent as minus symbol
        if (row.coachees.length == 0) {
            return <span className="Appkit4-icon icon-minus-fill" />;
        }

        // Else render the coachees in a list, with any warnings
        return (
            <>
                {row.coachees?.map((coachee, index) => (
                    <div key={index}>
                        <span>{coachee?.name}</span>
                        {coachee.warnings && coachee.warnings.length > 0 && (
                            <Tooltip
                                content={() => (
                                    <ul>
                                        {coachee.warnings?.map(
                                            (warning, index) => (
                                                <li key={index}>{warning}</li>
                                            )
                                        )}
                                    </ul>
                                )}
                            >
                                <button
                                    className="Appkit4-icon icon-circle-warning-outline"
                                    style={{ color: '#D04A02' }}
                                ></button>
                            </Tooltip>
                        )}
                    </div>
                ))}
            </>
        );
    };

    const renderEmploymentTypeCell = (row: IndexedCoachData) => {
        // If no Employment Type, represent as minus symbol
        if (row.employmentType?.length == 0 || !row.employmentType) {
            return <span className="Appkit4-icon icon-minus-fill" />;
        }

        return renderTooltip(row, 'employmentType');
    };

    const handleWithoutCoacheesClick = () => {
        if (!showWithoutCoachees && filteredCoaches.length === 0) {
            return;
        }

        setShowWithoutCoachees(!showWithoutCoachees);
    };

    const handleWithWarningClick = () => {
        if (!showWithWarning && filteredCoaches.length === 0) {
            return;
        }

        setShowWithWarning(!showWithWarning);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortFuncForName1 = (a: any, b: any) => {
        return a.name.localeCompare(b?.name);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortFuncForName2 = (a: any, b: any) => {
        return b.name.localeCompare(a.name);
    };

    const sortNumberOfCoacheesDesc = (
        a: IndexedCoachData,
        b: IndexedCoachData
    ) => {
        return a.coachees.length < b.coachees.length ? 1 : -1;
    };

    const sortNumberOfCoacheesAsc = (
        a: IndexedCoachData,
        b: IndexedCoachData
    ) => {
        return a.coachees.length > b.coachees.length ? 1 : -1;
    };

    useEffect(() => {
        const maxPageNum = filteredCoaches
            ? Math.ceil(filteredCoaches.length / itemsPerPage)
            : null;

        if (filteredCoaches && maxPageNum && currentPage > maxPageNum) {
            setCurrentPage(maxPageNum);
        }

        setTotal(
            filteredCoaches
                ? Math.ceil(filteredCoaches.length / itemsPerPage)
                : 0
        );
    }, [filteredCoaches, itemsPerPage, currentPage]);

    const filterData = () => {
        let updatedFilteredData = coaches;

        if (showWithoutCoachees) {
            updatedFilteredData = updatedFilteredData.filter(
                (coach) => coach.coachees.length == 0
            );
        }

        if (showWithWarning) {
            updatedFilteredData = updatedFilteredData.filter((coach) =>
                coach.coachees.some(
                    (coach) => coach.warnings && coach.warnings.length > 0
                )
            );
        }

        if (searchString) {
            const lowercaseSearch = searchString.toLowerCase();

            updatedFilteredData = updatedFilteredData.filter(
                (item) =>
                    item.name?.toLowerCase().includes(lowercaseSearch) ||
                    item.costCenter?.toLowerCase().includes(lowercaseSearch) ||
                    item.grade?.toLowerCase().includes(lowercaseSearch)
            );
        }

        onFilterUpdated(updatedFilteredData);

        // On change of filters, set to page 1
        setCurrentPage(1);
    };

    useEffect(() => {
        filterData();
    }, [showWithoutCoachees, showWithWarning, searchString, coaches]);

    const renderNotesCell = (row: IndexedCoachData) => {
        return (
            <div style={{ display: 'flex', maxWidth: '12rem' }}>
                <div
                    className="notes-container"
                    style={{
                        maxWidth: '10rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {row.notes}
                </div>
                <span
                    className="Appkit4-icon icon-edit-outline"
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        color: '#D04A02',
                    }}
                    onClick={() => {
                        setDisplayEditNotesModal(true);
                        setSelectedCoach(row);
                    }}
                />
            </div>
        );
    };

    const editNotes = async (value: string) => {
        await updateNotes.mutate(
            {
                allocationId: allocationId,
                data: {
                    id: selectedCoach?.id,
                    notes: value,
                    isCoachee: false,
                },
            },
            {
                onSuccess: async () => {
                    showToast(l('_success'), '', 'success');
                    setDisplayEditNotesModal(false);

                    refetchData();
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    return (
        <div>
            <Panel className="postallocation-panel-wrapper">
                {!isLoadingCoaches ? (
                    <>
                        <div className="ap-table-coaches">
                            <div>
                                <div className="search-sample">
                                    <GlobalSearch
                                        onSearch={(searchString) => {
                                            setSearchString(searchString);
                                            filterData();
                                        }}
                                    />
                                    <div className="filter-pills">
                                        <Badge
                                            className={`postallocation-pill ${
                                                showWithoutCoachees
                                                    ? 'clicked'
                                                    : filteredCoaches.length ===
                                                      0
                                                    ? 'pill-disabled'
                                                    : ''
                                            }`}
                                            role="button"
                                            value={l('_withoutCoacheesFilter')}
                                            onClick={handleWithoutCoacheesClick}
                                        ></Badge>
                                        <Badge
                                            className={`postallocation-pill ${
                                                showWithWarning
                                                    ? 'clicked'
                                                    : filteredCoaches.length ===
                                                      0
                                                    ? 'pill-disabled'
                                                    : ''
                                            }`}
                                            role="button"
                                            value={l('_withWarningFilter')}
                                            onClick={handleWithWarningClick}
                                        ></Badge>
                                    </div>
                                </div>
                            </div>
                            <div className="coaches-count-component">
                                <h4>
                                    {filteredCoaches?.length}{' '}
                                    {l('_allocations')}
                                </h4>
                            </div>
                            <div className="coaches-table-container">
                                <Table
                                    className="ap-table-coaches"
                                    originalData={filteredCoaches}
                                    hasTitle
                                    currentPage={currentPage}
                                    pageSize={itemsPerPage}
                                >
                                    <Column
                                        field="name"
                                        sortKey="name"
                                        sortFunc1={sortFuncForName1}
                                        sortFunc2={sortFuncForName2}
                                    >
                                        {l('_name')}
                                    </Column>
                                    <Column
                                        field="coachees"
                                        sortKey="coachees"
                                        renderCell={renderCoacheesCell}
                                        sortFunc1={sortNumberOfCoacheesDesc}
                                        sortFunc2={sortNumberOfCoacheesAsc}
                                    >
                                        {l('_coachees')}
                                    </Column>
                                    <Column field="grade" sortKey="grade">
                                        {l('_coachGrade')}
                                    </Column>
                                    <Column
                                        field="costCenter"
                                        sortKey="costCenter"
                                        renderCell={(row: IndexedCoachData) => {
                                            return (
                                                <>
                                                    <span className="overflow">
                                                        {row.costCenter}
                                                    </span>
                                                </>
                                            );
                                        }}
                                    >
                                        {l('_coachCostCenter')}
                                    </Column>
                                    <Column
                                        field="employmentType"
                                        sortKey="employmentType"
                                        renderCell={renderEmploymentTypeCell}
                                    >
                                        {l('_employmentType')}
                                    </Column>
                                    <Column field="email" sortKey="email">
                                        {l('_email')}
                                    </Column>
                                    <Column field="id" sortKey="id">
                                        {l('_coachWorkdayId')}
                                    </Column>
                                    <Column
                                        field="notes"
                                        renderCell={renderNotesCell}
                                    >
                                        {l('_notes')}
                                    </Column>
                                </Table>
                            </div>
                        </div>
                        {filteredCoaches.length == 0 && (
                            <h5 className="text-center mt-2 ap-text-states-error-03">
                                {l('_noSearchResults')}
                            </h5>
                        )}
                    </>
                ) : (
                    <Loading
                        loadingType="circular"
                        indeterminate
                        compact={false}
                    />
                )}
            </Panel>
            <GlobalPagination
                dropdownValue={dropdownValue}
                currentPage={currentPage}
                totalPages={total}
                onPageChange={onPageChange}
                onDropdownSelect={(selectedValue) => {
                    setDropdownValue(selectedValue);
                    onPageSizeSelect(selectedValue);
                }}
            />
            {displayEditNotesModal && (
                <EditNotesModal
                    isVisible={true}
                    setIsVisible={setDisplayEditNotesModal}
                    onEdit={(value) => {
                        editNotes(value);
                    }}
                    name={selectedCoach?.name || ''}
                    currentNotes={selectedCoach?.notes || ''}
                    editNotesModalTitle={l('_careerCoach')}
                />
            )}
        </div>
    );
};

export default OptimisedCoachesTable;
