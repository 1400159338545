import React from 'react';
import { Modal } from '@appkit4/react-components/modal';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import SuperAdminCreateContactPersonForm from './SuperAdminCreateContactPersonForm';
import { CreateContactPersonRequest } from '../../api/generated/models';
import {
    PostV1ContactPersonSuperAdminApplicationMutationError,
    getGetV1ContactPersonApplicationQueryKey,
    getGetV1ContactPersonSuperAdminQueryKey,
    usePostV1ContactPersonSuperAdminApplication,
} from '../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import useLocale from '../../locale/useLocale';

interface ISuperAdminCreateApplicationContactModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
}

const SuperAdminCreateApplicationContactModal = (
    props: ISuperAdminCreateApplicationContactModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createNewApplicationContact =
        usePostV1ContactPersonSuperAdminApplication();
    const queryClient = useQueryClient();

    const postNewApplicationContactRequest = (
        newApplicationContact: CreateContactPersonRequest
    ) => {
        createNewApplicationContact.mutate(
            { data: newApplicationContact },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_newApplicationContactCreated'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonApplicationQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonSuperAdminQueryKey()
                    );
                },
                onError: (
                    error: PostV1ContactPersonSuperAdminApplicationMutationError
                ) => {
                    showGenericErrorToast(error);
                    props.setNotVisible();
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible
                    closable
                    title={l('_createNewApplicationContact')}
                    aria-label={l('_createANewApplicationContact')}
                    modalStyle={{
                        width: '50vw',
                        maxWidth: '500px',
                        minWidth: '250px',
                    }}
                    footerStyle={{ marginTop: '1rem' }}
                    closeOnPressEscape
                    onCancel={() => props.setNotVisible()}
                >
                    <SuperAdminCreateContactPersonForm
                        submitFunc={postNewApplicationContactRequest}
                    />
                </Modal>
            )}
        </>
    );
};

export default SuperAdminCreateApplicationContactModal;
