import { Button } from '@appkit4/react-components';
import useLocale from '../../locale/useLocale';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppRoutes } from '../../Constants';
import { useGetV1DashboardEngagementsEmbed } from '../../api/generated/endpoint';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { useState } from 'react';
import { EmbedResponse } from '../../api/generated/models';
import './AllocationDashboard.scss';
import useToast from '../../helpers/useToast';
import { AxiosError } from 'axios';

export const AllocationDashboard = () => {
    const { l } = useLocale();
    const location = useLocation();
    const navigate = useNavigate();
    const showToast = useToast();
    const [token, setToken] = useState('');
    const [reportID, setReportID] = useState('');
    const [embedURL, setEmbedURL] = useState('');

    const cameFromReviewAllocation =
        location.state?.fromPage === AppRoutes.ReviewAllocation;

    useGetV1DashboardEngagementsEmbed({
        query: {
            enabled: true,
            retry: false,
            refetchInterval: 900000, // 15 minutes
            refetchOnWindowFocus: false,
            onSuccess: (response: EmbedResponse) => {
                setToken(response?.token || '');
                setEmbedURL(response?.embedUrl || '');
                setReportID(response?.reportId || '');
            },
            onError: (error: AxiosError<string>) => {
                showToast(
                    l('_error'),
                    error.response?.data || error.message,
                    'error'
                );
            },
        },
    });

    return (
        <>
            <div>
                <h2>{l('_allocationDashboardTitle')}</h2>
            </div>
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',
                    id: reportID,
                    embedUrl: embedURL,
                    accessToken: token,
                    tokenType: models.TokenType.Embed,
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false,
                            },
                        },
                        background: models.BackgroundType.Transparent,
                    },
                }}
                cssClassName={'bi-embedded'}
            />
            {cameFromReviewAllocation && (
                <Button
                    style={{ marginTop: '3%' }}
                    kind="secondary"
                    icon="icon-left-chevron-outline"
                    onClick={() => {
                        navigate(AppRoutes.ReviewAllocation, {
                            replace: true,
                            state: {
                                allocationId: location.state.allocationId,
                            },
                        });
                    }}
                >
                    {l('_allocationDashboardBackButton')}
                </Button>
            )}
        </>
    );
};

export default AllocationDashboard;
