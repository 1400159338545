import XLSX from 'sheetjs-style';
import FileSaver from 'file-saver';
import { Button } from '@appkit4/react-components';
import useLocale from '../locale/useLocale';
import { FC } from 'react';
import { OptimisedCoachee } from '../api/generated/models';
import { IndexedCoachData } from '../models/indexedCoachData';
import useToast from '../helpers/useToast';

interface ExportAllocationResultsProps {
    optimisedCoacheeData: OptimisedCoachee[];
    optimisedCoachData: IndexedCoachData[];
    fileName: string;
}

const ExportAllocationResults: FC<ExportAllocationResultsProps> = ({
    optimisedCoacheeData,
    optimisedCoachData,
    fileName,
}) => {
    const { l } = useLocale();
    const showToast = useToast();

    const fileType =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';

    const exportToExcel = async () => {
        const coacheeHeaders = [
            'Coachee ID',
            'Coachee',
            'Coachee Grade',
            'Coachee Cost Center',
            'Career Coach',
            'Career Coach ID',
            'Relationship Leader',
            'Coachee Email',
        ];

        const coachHeaders = [
            'Coach',
            'Coach ID',
            'Coach Grade',
            'Coach Cost Center',
            'Employment',
            'Coach Email',
        ];

        const coacheeData = optimisedCoacheeData.map((coachee) => [
            coachee.id,
            coachee.name,
            coachee.grade,
            coachee.costCenter,
            coachee.careerCoach?.name,
            coachee.careerCoach?.id,
            coachee.relationshipLeader,
            coachee.email,
        ]);

        const coachData = optimisedCoachData.map((coach) => [
            coach.name,
            coach.id,
            coach.grade,
            coach.costCenter,
            coach.employmentType,
            coach.email,
        ]);

        const ws = XLSX.utils.aoa_to_sheet([coacheeHeaders, ...coacheeData]);
        const ws2 = XLSX.utils.aoa_to_sheet([coachHeaders, ...coachData]);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Coachees');
        XLSX.utils.book_append_sheet(wb, ws2, 'Coaches');

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);

        showToast(
            'Success',
            'Allocation result download initiated.',
            'success'
        );
    };

    return (
        <>
            <Button
                className="postallocation-button-component"
                style={{
                    position: 'relative',
                    float: 'left',
                    marginRight: '1rem',
                }}
                kind="secondary"
                icon="icon-share-outline"
                disabled={optimisedCoacheeData.length == 0}
                onClick={exportToExcel}
            >
                {l('_export')}
            </Button>
        </>
    );
};

export default ExportAllocationResults;
