import React from 'react';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import { Loading } from '@appkit4/react-components/loading';
import { Panel } from '@appkit4/react-components/panel';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import './Policy.scss';
import { AppRoutes } from '../../Constants';
import { useGetV1PolicyCookies } from '../../api/generated/endpoint';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const Cookies = () => {
    const showGenericErrorToast = useGenericErrorToast();
    const { l } = useLocale();
    useTitle(l('_cookiePolicyTitleCase'));

    const { data: cookiesPolicy } = useGetV1PolicyCookies({
        query: {
            onError: showGenericErrorToast,
        },
    });

    return (
        <div>
            {cookiesPolicy ? (
                <>
                    <h1 data-cy="cookies-policy-title">
                        {l('_cookiePolicyTitleCase')}
                    </h1>
                    <div className="d-flex mt-4">
                        <span className="pwc-brand-icon icon-lightbulb icons" />
                        <p className="p-0 mb-0 ps-1">
                            {l('_lookingToUpdateYourCookiePreferences')}{' '}
                            <Link to={AppRoutes.SettingsPage}>
                                {l('_settings')}
                            </Link>{' '}
                            {l('_page')}
                        </p>
                    </div>
                    <Panel className="panel mt-2">
                        <ReactMarkdown
                            remarkPlugins={[RemarkGFM]}
                            className="table-bordered"
                        >
                            {cookiesPolicy.text}
                        </ReactMarkdown>
                    </Panel>
                </>
            ) : (
                <Loading loadingType="circular" indeterminate compact={false} />
            )}
        </div>
    );
};

export default Cookies;
