import { Modal } from '@appkit4/react-components/modal';
import {
    ContactPersonResponse,
    EditContactPersonRequest,
} from '../../api/generated/models';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import SuperAdminEditContactPersonForm from './SuperAdminEditContactPersonForm';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import {
    PutV1ContactPersonSuperAdminContactPersonIdMutationError,
    getGetV1ContactPersonSuperAdminQueryKey,
    getGetV1BusinessUnitSuperAdminQueryKey,
    usePutV1ContactPersonSuperAdminContactPersonId,
} from '../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';

interface SuperAdminEditContactPersonModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
    contactPerson: ContactPersonResponse | undefined;
}

const SuperAdminEditContactPersonModal = (
    props: SuperAdminEditContactPersonModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const editBusinessUnitContactPerson =
        usePutV1ContactPersonSuperAdminContactPersonId();
    const queryClient = useQueryClient();

    if (props.contactPerson === undefined) {
        showToast(
            l('_error'),
            l('_unableToRetrieveInfoForContactPerson'),
            'error'
        );
        props.setNotVisible();
    }

    const putContactPersonRequest = (
        contactPersonId: number,
        values: EditContactPersonRequest
    ) => {
        if (props.contactPerson === undefined) {
            return; // do not expect this to ever occur
        }
        editBusinessUnitContactPerson.mutate(
            { contactPersonId, data: values },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_contactPersonUpdatedSuccessfully'),
                        'success'
                    );
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonSuperAdminQueryKey()
                    );
                },
                onError: (
                    error: PutV1ContactPersonSuperAdminContactPersonIdMutationError
                ) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_editContactPerson')}
                        aria-label={l('_editContactPerson')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        <SuperAdminEditContactPersonForm
                            submitFunc={putContactPersonRequest}
                            currentContactPersonValues={
                                props.contactPerson as ContactPersonResponse
                            } // able to cast as ensured not undefined at start
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminEditContactPersonModal;
