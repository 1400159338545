import React from 'react';
import ReactMarkdown from 'react-markdown';
import RemarkGFM from 'remark-gfm';
import { Loading } from '@appkit4/react-components/loading';
import { Panel } from '@appkit4/react-components/panel';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import './Policy.scss';
import { useGetV1PolicyTerms } from '../../api/generated/endpoint';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const TandC = () => {
    const showGenericErrorToast = useGenericErrorToast();
    const { l } = useLocale();
    useTitle(l('_termsAndConditionsTitleCase'));

    const { data: termsAndConditions } = useGetV1PolicyTerms({
        query: {
            onError: showGenericErrorToast,
        },
    });

    return (
        <div>
            {termsAndConditions ? (
                <>
                    <h1 data-cy="terms-and-conditions-title">
                        {l('_termsAndConditionsTitleCase')}
                    </h1>
                    <Panel className="panel">
                        <ReactMarkdown remarkPlugins={[RemarkGFM]}>
                            {termsAndConditions.text}
                        </ReactMarkdown>
                    </Panel>
                </>
            ) : (
                <Loading loadingType="circular" indeterminate compact={false} />
            )}
        </div>
    );
};

export default TandC;
