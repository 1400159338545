import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useAuth } from 'react-oidc-context';

export const AXIOS_INSTANCE = Axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
});

export const useCustomFormAxios = <T>() => {
    const auth = useAuth();

    const customAxios = async (config: AxiosRequestConfig): Promise<T> => {
        const accessToken = auth.user?.access_token;
        const { data } = await AXIOS_INSTANCE({
            ...config,
            headers: {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    };

    return customAxios;
};

export type ErrorType<Error> = AxiosError<Error>;
