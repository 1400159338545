/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PwC Career Coach Allocator API
 * OpenAPI spec version: 1.0
 */

export type AllocationStatus =
    (typeof AllocationStatus)[keyof typeof AllocationStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AllocationStatus = {
    NUMBER_1: 1,
    NUMBER_2: 2,
    NUMBER_3: 3,
    NUMBER_4: 4,
} as const;
