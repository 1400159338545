type AllocationStatusKey = {
    [key: string]: string;
};

const AllocationStatuses: AllocationStatusKey = {
    '1': 'Pending',
    '2': 'Allocating',
    '3': 'Completed',
    '4': 'Failed',
};

export default AllocationStatuses;
