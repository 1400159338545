import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import useLogout from '../../auth/useLogout';
import { AppRoutes } from '../../Constants';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

const Logout = () => {
    const logout = useLogout();
    const navigate = useNavigate();
    const auth = useAuth();
    const { l } = useLocale();

    useTitle(l('_loggingOutTitleCase'));

    useEffect(() => {
        if (auth.isAuthenticated) {
            logout();
        } else {
            navigate(AppRoutes.LandingPage);
        }
    }, [auth.isAuthenticated]);

    return (
        <div className="d-flex flex-grow-1 align-items-center flex-column justify-content-center text-center w-100">
            <h1 className="px-2 py-1 mt-2 mb-0">{l('_loggingOutTitleCase')}</h1>
        </div>
    );
};

export default Logout;
