import React from 'react';
import { Avatar } from '@appkit4/react-components/avatar';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { ContactPersonResponse } from '../api/generated/models';

const avatarColors = [
    'ap-bg-primary-red-05',
    'ap-bg-primary-orange-05',
    'ap-bg-primary-pink-05',
    'ap-bg-primary-teal-07',
    'ap-bg-primary-blue-04',
];

const avatarStyle = {
    width: '23px',
    height: '23px',
    fontSize: '11px',
    fontWeight: 'normal',
    padding: '0',
};

export interface IContactAvatarProps {
    contact: ContactPersonResponse;
    index: number;
}

const ContactAvatar = (props: IContactAvatarProps) => {
    const avatarBgColor = avatarColors[props.index % avatarColors.length];

    return (
        <div key={props.index}>
            {/* display full name when hover over avatar */}
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`${props.index}`}
                content={`${props.contact.forenames} ${props.contact.surname}`}
            >
                <Avatar
                    label={`${props.contact.forenames
                        .charAt(0)
                        .toUpperCase()}${props.contact.surname
                        .charAt(0)
                        .toUpperCase()}`}
                    style={avatarStyle}
                    className={avatarBgColor}
                />
            </Tooltip>
        </div>
    );
};

export default ContactAvatar;
