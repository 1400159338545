import { AxiosError } from 'axios';
import useToast from './useToast';
import { ErrorType } from '../api/mutator/custom-instance';
import useLocale from '../locale/useLocale';

type ApiErrorType = string;

const useGenericErrorToast = () => {
    const { l } = useLocale();
    const showToast = useToast();
    // eslint-disable-next-line
    return (error: AxiosError<ApiErrorType> | ErrorType<any>) => {
        const errorTitle = error.response
            ? `${l('_error')}: ${error.response?.status.toString()}`
            : `${l('_error')}: ${error.code || l('_unknown')}`;
        showToast(errorTitle, error.response?.data || error.message, 'error');
    };
};

export default useGenericErrorToast;
