import { Modal } from '@appkit4/react-components/modal';
import { FormValues } from './AdjustCoachCapacityForm';
import useToast from '../helpers/useToast';
import useLocale from '../locale/useLocale';
import ModalContainer from './ModalContainer';
import {
    getGetV1BusinessUnitSuperAdminQueryKey,
    getGetV1CareerCoachCapacityCareerCoachCapacityIdQueryKey,
    useGetV1CareerCoachCapacityCareerCoachCapacityId,
    usePutV1CareerCoachCapacityCareerCoachCapacityId,
} from '../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import AdjustCoachCapacityForm from './AdjustCoachCapacityForm';
import { Loading } from '@appkit4/react-components';

interface IAdminEditCareerCoachCapacityProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    careerCoachCapacityId: number;
}

const AdjustCoachCapacityModal = (
    props: IAdminEditCareerCoachCapacityProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const editCareerCoachCapacity =
        usePutV1CareerCoachCapacityCareerCoachCapacityId();

    const queryClient = useQueryClient();

    const { data: careerCoachCapacity, isLoading: loadingCareerCoachCapacity } =
        useGetV1CareerCoachCapacityCareerCoachCapacityId(
            props.careerCoachCapacityId,
            {
                query: {
                    refetchOnWindowFocus: false,
                },
            }
        );

    const putCareerCoachCapacity = (values: FormValues) => {
        if (props.careerCoachCapacityId === undefined) {
            return; // do not expect this to ever occur
        }

        editCareerCoachCapacity.mutate(
            {
                careerCoachCapacityId: props.careerCoachCapacityId,
                data: {
                    maxCoacheesDirector: values.maxCoacheesD,
                    maxCoacheesSeniorManager: values.maxCoacheesSM,
                    maxCoacheesManager: values.maxCoacheesM,
                    maxCoacheesSeniorAssociate: values.maxCoacheesSA,
                    maxCoacheesAssociate: values.maxCoacheesA,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_careerCoachCapacityUpdatedSuccessfully'),
                        'success'
                    );
                    props.setIsVisible(false);
                    queryClient.invalidateQueries(
                        getGetV1CareerCoachCapacityCareerCoachCapacityIdQueryKey(
                            props.careerCoachCapacityId
                        )
                    );
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                },
                onError: () => {
                    showToast(l('_error'), l('_saveValuesFailed'), 'error');
                    props.setIsVisible(false);
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_adjustCareerCoachCapacity')}
                        aria-label={l('_adjustCareerCoachCapacity')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setIsVisible(false)}
                    >
                        {loadingCareerCoachCapacity ? (
                            <Loading
                                loadingType="circular"
                                indeterminate
                                compact={false}
                            />
                        ) : (
                            <AdjustCoachCapacityForm
                                submitFunc={putCareerCoachCapacity}
                                cancelFunc={() => props.setIsVisible(false)}
                                maxCoacheesD={
                                    careerCoachCapacity?.maxCoacheesDirector ??
                                    0
                                }
                                maxCoacheesSM={
                                    careerCoachCapacity?.maxCoacheesSeniorManager ??
                                    0
                                }
                                maxCoacheesM={
                                    careerCoachCapacity?.maxCoacheesManager ?? 0
                                }
                                maxCoacheesSA={
                                    careerCoachCapacity?.maxCoacheesSeniorAssociate ??
                                    0
                                }
                                maxCoacheesA={
                                    careerCoachCapacity?.maxCoacheesAssociate ??
                                    0
                                }
                            />
                        )}
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default AdjustCoachCapacityModal;
