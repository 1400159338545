import React, { useState } from 'react';
import { Button, Loading, Panel } from '@appkit4/react-components';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
    useGetV1Allocation,
    useGetV1AllocationPreoptimisedAllocationId,
    useGetV1BusinessUnitContent,
} from '../api/generated/endpoint';
import AllocationHistoryTable, {
    AllocationHistoryData,
} from '../components/tables/AllocationHistoryTable';
import AllocationStatuses from '../models/allocationStatuses';
import {
    AllocationData,
    BusinessUnitContentResponse,
} from '../api/generated/models';
import useTitle from '../helpers/useTitle';
import useToast from '../helpers/useToast';
import useLocale from '../locale/useLocale';
import { AppRoutes } from '../Constants';

import './Home.scss';

export const Home = () => {
    const { l } = useLocale();
    useTitle(l('_home'));
    const navigate = useNavigate();
    const showToast = useToast();

    const [allocations, setAllocations] = useState<AllocationHistoryData[]>([]);
    const [selectedAllocationIds, setSelectedAllcoationIds] = useState<
        number[]
    >([]);

    const [objectivesContent, setObjectivesContent] = useState<
        string | TrustedHTML
    >('');

    const [businessRulesContent, setBusinessRulesContent] = useState<
        string | TrustedHTML
    >('');

    const { isFetching: fetchingBusinessUnitContent } =
        useGetV1BusinessUnitContent<BusinessUnitContentResponse>({
            query: {
                refetchOnWindowFocus: false,
                retry: false,
                enabled: true,
                onSuccess: (response) => {
                    if (response.objectivesText == '') {
                        showToast(
                            l('_error'),
                            l('_businessUnitObjectivesNotConfigured'),
                            'error'
                        );
                    }

                    if (response.objectivesText == '') {
                        showToast(
                            l('_error'),
                            l('_businessUnitContentNotConfigured'),
                            'error'
                        );
                    }

                    setObjectivesContent(response.objectivesText as string);

                    setBusinessRulesContent(
                        response.businessRuleText as string
                    );
                },
            },
        });

    const { isFetching: fetchingAllocationHistory } = useGetV1Allocation(
        { skip: 0, take: 5 },
        {
            query: {
                refetchOnWindowFocus: false,
                onSuccess: (data) => {
                    if (data.allocations == null) {
                        return;
                    }
                    setAllocations(
                        data.allocations.map((allocation) => {
                            return {
                                id: allocation.id ?? 0,
                                fileName: allocation.name ?? '',
                                editor: allocation.updatedByUserName ?? '',
                                lastEdited: allocation.dateUpdated
                                    ? new Date(allocation.dateUpdated)
                                    : null,
                                dateCreated: new Date(
                                    allocation.dateCreated ?? ''
                                ),
                                status: AllocationStatuses[
                                    allocation.status ?? ''
                                ],
                                httpStatus:
                                    allocation.allocationHttpStatus ?? null,
                                notes: allocation.notes ?? '',
                            };
                        })
                    );
                },
                onError: (error: AxiosError<string>) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            },
        }
    );

    const getPreoptimisedAllocationData =
        useGetV1AllocationPreoptimisedAllocationId(selectedAllocationIds[0], {
            query: {
                enabled: false,
                refetchOnWindowFocus: false,
                onSuccess: (data: AllocationData) => {
                    navigate(AppRoutes.PreAllocation, {
                        state: {
                            allocationData: data,
                            allocationId: selectedAllocationIds[0],
                        },
                    });
                },
                onError: (error: AxiosError<string>) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            },
        });

    const handleClick = async () => {
        const selectedAllocation = allocations.find((allocation) =>
            selectedAllocationIds.includes(allocation.id)
        );

        if (selectedAllocation?.status === 'Completed') {
            navigate(AppRoutes.ReviewAllocation, {
                state: {
                    allocationId: selectedAllocationIds[0],
                },
            });
        } else if (selectedAllocation?.status === 'Allocating') {
            navigate(AppRoutes.AllocatingLoading, {
                state: {
                    allocationId: selectedAllocation.id,
                },
            });
        } else if (selectedAllocation?.status === 'Pending') {
            getPreoptimisedAllocationData.refetch();
        }
    };

    const hasFailedAllocation = () => {
        const selectedAllocation = allocations.find((allocation) =>
            selectedAllocationIds.includes(allocation.id)
        );

        if (selectedAllocation?.status === 'Failed') {
            return true;
        }
    };

    return (
        <>
            <div className="homepage-container">
                <div className="panel-container-home">
                    <Panel className="panels">
                        <div className="panel-content">
                            <span className="Appkit4-icon icon-target-outline" />
                            <div className="panel-text">
                                <h5>{l('_objectives')}</h5>
                                <>
                                    {!fetchingBusinessUnitContent ? (
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: objectivesContent,
                                            }}
                                        ></div>
                                    ) : (
                                        <Loading
                                            loadingType="circular"
                                            indeterminate={true}
                                            compact={false}
                                        ></Loading>
                                    )}
                                </>
                            </div>
                        </div>
                    </Panel>
                    <Panel className="panels">
                        <div className="panel-content">
                            <span className="Appkit4-icon icon-configuration-outline" />
                            <div className="panel-text">
                                <h5>{l('_businessRules')}</h5>
                                <>
                                    {!fetchingBusinessUnitContent ? (
                                        <div
                                            className="rule-text"
                                            dangerouslySetInnerHTML={{
                                                __html: businessRulesContent,
                                            }}
                                        ></div>
                                    ) : (
                                        <Loading
                                            loadingType="circular"
                                            indeterminate={true}
                                            compact={false}
                                        ></Loading>
                                    )}
                                </>
                            </div>
                        </div>
                    </Panel>
                </div>
                <div className="brand-text-subtitle">
                    <h3>{l('_allocationHistory')}</h3>
                </div>
                <div>
                    <AllocationHistoryTable
                        data={allocations}
                        loadingAllocationHistoryData={fetchingAllocationHistory}
                        selectedAllocationIds={selectedAllocationIds}
                        setSelectedAllcoationIds={setSelectedAllcoationIds}
                        tableType="short"
                    />
                    {allocations.length > 0 && (
                        <div className="button-container">
                            <Button
                                kind="secondary"
                                icon="icon-folder-closed-outline"
                                onClick={handleClick}
                                disabled={
                                    !(selectedAllocationIds.length === 1) ||
                                    hasFailedAllocation()
                                }
                            >
                                {l('_openAllocation')}
                            </Button>
                            <Button
                                kind="primary"
                                icon="icon-configuration-outline"
                                onClick={() => {
                                    navigate(AppRoutes.NewAllocation);
                                }}
                            >
                                {l('_createAllocation')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default Home;
