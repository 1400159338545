import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { UserResponse } from '../api/generated/models';
import Roles from '../auth/roles';
import useLocale from '../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../api/generated/endpoint';

interface UserManagementActionsCellProps {
    user: UserResponse;
    displayEditUserModal: () => void;
    displayDeleteUserModal: () => void;
}

const UserManagementActionsCell = (props: UserManagementActionsCellProps) => {
    const { l } = useLocale();

    /* Load current user info */
    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <div>
            {props.user.id != userInfo?.id && // cannot edit or delete yourself
                (props.user.roleName === 'Support User' || // admin can edit or delete users
                    props.user.roleName === 'Admin' || // admin can edit or delete admins
                    userInfo?.roleId === Roles.SuperAdminId) && ( // super admin can edit or delete all roles
                    <>
                        <div className="row actions-icons">
                            <Button
                                className="col m-0 p-0 h-50"
                                kind="text"
                                icon="icon-edit-outline"
                                type="button"
                                role="button"
                                compact
                                onClick={() => {
                                    props.displayEditUserModal();
                                }}
                                title={`${l('_editUser')} ${
                                    props.user.forenames
                                } ${props.user.surname}`}
                            />

                            <Button
                                className="col m-0 p-0 h-50"
                                kind="text"
                                icon="icon-delete-outline"
                                type="button"
                                role="button"
                                compact
                                onClick={() => props.displayDeleteUserModal()}
                                title={`${l('_deleteUser')} ${
                                    props.user.forenames
                                } ${props.user.surname}`}
                            />
                        </div>
                    </>
                )}
        </div>
    );
};

export default UserManagementActionsCell;
