import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Tooltip } from '@appkit4/react-components';
import AllocationHistoryTable, {
    AllocationHistoryData,
} from '../../components/tables/AllocationHistoryTable';
import {
    useGetV1Allocation,
    getGetV1AllocationQueryKey,
    useGetV1AllocationPreoptimisedAllocationId,
    useDeleteV1AllocationDeleteAllocations,
    usePutV1AllocationAllocationUpdateNameAllocationId,
} from '../../api/generated/endpoint';
import { AllocationData } from '../../api/generated/models';
import DeleteHistoricalAllocationModal from '../../components/DeleteHistoricalAllocationModal';
import RenameHistoricalAllocationModal from '../../components/RenameHistoricalAllocationModal';
import AllocationStatuses from '../../models/allocationStatuses';
import { AppRoutes } from '../../Constants';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';

import './AllocationHistory.scss';

export const AllocationHistory = () => {
    const { l } = useLocale();
    const showToast = useToast();
    const navigate = useNavigate();
    const deleteAllocationData = useDeleteV1AllocationDeleteAllocations();
    const renameAllocationData =
        usePutV1AllocationAllocationUpdateNameAllocationId();
    const queryClient = useQueryClient();

    const [allocations, setAllocations] = useState<AllocationHistoryData[]>([]);
    const [selectedAllocationIds, setSelectedAllocationIds] = useState<
        number[]
    >([]);

    const [isRenaming, setIsRenaming] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    const [
        displayDeleteHistoricalAllocationModal,
        setDisplayDeleteHistoricalAllocationModal,
    ] = useState(false);

    const [
        displayRenameHistoricalAllocationModal,
        setDisplayRenameHistoricalAllocationModal,
    ] = useState(false);

    const { isFetching: fetchingAllocationHistory } = useGetV1Allocation(
        {},
        {
            query: {
                refetchOnWindowFocus: false,
                onSuccess: (data) => {
                    if (data.allocations == null) {
                        return;
                    }
                    setAllocations(
                        data.allocations.map((allocation) => {
                            return {
                                id: allocation.id ?? 0,
                                fileName: allocation.name ?? '',
                                editor: allocation.updatedByUserName ?? '',
                                lastEdited: allocation.dateUpdated
                                    ? new Date(allocation.dateUpdated)
                                    : null,
                                dateCreated: new Date(
                                    allocation.dateCreated ?? ''
                                ),
                                status: AllocationStatuses[
                                    allocation.status ?? ''
                                ],
                                httpStatus:
                                    allocation.allocationHttpStatus ?? null,
                                notes: allocation.notes ?? '',
                            };
                        })
                    );
                },
                onError: (error: AxiosError<string>) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            },
        }
    );

    const getPreoptimisedAllocationData =
        useGetV1AllocationPreoptimisedAllocationId(selectedAllocationIds[0], {
            query: {
                enabled: false,
                refetchOnWindowFocus: false,
                onSuccess: (data: AllocationData) => {
                    navigate(AppRoutes.PreAllocation, {
                        state: {
                            allocationData: data,
                            allocationId: selectedAllocationIds[0],
                        },
                    });
                },
                onError: (error: AxiosError<string>) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            },
        });

    const deleteAllocation = async () => {
        setIsDeleting(true);

        await deleteAllocationData.mutateAsync(
            {
                data: selectedAllocationIds,
            },
            {
                onSuccess: () => {
                    showToast(l('_success'), '', 'success');
                    queryClient.invalidateQueries(getGetV1AllocationQueryKey());
                    setDisplayDeleteHistoricalAllocationModal(false);

                    setIsDeleting(false);
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );

                    setIsDeleting(false);
                },
            }
        );

        setSelectedAllocationIds([]);
    };

    const renameAllocation = async (value: string) => {
        setIsRenaming(true);

        await renameAllocationData.mutateAsync(
            {
                allocationId: selectedAllocationIds[0],
                data: value,
            },
            {
                onSuccess: () => {
                    showToast(l('_success'), '', 'success');
                    queryClient.invalidateQueries(getGetV1AllocationQueryKey());
                    setDisplayRenameHistoricalAllocationModal(false);

                    setIsRenaming(false);
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );

                    setIsRenaming(false);
                },
            }
        );
        setSelectedAllocationIds([]);
    };

    const handleClick = async () => {
        const selectedAllocation = allocations.find((allocation) =>
            selectedAllocationIds.includes(allocation.id)
        );

        if (selectedAllocation?.status === 'Completed') {
            navigate(AppRoutes.ReviewAllocation, {
                state: {
                    allocationId: selectedAllocationIds[0],
                },
            });
        } else if (selectedAllocation?.status === 'Allocating') {
            navigate(AppRoutes.AllocatingLoading, {
                state: {
                    allocationId: selectedAllocation.id,
                },
            });
        } else if (selectedAllocation?.status === 'Pending') {
            getPreoptimisedAllocationData.refetch();
        }
    };

    const hasFailedAllocation = () => {
        const selectedAllocation = allocations.find((allocation) =>
            selectedAllocationIds.includes(allocation.id)
        );

        if (selectedAllocation?.status === 'Failed') {
            return true;
        }
    };

    return (
        <>
            <div className="homepage-container">
                <div className="brand-text-title">
                    <h2>{l('_allocationHistory')}</h2>
                </div>
                <AllocationHistoryTable
                    data={allocations}
                    loadingAllocationHistoryData={fetchingAllocationHistory}
                    selectedAllocationIds={selectedAllocationIds}
                    setSelectedAllcoationIds={setSelectedAllocationIds}
                    tableType="full"
                />
                <div className="button-container">
                    <Button
                        kind="secondary"
                        icon="icon-delete-outline"
                        onClick={() => {
                            setDisplayDeleteHistoricalAllocationModal(true);
                        }}
                        loading={isDeleting}
                        disabled={selectedAllocationIds.length === 0}
                    >
                        {l('_delete')}
                    </Button>
                    <Button
                        kind="secondary"
                        icon="icon-pencil-outline"
                        onClick={() => {
                            setDisplayRenameHistoricalAllocationModal(true);
                        }}
                        loading={isRenaming}
                        disabled={
                            !(selectedAllocationIds.length === 1) ||
                            selectedAllocationIds.length > 1 ||
                            selectedAllocationIds.some(
                                (id) =>
                                    allocations.find(
                                        (allocation) => allocation.id === id
                                    )?.status !== 'Completed'
                            )
                        }
                    >
                        {selectedAllocationIds.length > 1 ||
                        selectedAllocationIds.some(
                            (id) =>
                                allocations.find(
                                    (allocation) => allocation.id === id
                                )?.status !== 'Completed'
                        ) ? (
                            <Tooltip
                                style={{ maxWidth: '40%', zIndex: '9999' }}
                                trigger="hover"
                                position="top-left"
                                distance={4}
                                id="tooltipDesc"
                                appendAfterTarget={true}
                                content={
                                    selectedAllocationIds.length !== 1
                                        ? l('_selectOneAllocation')
                                        : l('_completedAllocationsOnly')
                                }
                            >
                                <span>{l('_rename')}</span>
                            </Tooltip>
                        ) : (
                            l('_rename')
                        )}
                    </Button>
                    <Button
                        kind="primary"
                        icon="icon-right-chevron-outline"
                        onClick={handleClick}
                        disabled={
                            !(selectedAllocationIds.length === 1) ||
                            hasFailedAllocation()
                        }
                    >
                        {l('_open')}
                    </Button>
                </div>
                {displayDeleteHistoricalAllocationModal && (
                    <DeleteHistoricalAllocationModal
                        isVisible={true}
                        setIsVisible={setDisplayDeleteHistoricalAllocationModal}
                        onDelete={deleteAllocation}
                        selectedAllocationNames={selectedAllocationIds.map(
                            (id) => {
                                const selectedAllocation = allocations.find(
                                    (allocation) => allocation.id === id
                                );
                                return selectedAllocation
                                    ? selectedAllocation.fileName
                                    : '';
                            }
                        )}
                    />
                )}
                {displayRenameHistoricalAllocationModal && (
                    <RenameHistoricalAllocationModal
                        isVisible={true}
                        setIsVisible={setDisplayRenameHistoricalAllocationModal}
                        onSave={(value) => {
                            renameAllocation(value);
                        }}
                        selectedAllocationName={
                            (
                                allocations.find(
                                    (allocation) =>
                                        allocation.id ===
                                        selectedAllocationIds[0]
                                ) || {}
                            ).fileName || ''
                        }
                        selectedAllocationStatus={
                            (
                                allocations.find(
                                    (allocation) =>
                                        allocation.id ===
                                        selectedAllocationIds[0]
                                ) || {}
                            ).status || ''
                        }
                    />
                )}
            </div>
        </>
    );
};

export default AllocationHistory;
