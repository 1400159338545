import { Button } from '@appkit4/react-components';
import {
    BusinessUnitResponse,
    UploadValidationRule,
} from '../../api/generated/models';
import useLocale from '../../locale/useLocale';
import { useGetV1IdentityUserInfo } from '../../api/generated/endpoint';

export interface IBusinessUnitManagementActionsCellProps {
    businessRule: UploadValidationRule;
    displayEditBusinessRuleModal: () => void;
    displayDeleteBusinessRuleModal: () => void;
}

const BusinessUnitManagementActionsCell = (
    props: IBusinessUnitManagementActionsCellProps
) => {
    const { l } = useLocale();

    /* Load current user info */
    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <div>
            <div className="row actions-icons">
                <Button
                    className="col m-0 p-0 h-50"
                    kind="text"
                    icon="icon-edit-outline"
                    type="button"
                    role="button"
                    compact
                    onClick={() => {
                        props.displayEditBusinessRuleModal();
                    }}
                    title={`${l('_editBusinessUnit')} ${
                        props.businessRule.name
                    }`}
                />

                <Button
                    className="col m-0 p-0 h-50"
                    kind="text"
                    icon="icon-delete-outline"
                    type="button"
                    role="button"
                    compact
                    disabled={
                        userInfo?.businessUnitId === props.businessRule.id
                    }
                    onClick={() => {
                        props.displayDeleteBusinessRuleModal();
                    }}
                    title={`${l('_deleteBusinessUnit')} ${
                        props.businessRule.name
                    }`}
                />
            </div>
        </div>
    );
};

export default BusinessUnitManagementActionsCell;
