import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
    Upload,
    Table,
    Column,
    Radio,
    Button,
    Panel,
    Loading,
} from '@appkit4/react-components';
import { AxiosError } from 'axios';
import {
    AllocationDetailResponse,
    AllocationDetails,
    FileUploadResponse,
} from '../../api/generated/models';
import { useCustomFormAxios } from '../../api/mutator/custom-form-instance';
import { AppRoutes, SampleUploadUrl } from '../../Constants';
import useLocale from '../../locale/useLocale';
import useToast from '../../helpers/useToast';

import './NewAllocation.scss';
import './AllocationLoading.scss';
import {
    useGetV1AllocationGetAllocations,
    usePostV1AllocationUploadAllocationId,
} from '../../api/generated/endpoint';

const NewAllocation = () => {
    const { l } = useLocale();
    const navigate = useNavigate();
    const showToast = useToast();

    const [selectedIndexes, setSelectedIndexes] = useState([] as number[]);
    const [uploadFile, setUploadFile] = useState<File | null>(null);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(true);

    const [showLoading, setShowLoading] = useState(false);

    const recreateAllocationByAllocationId =
        usePostV1AllocationUploadAllocationId();

    const uploadApi = useCustomFormAxios<FileUploadResponse>();

    const { isLoading: allocationsLoading, data: allocationResponse } =
        useGetV1AllocationGetAllocations<AllocationDetailResponse>(
            { take: 5 },
            {
                query: {
                    enabled: true,
                    retry: false,
                    refetchOnWindowFocus: false,
                },
            }
        );

    const renderFileNameCell = (row: AllocationDetails, field: string) => {
        const onCheckboxChange = (rowId: number) => {
            setSelectedIndexes([rowId]);
            setNextButtonDisabled(false);
        };

        if (!(field in row)) return '';

        return (
            <Radio
                checked={selectedIndexes.includes(row.id as number)}
                onClick={() => onCheckboxChange(row.id as number)}
                disabled={uploadFile !== null}
            >
                <span>{row.name}</span>
            </Radio>
        );
    };

    const renderDateCell = (date: string) => {
        return new Date(date).toLocaleString();
    };

    const uploadDivElement = document.querySelector<HTMLDivElement>(
        '.a-upload-drag-zone'
    );

    const handleUploadRemoval = () => {
        setUploadFile(null);
        setNextButtonDisabled(true);

        // Perform a null check before accessing properties or methods
        if (uploadDivElement) {
            uploadDivElement.style.display = 'block';
        }
    };

    const handleUploadSuccess = (file: { originFile: File }) => {
        setUploadFile(file.originFile);
        setNextButtonDisabled(false);

        setSelectedIndexes([] as number[]);

        // Perform a null check before accessing properties or methods
        if (uploadDivElement) {
            uploadDivElement.style.display = 'none';
        }
    };

    const handleNextButton = async () => {
        // Assert there's actually a file set to upload.
        if (uploadFile) {
            await handleFileUpload();
        } else {
            await recreateSelectedAllocation();
        }
    };

    const handleFileUpload = async () => {
        const formData = new FormData();
        formData.append('file', uploadFile as File);

        setShowLoading(true);

        await uploadApi({
            url: `/v1/Allocation/Upload`,
            method: 'post',
            data: formData,
        })
            .then((response: FileUploadResponse) => {
                setShowLoading(false);

                navigate(AppRoutes.PreAllocation, {
                    state: {
                        allocationData: response.allocationData,
                        allocationId: response.allocationId,
                    },
                });
            })
            .catch((error: AxiosError<string>) => {
                setShowLoading(false);

                showToast(
                    l('_fileUploadErrorMessage'),
                    error.response?.data || error.message,
                    'error'
                );
            });
    };

    const recreateSelectedAllocation = async () => {
        setShowLoading(true);

        await recreateAllocationByAllocationId.mutateAsync(
            {
                allocationId: selectedIndexes[0],
            },
            {
                onSuccess: (response: FileUploadResponse) => {
                    setShowLoading(false);

                    navigate(AppRoutes.PreAllocation, {
                        state: {
                            allocationData: response.allocationData,
                            allocationId: response.allocationId,
                        },
                    });
                },
                onError: () => {
                    showToast(l('_error'), l('_saveValuesFailed'), 'error');

                    setShowLoading(false);
                },
            }
        );
    };

    return (
        <>
            {showLoading && (
                <>
                    <div className="loading-content">
                        <h6>{l('_uploadLoadingTitle')}</h6>
                        {l('_uploadLoadingDescription')}
                        <Loading
                            style={{ margin: '1rem auto auto auto' }}
                            loadingType="linear"
                            indeterminate={true}
                            compact={false}
                        ></Loading>
                    </div>
                </>
            )}
            {!showLoading && (
                <>
                    <div className="upload-form-container">
                        <div className="brand-text-title-new-allocation">
                            <h2>{l('_newAllocationPageTitle')}</h2>
                        </div>
                        {uploadFile == null && (
                            <>
                                <div className="upload-description">
                                    <div>{l('_uploadInstructionMessage')}</div>
                                    <div>
                                        {l('_uploadFileFormatsStart')}(
                                        <a
                                            style={{
                                                color: '#d04a02',
                                                textDecoration: 'underline',
                                            }}
                                            href={SampleUploadUrl}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {l('_uploadFileFormatsLink')}
                                        </a>
                                        ){l('_uploadFileFormatsEnd')}
                                    </div>
                                </div>
                            </>
                        )}
                        <Upload
                            onSuccess={handleUploadSuccess}
                            onRemove={handleUploadRemoval}
                            style={{ marginBottom: '-1.5rem' }}
                            multiple={false}
                            drag={true}
                            autoUpload={true}
                            acceptFileType=".XLS,.XLSX"
                            config={{
                                trigger: false,
                                type: 'inline',
                                size: true,
                            }}
                        ></Upload>
                    </div>

                    <div style={{ position: 'relative' }}>
                        {allocationResponse !== undefined &&
                        allocationResponse.allocations.length > 0 ? (
                            <Panel className="newallocation-panel-wrapper">
                                <div
                                    className={`${
                                        uploadFile ? 'grayed-out' : ''
                                    }`}
                                >
                                    <Table
                                        className="ap-table-checkable"
                                        originalData={
                                            allocationResponse?.allocations
                                        }
                                        hasTitle
                                        selectedIndexs={selectedIndexes}
                                    >
                                        <Column
                                            field="name"
                                            sortKey="name"
                                            renderCell={renderFileNameCell}
                                        >
                                            {l('_fileName')}
                                        </Column>
                                        <Column
                                            field="updatedBy"
                                            sortKey="updatedBy"
                                        >
                                            {l('_editor')}
                                        </Column>
                                        <Column
                                            field="dateCreated"
                                            sortKey="dateCreated"
                                            renderCell={(
                                                row: AllocationDetails
                                            ) => {
                                                return renderDateCell(
                                                    row.dateCreated as string
                                                );
                                            }}
                                        >
                                            {l('_dateCreated')}
                                        </Column>
                                    </Table>
                                </div>
                            </Panel>
                        ) : (
                            <div className="no-recent-uploads">
                                <Panel className="no-recent-uploads-panel">
                                    {allocationsLoading ? (
                                        <Loading
                                            loadingType="circular"
                                            indeterminate={true}
                                            compact={false}
                                        ></Loading>
                                    ) : (
                                        <h4>{l('_noRecentUploads')}</h4>
                                    )}
                                </Panel>
                            </div>
                        )}

                        <Button
                            className="next-button-new-allocation"
                            kind="primary"
                            icon="icon-right-chevron-outline"
                            disabled={nextButtonDisabled}
                            onClick={handleNextButton}
                        >
                            {l('_next')}
                        </Button>
                    </div>
                </>
            )}
        </>
    );
};

export default NewAllocation;
