import React from 'react';
import { Button } from '@appkit4/react-components/button';
import useLocale from '../locale/useLocale';
import { ContactPersonResponse } from '../api/generated/models';

interface ContactPersonsManagementActionsCellProps {
    user: ContactPersonResponse;
    displayEditContactModal: () => void;
    displayDeleteContactModal: () => void;
}

const ContactPersonsManagementActionsCell = (
    props: ContactPersonsManagementActionsCellProps
) => {
    const { l } = useLocale();

    return (
        <div>
            <>
                <div className="row actions-icons">
                    <Button
                        className="col m-0 p-0 ps-1 pt-1 h-50"
                        kind="text"
                        icon="icon-edit-outline"
                        type="button"
                        role="button"
                        compact
                        onClick={() => {
                            props.displayEditContactModal();
                        }}
                        title={`${l('_editContactPerson')} ${
                            props.user.forenames
                        } ${props.user.surname}`}
                    />

                    <Button
                        className="col m-0 p-0 ps-1 pt-1 h-50"
                        kind="text"
                        icon="icon-delete-outline"
                        type="button"
                        role="button"
                        compact
                        onClick={() => props.displayDeleteContactModal()}
                        title={`${l('_deleteContactPerson')} ${
                            props.user.forenames
                        } ${props.user.surname}`}
                    />
                </div>
            </>
        </div>
    );
};

export default ContactPersonsManagementActionsCell;
