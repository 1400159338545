import React, { ReactNode, useEffect } from 'react';

interface IModalContainerProps {
    isVisible: boolean;
    children: ReactNode;
}

const ModalContainer = (props: IModalContainerProps) => {
    const disableScroll = (disable = true) => {
        disable
            ? document.body.classList.add('no-scroll')
            : document.body.classList.remove('no-scroll');
    };

    useEffect(() => {
        disableScroll(props.isVisible);

        return () => {
            disableScroll(false);
        };
    }, [props.isVisible]);

    return <>{props.isVisible && props.children}</>;
};

export default ModalContainer;
