/* istanbul ignore file */
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from 'react-oidc-context';
import './styles/styles.scss';
import './styles/_variables.scss';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import App from './App.tsx';
import reportWebVitals from './reportWebVitals';
import oidcConfig from './auth/config';
import './assets/icons/icons.scss';
import './assets/pictograms/pictograms.scss';
import './styles/font.css';
import { AppInsightsContextProvider } from './api/monitoring/AppInsightsContextProvider';
import enableDatadog from './datadog';

const queryClient = new QueryClient();
enableDatadog();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            {/* eslint-disable-next-line react/jsx-props-no-spreading */}
            <AuthProvider {...oidcConfig}>
                <AppInsightsContextProvider>
                    <App />
                </AppInsightsContextProvider>
            </AuthProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
