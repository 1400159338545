import { datadogRum } from '@datadog/browser-rum';

const enableDatadog = () => {
    if (
        process.env.REACT_APP_DATADOG_ENABLED &&
        process.env.REACT_APP_DATADOG_ENABLED.trim() === 'true' &&
        process.env.REACT_APP_DATADOG_APPLICATION_ID &&
        process.env.REACT_APP_DATADOG_CLIENT_TOKEN &&
        process.env.REACT_APP_DATADOG_ENV &&
        process.env.REACT_APP_DATADOG_APPLICATION_ID.trim() !== '' &&
        process.env.REACT_APP_DATADOG_CLIENT_TOKEN.trim() !== '' &&
        process.env.REACT_APP_DATADOG_ENV.trim() !== ''
    ) {
        datadogRum.init({
            applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
            clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
            site: 'datadoghq.eu',
            service: 'skeletonapp_uk',
            env: process.env.REACT_APP_DATADOG_ENV,
            sessionSampleRate: 100,
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            silentMultipleInit: true,
        });
    }
};

export default enableDatadog;
