import { useState, useEffect } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components';
import { useQueryClient } from '@tanstack/react-query';
import {
    PutV1ContactPersonSuperAdminMutationError,
    getGetV1ContactPersonQueryKey,
    getGetV1BusinessUnitSuperAdminQueryKey,
    useGetV1ContactPersonSuperAdmin,
    useGetV1BusinessUnitSuperAdmin,
    usePutV1ContactPersonSuperAdmin,
} from '../../api/generated/endpoint';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import SuperAdminConfirmDeleteContactPersonModal from './SuperAdminConfirmDeleteContactPersonModal';
import SuperAdminCreateContactPersonModal from './SuperAdminCreateContactPersonModal';
import SuperAdminEditContactPersonModal from './SuperAdminEditContactPersonModal';
import SuperAdminBusinessUnitContactPersonTable from './SuperAdminBusinessUnitContactPersonTable';
import { ContactPersonResponse } from '../../api/generated/models';

export interface ISuperAdminUpdateBusinessUnitContactModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
    businessUnitId: number | undefined;
}

const SuperAdminUpdateBusinessUnitContactModal = (
    props: ISuperAdminUpdateBusinessUnitContactModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const queryClient = useQueryClient();
    const [selectedContacts, setSelectedContacts] = useState<number[]>([]);

    const [
        displayCreateContactPersonModal,
        setDisplayCreateContactPersonModal,
    ] = useState(false);
    const [
        showDeleteContactPersonConfirmationForId,
        setShowDeleteContactPersonConfirmationForId,
    ] = useState<number | null>(null);
    const [
        displayEditContactPersonModalForId,
        setDisplayEditContactPersonModalForId,
    ] = useState<number | null>(null);
    const editBusinessUnitContactList = usePutV1ContactPersonSuperAdmin();

    const updateBusinessUnitContactPersons = (contactsId: number[]) => {
        editBusinessUnitContactList.mutate(
            {
                data: {
                    contactPersonsIds: contactsId,
                    businessUnitId: props.businessUnitId ?? -1,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_businessUnitContactsUpdated'),
                        'success'
                    );
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonQueryKey()
                    );
                },
                onError: (error: PutV1ContactPersonSuperAdminMutationError) => {
                    showGenericErrorToast(error);
                },
            }
        );
    };

    const { data: contactPersons } = useGetV1ContactPersonSuperAdmin(
        undefined,
        {
            query: {
                onError: showGenericErrorToast,
            },
        }
    );

    const { data: businessUnits, isLoading: loadingBusinessUnits } =
        useGetV1BusinessUnitSuperAdmin(
            {},
            {
                query: {
                    onError: showGenericErrorToast,
                },
            }
        );

    useEffect(() => {
        if (!loadingBusinessUnits && businessUnits) {
            const businessUnit = businessUnits.businessUnits.find(
                (businessUnit) => businessUnit.id === props.businessUnitId
            );
            if (businessUnit && businessUnit.contactPersons) {
                setSelectedContacts(
                    businessUnit.contactPersons.map((contact) => contact.id)
                );
            }
        }
    }, [loadingBusinessUnits, businessUnits, props.businessUnitId]);

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_updateBusinessUnitContacts')}
                        aria-label={l('_editBusinessUnit')}
                        modalStyle={{
                            width: '75vw',
                            maxWidth: '750px',
                            minWidth: '500px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        <div className="row mt-3">
                            <div className="col">
                                <h2 className="ap-typography-heading-s">
                                    {l('_contactPersons')}
                                </h2>
                            </div>
                            <div className="col">
                                <Button
                                    kind="secondary"
                                    icon="icon-plus-outline"
                                    type="button"
                                    role="button"
                                    onClick={() =>
                                        setDisplayCreateContactPersonModal(true)
                                    }
                                    className="ms-auto align-top h-75"
                                    style={{ paddingTop: '12px' }}
                                >
                                    {l('_newContactPerson')}
                                </Button>
                            </div>
                        </div>
                        <SuperAdminBusinessUnitContactPersonTable
                            contactPersons={contactPersons ?? []}
                            setDisplayEditContactPersonModalForId={
                                setDisplayEditContactPersonModalForId
                            }
                            setShowDeleteContactPersonConfirmationForId={
                                setShowDeleteContactPersonConfirmationForId
                            }
                            selectedContactIds={selectedContacts}
                            updateSelectedContactIds={(
                                selectedContactIds: number[]
                            ) => {
                                setSelectedContacts(selectedContactIds);
                                updateBusinessUnitContactPersons(
                                    selectedContactIds
                                );
                            }}
                        />
                    </Modal>
                </ModalContainer>
            )}

            {displayCreateContactPersonModal && (
                <SuperAdminCreateContactPersonModal
                    isVisible={displayCreateContactPersonModal}
                    setNotVisible={() =>
                        setDisplayCreateContactPersonModal(false)
                    }
                    onCreationSuccess={(newContact: ContactPersonResponse) => {
                        const updatedSelectedContacts = [
                            ...selectedContacts,
                            newContact.id,
                        ];
                        updateBusinessUnitContactPersons(
                            updatedSelectedContacts
                        );
                        setSelectedContacts(updatedSelectedContacts);
                    }}
                />
            )}

            {displayEditContactPersonModalForId && contactPersons && (
                <SuperAdminEditContactPersonModal
                    isVisible={displayEditContactPersonModalForId != null}
                    setNotVisible={() => {
                        setDisplayEditContactPersonModalForId(null);
                    }}
                    contactPerson={contactPersons?.find(
                        (c) => c.id == displayEditContactPersonModalForId
                    )}
                />
            )}

            {showDeleteContactPersonConfirmationForId && contactPersons && (
                <SuperAdminConfirmDeleteContactPersonModal
                    visible={showDeleteContactPersonConfirmationForId != null}
                    contactPerson={contactPersons?.find(
                        (c) => c.id == showDeleteContactPersonConfirmationForId
                    )}
                    setNotVisible={() => {
                        setShowDeleteContactPersonConfirmationForId(null);
                    }}
                />
            )}
        </>
    );
};

export default SuperAdminUpdateBusinessUnitContactModal;
