import { FC } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import './Layout.scss';
import Navigation from './Navigation';
import RestrictedSidebar from './RestrictedSidebar';

type LayoutProps = { children?: JSX.Element };

const Layout: FC<LayoutProps> = () => (
    <>
        <Navigation />
        <div className="content-container">
            <RestrictedSidebar />
            <main
                role="main"
                className="flex-grow-1 ap-container page-container mt-4"
            >
                <Outlet />
            </main>
        </div>
        <ScrollRestoration />
    </>
);

export default Layout;
