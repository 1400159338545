import React, { useState, useEffect, FC } from 'react';
import {
    Table,
    Column,
    Tooltip,
    Badge,
    Panel,
    Loading,
} from '@appkit4/react-components';
import { IndexedCoacheeData } from '../../../models/indexedCoacheeData';
import useLocale from '../../../locale/useLocale';
import GlobalSearch from '../../GlobalSearch';
import GlobalPagination from '../../GlobalPagination';
import { usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationId } from '../../../api/generated/endpoint';
import useToast from '../../../helpers/useToast';

import { OptimisedCoachee } from '../../../api/generated/models';
import { UnallocatedCoachNames } from '../../../models/unallocatedCoachNames';

import './OptimisedCoacheesTable.scss';
import EditNotesModal from '../../EditNotesModal';

type ValueType = string | number;
type SelectValue = ValueType | ValueType[];

interface OptimisedCoacheeTableProps {
    coachees: OptimisedCoachee[];
    filteredCoachees: OptimisedCoachee[];
    isLoadingCoachees: boolean;
    onFilterUpdated: (values: OptimisedCoachee[]) => void;
    allocationId: number;
    refetchData: () => void;
}

const OptimisedCoacheesTable: FC<OptimisedCoacheeTableProps> = ({
    coachees,
    filteredCoachees,
    isLoadingCoachees,
    onFilterUpdated,
    allocationId,
    refetchData,
}) => {
    const { l } = useLocale();

    const showToast = useToast();

    const [dropdownValue, setDropdownValue] = React.useState<SelectValue>(10);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(10);

    const [showWithoutCareerCoach, setShowWithoutCareerCoach] = useState(false);
    const [showWithWarning, setShowWithWarning] = useState(false);

    const [searchString, setSearchString] = useState<string>('');

    const [total, setTotal] = useState(0);

    const updateNotes =
        usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationId();

    const [selectedCoachee, setSelectedCoachee] =
        useState<IndexedCoacheeData>();

    const [displayEditNotesModal, setDisplayEditNotesModal] = useState(false);

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const onPageSizeSelect = (value: SelectValue) => {
        const selectedPageSize = Number(value);

        setItemsPerPage(selectedPageSize);
        setCurrentPage(1);
    };

    const renderNameCell = (row: IndexedCoacheeData, field: string) => {
        const renderCoacheeWarnings = (row: IndexedCoacheeData) => {
            return (
                <span>
                    {row.warnings && row.warnings.length > 0 && (
                        <Tooltip
                            content={() => (
                                <ul>
                                    {row.warnings?.map((warning, index) => (
                                        <li key={index}>{warning}</li>
                                    ))}
                                </ul>
                            )}
                        >
                            <button
                                className="Appkit4-icon icon-circle-warning-outline"
                                style={{ color: '#D04A02' }}
                            ></button>
                        </Tooltip>
                    )}
                </span>
            );
        };

        if (!(field in row)) {
            return '';
        }

        return (
            <span>
                {String(row[field as keyof IndexedCoacheeData])}
                {renderCoacheeWarnings(row)}
            </span>
        );
    };

    const renderTooltip = (content: string, field: string) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-${field}`}
                content={`${content}`}
                className="tooltip-content"
            >
                <span>{content}</span>
            </Tooltip>
        );
    };

    const filterData = () => {
        let updatedFilteredData = coachees;

        if (showWithoutCareerCoach) {
            updatedFilteredData = updatedFilteredData.filter(
                (item) =>
                    !item.careerCoach?.name ||
                    UnallocatedCoachNames.includes(item.careerCoach.name)
            );
        }

        if (showWithWarning) {
            updatedFilteredData = updatedFilteredData.filter(
                (item) => item.warnings && item.warnings.length > 0
            );
        }

        if (searchString) {
            const lowercaseSearch = searchString.toLowerCase();

            updatedFilteredData = updatedFilteredData.filter(
                (item) =>
                    item.name?.toLowerCase().includes(lowercaseSearch) ||
                    item.costCenter?.toLowerCase().includes(lowercaseSearch) ||
                    item.grade?.toLowerCase().includes(lowercaseSearch)
            );
        }

        onFilterUpdated(updatedFilteredData);

        // On change of filters, set to page 1
        setCurrentPage(1);
    };

    useEffect(() => {
        filterData();
    }, [showWithoutCareerCoach, showWithWarning, searchString, coachees]);

    const handleWithoutCareerCoachClick = () => {
        if (!showWithoutCareerCoach && filteredCoachees.length === 0) {
            return;
        }

        setShowWithoutCareerCoach(!showWithoutCareerCoach);
        filterData();
    };

    const handleWithWarningClick = () => {
        if (!showWithWarning && filteredCoachees.length === 0) {
            return;
        }

        setShowWithWarning(!showWithWarning);
        filterData();
    };

    useEffect(() => {
        const maxPageNum = filteredCoachees
            ? Math.ceil(filteredCoachees.length / itemsPerPage)
            : null;

        if (filteredCoachees && maxPageNum && currentPage > maxPageNum) {
            setCurrentPage(maxPageNum);
        }

        setTotal(
            filteredCoachees
                ? Math.ceil(filteredCoachees.length / itemsPerPage)
                : 0
        );
    }, [filteredCoachees, itemsPerPage, currentPage]);

    const renderNotesCell = (row: IndexedCoacheeData) => {
        return (
            <div style={{ display: 'flex', maxWidth: '12rem' }}>
                <div
                    className="notes-container"
                    style={{
                        maxWidth: '10rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {row.notes}
                </div>
                <span
                    className="Appkit4-icon icon-edit-outline"
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        color: '#D04A02',
                    }}
                    onClick={() => {
                        setDisplayEditNotesModal(true);
                        setSelectedCoachee(row);
                    }}
                />
            </div>
        );
    };

    const editNotes = async (value: string) => {
        await updateNotes.mutate(
            {
                allocationId: allocationId,
                data: {
                    id: selectedCoachee?.id,
                    notes: value,
                    isCoachee: true,
                },
            },
            {
                onSuccess: async () => {
                    showToast(l('_success'), '', 'success');
                    setDisplayEditNotesModal(false);

                    refetchData();
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    return (
        <div>
            <Panel className="postallocation-panel-wrapper">
                {!isLoadingCoachees ? (
                    <>
                        <div className="ap-table-coachees">
                            <div>
                                <div className="search-sample">
                                    <GlobalSearch
                                        onSearch={(searchString) => {
                                            setSearchString(searchString);
                                            filterData();
                                        }}
                                    />
                                    <div className="filter-pills">
                                        <Badge
                                            className={`postallocation-pill-one ${
                                                showWithoutCareerCoach
                                                    ? 'clicked'
                                                    : filteredCoachees.length ===
                                                      0
                                                    ? 'pill-disabled'
                                                    : ''
                                            }`}
                                            role="button"
                                            onClick={
                                                handleWithoutCareerCoachClick
                                            }
                                            value={l(
                                                '_withoutCareerCoachFilter'
                                            )}
                                        ></Badge>
                                        <Badge
                                            className={`postallocation-pill-two ${
                                                showWithWarning
                                                    ? 'clicked'
                                                    : filteredCoachees.length ===
                                                      0
                                                    ? 'pill-disabled'
                                                    : ''
                                            }`}
                                            role="button"
                                            onClick={handleWithWarningClick}
                                            value={l('_withWarningFilter')}
                                        >
                                            <button
                                                onClick={handleWithWarningClick}
                                            ></button>
                                        </Badge>
                                    </div>
                                </div>
                            </div>
                            <div className="coachees-checkboxes-component">
                                <h4>
                                    {filteredCoachees?.length}{' '}
                                    {l('_allocations')}
                                </h4>
                            </div>
                            <div className="coachees-table-container">
                                <Table
                                    className="ap-table-checkable-coachees"
                                    originalData={filteredCoachees}
                                    hasTitle
                                    currentPage={currentPage}
                                    pageSize={itemsPerPage}
                                >
                                    <Column
                                        field="name"
                                        sortKey="name"
                                        sortFunc1={(a: any, b: any) => {
                                            return a.name.localeCompare(b.name);
                                        }}
                                        sortFunc2={(a: any, b: any) => {
                                            return b.name.localeCompare(a.name);
                                        }}
                                        renderCell={renderNameCell}
                                    >
                                        <span>{l('_name')}</span>
                                    </Column>
                                    <Column
                                        field="careerCoach"
                                        sortKey="careerCoach"
                                        sortFunc1={(a: any, b: any) => {
                                            return a.careerCoach.name.localeCompare(
                                                b.careerCoach.name
                                            );
                                        }}
                                        sortFunc2={(a: any, b: any) => {
                                            return b.careerCoach.name.localeCompare(
                                                a.careerCoach.name
                                            );
                                        }}
                                        renderCell={(row: OptimisedCoachee) => {
                                            if (
                                                row?.careerCoach?.name ==
                                                    undefined ||
                                                row.careerCoach.name == ''
                                            ) {
                                                return (
                                                    <span className="Appkit4-icon icon-minus-fill" />
                                                );
                                            }

                                            return renderTooltip(
                                                row.careerCoach.name,
                                                'previousCareerCoach'
                                            );
                                        }}
                                    >
                                        {l('_currentCoach')}
                                    </Column>
                                    <Column
                                        field="previousCareerCoach"
                                        sortKey="previousCareerCoach"
                                        sortFunc1={(a: any, b: any) => {
                                            return a.previousCareerCoach.name.localeCompare(
                                                b.previousCareerCoach.name
                                            );
                                        }}
                                        sortFunc2={(a: any, b: any) => {
                                            return b.previousCareerCoach.name.localeCompare(
                                                a.previousCareerCoach.name
                                            );
                                        }}
                                        renderCell={(row) => {
                                            if (
                                                row.previousCareerCoach.name ==
                                                0
                                            ) {
                                                return (
                                                    <span className="Appkit4-icon icon-minus-fill" />
                                                );
                                            }

                                            return renderTooltip(
                                                row.previousCareerCoach.name,
                                                'previousCareerCoach.name'
                                            );
                                        }}
                                    >
                                        {l('_previousCoach')}
                                    </Column>
                                    <Column
                                        field="relationshipLeader"
                                        sortKey="relationshipLeader"
                                        sortFunc1={(a: any, b: any) => {
                                            return a.relationshipLeader.localeCompare(
                                                b.relationshipLeader
                                            );
                                        }}
                                        sortFunc2={(a: any, b: any) => {
                                            return b.relationshipLeader.localeCompare(
                                                a.relationshipLeader
                                            );
                                        }}
                                    >
                                        {l('_relationshipLeader')}
                                    </Column>
                                    <Column
                                        field="grade"
                                        sortKey="grade"
                                        renderCell={(row) =>
                                            renderTooltip(row.grade, 'grade')
                                        }
                                    >
                                        {l('_coacheeGrade')}
                                    </Column>
                                    <Column
                                        field="costCenter"
                                        sortKey="costCenter"
                                        renderCell={(row) =>
                                            renderTooltip(
                                                row.costCenter,
                                                'costCenter'
                                            )
                                        }
                                    >
                                        {l('_costCentre')}
                                    </Column>
                                    <Column
                                        field="email"
                                        sortKey="email"
                                        renderCell={(row) =>
                                            renderTooltip(row.email, 'email')
                                        }
                                    >
                                        {l('_coacheeEmail')}
                                    </Column>
                                    <Column field="id" sortKey="id">
                                        {l('_workdayId')}
                                    </Column>
                                    <Column
                                        field="notes"
                                        renderCell={renderNotesCell}
                                    >
                                        {l('_notes')}
                                    </Column>
                                </Table>
                            </div>
                        </div>
                        {filteredCoachees.length == 0 && (
                            <h5 className="text-center mt-2 ap-text-states-error-03">
                                {l('_noSearchResults')}
                            </h5>
                        )}
                    </>
                ) : (
                    <Loading
                        loadingType="circular"
                        indeterminate
                        compact={false}
                    />
                )}
            </Panel>
            <GlobalPagination
                dropdownValue={dropdownValue}
                currentPage={currentPage}
                totalPages={total}
                onPageChange={onPageChange}
                onDropdownSelect={(selectedValue) => {
                    setDropdownValue(selectedValue);
                    onPageSizeSelect(selectedValue);
                }}
            />
            {displayEditNotesModal && (
                <EditNotesModal
                    isVisible={true}
                    setIsVisible={setDisplayEditNotesModal}
                    onEdit={(value) => {
                        editNotes(value);
                    }}
                    name={selectedCoachee?.name || ''}
                    currentNotes={selectedCoachee?.notes || ''}
                    editNotesModalTitle={l('_coachee')}
                />
            )}
        </div>
    );
};

export default OptimisedCoacheesTable;
