import { useState } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import SuperAdminCreateBusinessUnitForm, {
    FormValues,
} from './SuperAdminCreateBusinessUnitForm';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import useToast from '../../helpers/useToast';
import {
    PostV1BusinessUnitSuperAdminMutationError,
    getGetV1BusinessUnitSuperAdminQueryKey,
    useGetV1ContactPersonSuperAdmin,
    usePostV1BusinessUnitSuperAdmin,
} from '../../api/generated/endpoint';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import { useQueryClient } from '@tanstack/react-query';
import SuperAdminEditContactPersonModal from './SuperAdminEditContactPersonModal';
import SuperAdminConfirmDeleteContactPersonModal from './SuperAdminConfirmDeleteContactPersonModal';

interface ISuperAdminCreateBusinessUnitModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
}

const SuperAdminCreateBusinessUnitModal = (
    props: ISuperAdminCreateBusinessUnitModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createNewBusinessUnit = usePostV1BusinessUnitSuperAdmin();
    const queryClient = useQueryClient();
    const [
        displayEditContactPersonModalForId,
        setDisplayEditContactPersonModalForId,
    ] = useState<number | null>(null);
    const [
        showDeleteContactPersonConfirmationForId,
        setShowDeleteContactPersonConfirmationForId,
    ] = useState<number | null>(null);

    const postNewBusinessUnitRequest = (values: FormValues) => {
        createNewBusinessUnit.mutate(
            {
                data: {
                    name: values.name,
                    contactPersonIds: values.contactPersonIds,
                },
            },
            {
                onSuccess: () => {
                    showToast(
                        l('_success'),
                        l('_newBusinessUnitCreated'),
                        'success'
                    );
                    props.setIsVisible(false);
                    queryClient.invalidateQueries(
                        getGetV1BusinessUnitSuperAdminQueryKey()
                    );
                },
                onError: (error: PostV1BusinessUnitSuperAdminMutationError) => {
                    showGenericErrorToast(error);
                    props.setIsVisible(false);
                },
            }
        );
    };

    const { data: contactPersons } = useGetV1ContactPersonSuperAdmin(
        undefined,
        {
            query: {
                onError: showGenericErrorToast,
            },
        }
    );

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_createNewBusinessUnit')}
                        aria-label={l('_createANewBusinessUnit')}
                        modalStyle={{
                            width: '75vw',
                            maxWidth: '750px',
                            minWidth: '500px',
                        }}
                        footerStyle={{ marginTop: '1rem' }}
                        closeOnPressEscape
                        onCancel={() => props.setIsVisible(false)}
                    >
                        <SuperAdminCreateBusinessUnitForm
                            submitFunc={postNewBusinessUnitRequest}
                            contactPersons={
                                contactPersons ? contactPersons : []
                            }
                            setDisplayEditContactPersonModalForId={
                                setDisplayEditContactPersonModalForId
                            }
                            setShowDeleteContactPersonConfirmationForId={
                                setShowDeleteContactPersonConfirmationForId
                            }
                        />
                    </Modal>
                </ModalContainer>
            )}

            {displayEditContactPersonModalForId && contactPersons && (
                <SuperAdminEditContactPersonModal
                    isVisible={displayEditContactPersonModalForId != null}
                    setNotVisible={() => {
                        setDisplayEditContactPersonModalForId(null);
                    }}
                    contactPerson={contactPersons?.find(
                        (c) => c.id == displayEditContactPersonModalForId
                    )}
                />
            )}

            {showDeleteContactPersonConfirmationForId && contactPersons && (
                <SuperAdminConfirmDeleteContactPersonModal
                    visible={showDeleteContactPersonConfirmationForId != null}
                    contactPerson={contactPersons?.find(
                        (c) => c.id == showDeleteContactPersonConfirmationForId
                    )}
                    setNotVisible={() => {
                        setShowDeleteContactPersonConfirmationForId(null);
                    }}
                />
            )}
        </>
    );
};

export default SuperAdminCreateBusinessUnitModal;
