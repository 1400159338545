import { Avatar, AvatarGroup } from '@appkit4/react-components/avatar';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { SuperAdminBusinessUnitResponse } from '../../api/generated/models';
import ContactAvatar from '../ContactAvatar';
import useWindowWidth from '../../helpers/useWindowWidth';

export interface IBusinessUnitManagementContactsCellProps {
    businessUnit: SuperAdminBusinessUnitResponse;
}

const avatarStyle = {
    width: '23px',
    height: '23px',
    fontSize: '11px',
    fontWeight: 'normal',
    padding: '0',
};

const BusinessUnitManagementContactsCell = (
    props: IBusinessUnitManagementContactsCellProps
) => {
    const narrowScreenWidth = 575;
    const windowWidth = useWindowWidth();
    const maxAvatars = windowWidth < narrowScreenWidth ? 3 : 5;

    const extraAvatarsCount =
        (props.businessUnit.contactPersons?.length || maxAvatars) - maxAvatars;
    const extraAvatarsNames = (props.businessUnit.contactPersons || [])
        .slice(maxAvatars)
        .map((contact) => `${contact.forenames} ${contact.surname}`)
        .join(', ');

    const divStyle = {
        height: '26px',
        display: 'flex',
        alignItems: 'center',
    };

    const avatars: JSX.Element[] = (props.businessUnit.contactPersons || [])
        .slice(0, maxAvatars)
        .map((contact, index) => (
            <div key={contact.id}>
                <ContactAvatar contact={contact} index={index} />
            </div>
        ));

    /* display +{extra Avatars} if more than 0 */
    if (extraAvatarsCount > 0) {
        avatars.push(
            <div key={maxAvatars}>
                <Tooltip
                    trigger="hover"
                    position="right"
                    distance={5}
                    id={`${maxAvatars}`}
                    content={extraAvatarsNames}
                >
                    <Avatar
                        label={`+${extraAvatarsCount}`}
                        style={avatarStyle}
                        className="ap-bg-neutral-09"
                    />
                </Tooltip>
            </div>
        );
    }

    return (
        <div className="col" style={divStyle}>
            <AvatarGroup>{avatars}</AvatarGroup>
        </div>
    );
};

export default BusinessUnitManagementContactsCell;
