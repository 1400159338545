import React, { useState } from 'react';
import { Table, Column } from '@appkit4/react-components/table';
import { Loading } from '@appkit4/react-components/loading';
import { Pagination } from '@appkit4/react-components/pagination';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import { Button, Search, Tooltip } from '@appkit4/react-components';
import SuperAdminCreateBusinessUnitModal from '../../components/SuperAdmin/SuperAdminCreateBusinessUnitModal';
import SuperAdminEditBusinessUnitModal from '../../components/SuperAdmin/SuperAdminEditBusinessUnitModal';
import SuperAdminConfirmDeleteBusinessUnitModal from '../../components/SuperAdmin/SuperAdminConfirmDeleteBusinessUnitModal';
import useLocale from '../../locale/useLocale';
import { useGetV1BusinessUnitSuperAdmin } from '../../api/generated/endpoint';
import BusinessUnitManagementActionsCell from '../../components/SuperAdmin/BusinessUnitManagementActionsCell';
import BusinessUnitManagementAdminsCell from '../../components/SuperAdmin/BusinessUnitManagementAdminsCell';
import { BusinessUnitResponse } from '../../api/generated/models';
import BusinessUnitManagementContactsCell from '../../components/SuperAdmin/BusinessUnitManagementContactsCell';
import usePaginationControl from '../../helpers/usePaginationControl';
import useTitle from '../../helpers/useTitle';

import './AdminPanel.scss';

const BusinessUnitManagement = () => {
    const { l } = useLocale();
    useTitle(l('_businessUnitManagementTitleCase'));

    const [displayCreateBusinessUnitModal, setDisplayCreateBusinessUnitModal] =
        useState(false);
    const [
        displayEditBusinessUnitModalForId,
        setDisplayEditBusinessUnitModalForId,
    ] = useState<number | undefined>(undefined);
    const [
        displayDeleteBusinessUnitModalForId,
        setDisplayDeleteBusinessUnitModalForId,
    ] = useState<number | undefined>(undefined);

    const [searchString, setSearchString] = useState<string>('');
    const pageSize = 20;
    const [pageNum, setPageNum] = useState<number>(1);
    const skip = (pageNum - 1) * pageSize;
    const take = pageSize;
    const showGenericErrorToast = useGenericErrorToast();

    const { data: paginatedBusinessUnits, isLoading: loadingBusinessUnits } =
        useGetV1BusinessUnitSuperAdmin(
            { skip, take, searchString },
            {
                query: {
                    onError: showGenericErrorToast,
                },
            }
        );

    /* prevent current page number being invalid */
    usePaginationControl(
        { totalCount: paginatedBusinessUnits?.totalBusinessUnitCount },
        pageSize,
        setPageNum,
        pageNum
    );

    const findBusinessUnitById = (id: number) =>
        paginatedBusinessUnits?.businessUnits.find((t) => t.id == id);

    const renderNameTooltip = (row: BusinessUnitResponse) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-name`}
                content={row.name}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row.name}</span>
            </Tooltip>
        );
    };

    let typingTimer: NodeJS.Timeout; //timer identifier
    const doneTypingIntervals = 1000; //time in ms

    const actionsColumnStyle: React.CSSProperties = {
        width: '6rem',
    };

    return (
        <>
            <div className="row mt-4">
                <div className="col">
                    {!displayEditBusinessUnitModalForId &&
                        !displayDeleteBusinessUnitModalForId && (
                            <Search
                                searchType="primary"
                                placeholder={l('_search')}
                                disabled={loadingBusinessUnits}
                                onSearch={(searchString) =>
                                    setSearchString(searchString)
                                }
                                onChange={(search: string, event) => {
                                    clearTimeout(typingTimer);
                                    if (!search) {
                                        setSearchString('');
                                    } else {
                                        typingTimer = setTimeout(() => {
                                            // simulate pressing enter on the search box
                                            // so the onSearch method handles all the actual searching
                                            event.target.dispatchEvent(
                                                new KeyboardEvent('keydown', {
                                                    code: 'Enter',
                                                    key: 'Enter',
                                                    charCode: 13,
                                                    keyCode: 13,
                                                    view: window,
                                                    bubbles: true,
                                                })
                                            );
                                        }, doneTypingIntervals);
                                    }
                                }}
                                onClear={() => setSearchString('')}
                                className="m-0 p-0"
                            />
                        )}
                </div>
                <div className="col">
                    <Button
                        kind="primary"
                        icon="icon-plus-outline"
                        type="button"
                        role="button"
                        onClick={() => setDisplayCreateBusinessUnitModal(true)}
                        className="ms-auto align-top h-100"
                        style={{ paddingTop: '12px' }}
                    >
                        {l('_addBusinessUnit')}
                    </Button>
                </div>
            </div>

            {displayCreateBusinessUnitModal && (
                <SuperAdminCreateBusinessUnitModal
                    isVisible
                    setIsVisible={setDisplayCreateBusinessUnitModal}
                />
            )}

            {displayEditBusinessUnitModalForId != undefined && (
                <SuperAdminEditBusinessUnitModal
                    isVisible
                    setNotVisible={() =>
                        setDisplayEditBusinessUnitModalForId(undefined)
                    }
                    businessUnit={findBusinessUnitById(
                        displayEditBusinessUnitModalForId
                    )}
                />
            )}

            {displayDeleteBusinessUnitModalForId != undefined && (
                <SuperAdminConfirmDeleteBusinessUnitModal
                    visible
                    businessUnit={findBusinessUnitById(
                        displayDeleteBusinessUnitModalForId
                    )}
                    setNotVisible={() =>
                        setDisplayDeleteBusinessUnitModalForId(undefined)
                    }
                />
            )}

            <Table
                className="ap-mt-spacing-4 admin-table-container"
                originalData={paginatedBusinessUnits?.businessUnits ?? []}
                condensed
                striped
                hasTitle
            >
                <Column field="name" renderCell={renderNameTooltip}>
                    {l('_name')}
                </Column>
                <Column
                    renderCell={(row: BusinessUnitResponse) => (
                        <BusinessUnitManagementAdminsCell businessUnit={row} />
                    )}
                    field="admins"
                >
                    {l('_admins')}
                </Column>
                <Column
                    renderCell={(row: BusinessUnitResponse) => (
                        <BusinessUnitManagementContactsCell
                            businessUnit={row}
                        />
                    )}
                    field="contactPersons"
                >
                    {l('_contacts')}
                </Column>
                <Column
                    renderCell={(row: BusinessUnitResponse) => (
                        <BusinessUnitManagementActionsCell
                            businessUnit={row}
                            displayRenameBusinessUnitModal={() =>
                                setDisplayEditBusinessUnitModalForId(row.id)
                            }
                            displayDeleteBusinessUnitModal={() =>
                                setDisplayDeleteBusinessUnitModalForId(row.id)
                            }
                        />
                    )}
                    field="id"
                    style={actionsColumnStyle}
                >
                    {l('_actions')}
                </Column>
            </Table>

            {!loadingBusinessUnits &&
                paginatedBusinessUnits &&
                paginatedBusinessUnits.businessUnits.length > 0 && (
                    <Pagination
                        data-testid="pagination"
                        total={Math.ceil(
                            paginatedBusinessUnits.totalBusinessUnitCount /
                                pageSize
                        )}
                        current={pageNum}
                        onPageChange={(page: number) => setPageNum(page)}
                        style={{ float: 'right' }}
                    />
                )}

            {loadingBusinessUnits && (
                <Loading loadingType="circular" indeterminate compact={false} />
            )}

            {/* Display message if no businessUnits found from search */}
            {!loadingBusinessUnits &&
                (!paginatedBusinessUnits ||
                    paginatedBusinessUnits.businessUnits.length == 0) && (
                    <h5 className="text-center mt-2 ap-text-states-error-03">
                        {l('_noBusinessUnitsFound')}
                    </h5>
                )}
        </>
    );
};

export default BusinessUnitManagement;
