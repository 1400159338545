import { Modal } from '@appkit4/react-components/modal';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';
import ModalContainer from '../ModalContainer';
import SuperAdminCreateContactPersonForm, {
    FormValues,
} from './SuperAdminCreateContactPersonForm';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import {
    PostV1ContactPersonSuperAdminMutationError,
    getGetV1ContactPersonSuperAdminQueryKey,
    usePostV1ContactPersonSuperAdmin,
} from '../../api/generated/endpoint';
import { useQueryClient } from '@tanstack/react-query';
import { ContactPersonResponse } from '../../api/generated/models';

interface ISuperAdminCreateContactPersonModalProps {
    isVisible: boolean;
    setNotVisible: () => void;
    onCreationSuccess: (newContact: ContactPersonResponse) => void;
}

const SuperAdminCreateContactPersonModal = (
    props: ISuperAdminCreateContactPersonModalProps
) => {
    const showToast = useToast();
    const { l } = useLocale();
    const showGenericErrorToast = useGenericErrorToast();
    const createBusinessUnitContact = usePostV1ContactPersonSuperAdmin();
    const queryClient = useQueryClient();

    const postNewContactPersonRequest = (values: FormValues) => {
        createBusinessUnitContact.mutate(
            { data: values },
            {
                onSuccess: (newContact) => {
                    showToast(
                        l('_success'),
                        l('_newContactPersonCreated'),
                        'success'
                    );
                    props.onCreationSuccess(newContact);
                },
                onError: (
                    error: PostV1ContactPersonSuperAdminMutationError
                ) => {
                    showGenericErrorToast(error);
                },
                onSettled: () => {
                    props.setNotVisible();
                    queryClient.invalidateQueries(
                        getGetV1ContactPersonSuperAdminQueryKey()
                    );
                },
            }
        );
    };

    return (
        <>
            {props.isVisible && (
                <ModalContainer isVisible={props.isVisible}>
                    <Modal
                        visible
                        closable
                        closeAriaLabel={l('_close')}
                        title={l('_createNewContactPerson')}
                        aria-label={l('_createNewContactPerson')}
                        modalStyle={{
                            width: '50vw',
                            maxWidth: '500px',
                            minWidth: '250px',
                        }}
                        closeOnPressEscape
                        onCancel={() => props.setNotVisible()}
                    >
                        <SuperAdminCreateContactPersonForm
                            submitFunc={postNewContactPersonRequest}
                        />
                    </Modal>
                </ModalContainer>
            )}
        </>
    );
};

export default SuperAdminCreateContactPersonModal;
