export const enum AppRoutes {
    LandingPage = '/login',
    SettingsPage = '/settings',
    HomePage = '/',
    NewAllocation = '/allocation/new',
    PreAllocation = '/allocation/pre',
    ReviewAllocation = '/allocation/review',
    AllocationHistory = '/allocation/history',
    AllocatingLoading = '/allocation/loading',
    AllocationDashboard = '/dashboard',
    LogoutPage = '/logout',
    CookiesPage = '/cookies',
    TandC = '/tandc',
    PrivacyPage = '/privacy',
    ContactPage = '/contact',
    AccessibilityStatement = '/accessibility',
    UserManagement = `/admin/userManagement`,
    Unauthorized = '/403',
    BusinessUnitManagement = '/admin/businessUnitManagement',
    BusinessRuleManagement = `/admin/businessRuleManagement`,
    BusinessMetrics = '/admin/businessMetrics',
}

export const LoginRedirectStorageKey = 'returnRoute';

export const SampleUploadUrl =
    'https://docs.google.com/spreadsheets/d/1yJAIyZUVgm-NElTbQvrNOHUBfUDJwyp-/edit?usp=sharing&ouid=107132689094556992611&rtpof=true&sd=true';
