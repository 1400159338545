import { ResponsiveCalendar } from '@nivo/calendar';
import '../../pages/metrics/BusinessMetrics.scss';
import { useIsDarkModeActive } from '../../helpers/DarkModeDetector';
import appkitVariables from '../../styles/appkit-variables.module.scss';

interface CalendarProps {
    data: {
        value: number;
        day: string;
    }[];
}

const CalendarTemplate = ({ data }: CalendarProps) => {
    const calendarTheme = {
        textColor: appkitVariables.neutral10,
        tooltip: {
            container: {
                fontSize: '12px',
                color: appkitVariables.neutral23,
            },
        },
    };

    const isDarkModeActive = useIsDarkModeActive();
    const calendarBackgroundColor = isDarkModeActive
        ? appkitVariables.neutral20
        : appkitVariables.neutral01;

    const calendarValueColors = isDarkModeActive
        ? [
              appkitVariables.primaryred04,
              appkitVariables.datayellow,
              appkitVariables.datagreendarker,
              appkitVariables.datagreenlight,
          ]
        : [
              appkitVariables.dataredlight,
              appkitVariables.datayellowlight,
              appkitVariables.datagreenlight,
              appkitVariables.datagreendark,
          ];

    const today = new Date();

    return (
        <ResponsiveCalendar
            data={data}
            from={today}
            to={today} // this value is let's say "ignored", I cannot set it to two months calendar, just yearly
            emptyColor={calendarBackgroundColor}
            colors={calendarValueColors}
            margin={{ top: 0, right: 10, bottom: 0, left: 20 }}
            yearSpacing={40}
            monthSpacing={0}
            monthBorderColor={calendarBackgroundColor}
            dayBorderWidth={5}
            dayBorderColor={calendarBackgroundColor}
            legends={[
                {
                    anchor: 'bottom-right',
                    direction: 'row',
                    translateY: 36,
                    itemCount: 4,
                    itemWidth: 42,
                    itemHeight: 36,
                    itemsSpacing: 14,
                    itemDirection: 'right-to-left',
                },
            ]}
            theme={calendarTheme}
        />
    );
};

export default CalendarTemplate;
