import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { FC, useState } from 'react';
import useLocale from '../locale/useLocale';
import { TextArea } from '@appkit4/react-components/field';

interface EditNotesModalProps {
    isVisible: boolean;
    setIsVisible: (display: boolean) => void;
    onEdit: (value: string) => void;
    name: string;
    currentNotes: string;
    editNotesModalTitle: string;
}

const EditNotesModal: FC<EditNotesModalProps> = (props) => {
    const { l } = useLocale();

    const handleEditClick = () => {
        props.setIsVisible(false);
        props.onEdit(value);
    };

    const [value, setValue] = useState(props.currentNotes);

    const onChange = (value: any) => {
        setValue(value);
    };

    return (
        <>
            {props.isVisible && (
                <Modal
                    visible={props.isVisible}
                    title={`Notes: ${props.editNotesModalTitle} - ${props.name}`}
                    ariaLabel={''}
                    onCancel={() => props.setIsVisible(false)}
                    modalStyle={{ width: '33.75rem' }}
                    footerStyle={{
                        paddingTop: '8px',
                        marginTop: '-8px',
                        minHeight: '64px',
                    }}
                    header={''}
                    icons={''}
                    footer={
                        <>
                            <Button
                                onClick={() => props.setIsVisible(false)}
                                kind="secondary"
                            >
                                {l('_cancel')}
                            </Button>
                            <Button
                                icon="icon-save-outline"
                                onClick={() => {
                                    handleEditClick();
                                }}
                            >
                                {l('_save')}
                            </Button>
                        </>
                    }
                    bodyStyle={{ minHeight: '92px' }}
                >
                    <div
                        className="ap-field-demo-wrapper"
                        style={{
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '0.5rem',
                        }}
                    >
                        <TextArea
                            value={value}
                            onChange={onChange}
                            maxLength={490}
                        ></TextArea>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default EditNotesModal;
