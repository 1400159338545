import { FC, Dispatch, SetStateAction, useState, useRef } from 'react';
import { Table, Column } from '@appkit4/react-components/table';
import './AllocationHistoryTable.scss';
import useLocale from '../../locale/useLocale';
import { Checkbox, Loading, Radio, Tooltip } from '@appkit4/react-components';
import NoAllocations from '../../pages/allocation/NoAllocations';
import { AllocationHttpStatus } from '../../api/generated/models';
import EditNotesModal from '../EditNotesModal';
import {
    usePutV1AllocationAllocationUpdateNotesAllocationId,
    getGetV1AllocationQueryKey,
} from '../../api/generated/endpoint';
import useToast from '../../helpers/useToast';
import { useQueryClient } from '@tanstack/react-query';
import { Panel } from '@appkit4/react-components';

interface AllocationHistoryProps {
    data: AllocationHistoryData[];
    loadingAllocationHistoryData: boolean;
    selectedAllocationIds: number[];
    setSelectedAllcoationIds: Dispatch<SetStateAction<number[]>>;
    tableType: 'full' | 'short';
}

export interface AllocationHistoryData {
    id: number;
    fileName: string;
    editor: string;
    lastEdited: Date | null;
    dateCreated: Date;
    status: string;
    httpStatus: AllocationHttpStatus | null;
    notes: string | null;
}

const AllocationHistoryTable: FC<AllocationHistoryProps> = ({
    data,
    loadingAllocationHistoryData,
    selectedAllocationIds,
    setSelectedAllcoationIds,
    tableType,
}) => {
    const { l } = useLocale();

    const [checkValue, setCheckValue] = useState(false);

    const [displayEditNotesModal, setDisplayEditNotesModal] = useState(false);

    const selectedAllocationNameRef = useRef<string>('');

    const selectedAllocationIdRef = useRef<number>();

    const showToast = useToast();

    const queryClient = useQueryClient();

    const updateNotes = usePutV1AllocationAllocationUpdateNotesAllocationId();

    const renderFileNameCell = (row: AllocationHistoryData, field: string) => {
        const onCheckboxChange = (value: boolean) => {
            if (value) {
                setSelectedAllcoationIds((prev) => [...prev, row.id]);
            } else {
                setSelectedAllcoationIds((prev) =>
                    prev.filter((prevRowId) => prevRowId !== row.id)
                );
            }
        };

        if (!(field in row)) return '';

        const fieldValue = (
            <span>
                {String(row[field as keyof AllocationHistoryData])}
                {row.httpStatus && row.httpStatus.id != 200 && (
                    <Tooltip
                        content={() => (
                            <ul>
                                <li key={row.id}>
                                    {row.httpStatus?.description}
                                </li>
                            </ul>
                        )}
                    >
                        <button
                            className="Appkit4-icon icon-circle-warning-outline"
                            style={{ color: '#D04A02' }}
                        ></button>
                    </Tooltip>
                )}
            </span>
        );

        return tableType === 'full' ? (
            <Checkbox
                checked={selectedAllocationIds.some((id) => id === row.id)}
                onChange={(value) => onCheckboxChange(value)}
            >
                {fieldValue}
            </Checkbox>
        ) : (
            <Radio
                checked={selectedAllocationIds.some((id) => id === row.id)}
                onClick={() => setSelectedAllcoationIds([row.id])}
            >
                {fieldValue}
            </Radio>
        );
    };

    const renderLastUpdatedCell = (row: AllocationHistoryData) => {
        // If not edited, represent as minus symbol
        if (row.lastEdited) {
            return <span>{row.lastEdited.toLocaleDateString()}</span>;
        } else {
            return <span className="Appkit4-icon icon-minus-fill" />;
        }
    };

    const renderNotesCell = (row: AllocationHistoryData) => {
        return (
            <div style={{ display: 'flex', maxWidth: '12rem' }}>
                <div
                    className="notes-container"
                    style={{
                        maxWidth: '10rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {row.notes}
                </div>
                <span
                    className="Appkit4-icon icon-edit-outline"
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        color: '#D04A02',
                    }}
                    onClick={() => {
                        setDisplayEditNotesModal(true);
                        selectedAllocationNameRef.current = row.fileName;
                        selectedAllocationIdRef.current = row.id;
                    }}
                />
            </div>
        );
    };

    const editNotes = async (value: string) => {
        await updateNotes.mutate(
            {
                allocationId: selectedAllocationIdRef.current || 1,
                data: value,
            },
            {
                onSuccess: () => {
                    showToast(l('_success'), '', 'success');
                    queryClient.invalidateQueries(getGetV1AllocationQueryKey());
                    setDisplayEditNotesModal(false);
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
        setSelectedAllcoationIds([]);
    };

    const onCheckTotalChange = (
        value: boolean,
        event: React.SyntheticEvent
    ) => {
        if (
            (
                (event.target as HTMLElement).parentNode as HTMLElement
            ).className.indexOf('ap-checkbox-label') > -1
        ) {
            return;
        }

        if (value) {
            const allAllocationIds = data.map((allocation) => allocation.id);
            setSelectedAllcoationIds(allAllocationIds);
        } else {
            setSelectedAllcoationIds([]);
        }

        setCheckValue(value);
    };

    const sortTwoDates = (dateOne: Date | null, dateTwo: Date | null) => {
        if (dateTwo == null) return -1;
        if (dateOne == null) return 1;
        return dateOne < dateTwo ? 1 : -1;
    };

    return (
        <>
            {data.length > 0 || loadingAllocationHistoryData ? (
                <div className="table-container-history">
                    <Panel className="history-panel-wrapper">
                        <Table
                            className={`allocation-history-table ${tableType}`}
                            originalData={data}
                            hasTitle
                            selectedIndexs={selectedAllocationIds}
                        >
                            <Column
                                field="fileName"
                                sortKey="fileName"
                                renderCell={renderFileNameCell}
                            >
                                {tableType === 'full' ? (
                                    <Checkbox
                                        checked={checkValue}
                                        onChange={(value, event) =>
                                            onCheckTotalChange(value, event)
                                        }
                                    >
                                        {l('_fileName')}
                                    </Checkbox>
                                ) : (
                                    l('_fileName')
                                )}
                            </Column>
                            <Column field="editor" sortKey="editor">
                                {l('_editor')}
                            </Column>
                            <Column
                                field="lastEdited"
                                sortKey="lastEdited"
                                sortFunc1={(
                                    a: AllocationHistoryData,
                                    b: AllocationHistoryData
                                ) => sortTwoDates(a.lastEdited, b.lastEdited)}
                                sortFunc2={(
                                    a: AllocationHistoryData,
                                    b: AllocationHistoryData
                                ) => sortTwoDates(b.lastEdited, a.lastEdited)}
                                renderCell={renderLastUpdatedCell}
                            >
                                {l('_lastEdited')}
                            </Column>
                            <Column
                                field="dateCreated"
                                sortKey="dateCreated"
                                sortFunc1={(
                                    a: AllocationHistoryData,
                                    b: AllocationHistoryData
                                ) => sortTwoDates(a.dateCreated, b.dateCreated)}
                                sortFunc2={(
                                    a: AllocationHistoryData,
                                    b: AllocationHistoryData
                                ) => sortTwoDates(b.dateCreated, a.dateCreated)}
                                renderCell={(row: AllocationHistoryData) =>
                                    row.dateCreated.toLocaleDateString()
                                }
                            >
                                {l('_dateCreated')}
                            </Column>
                            <Column field="status" sortKey="status">
                                {l('_status')}
                            </Column>
                            <Column
                                field="notes"
                                sortKey="note"
                                renderCell={renderNotesCell}
                            >
                                {l('_notes')}
                            </Column>
                        </Table>
                        {loadingAllocationHistoryData && (
                            <Loading
                                loadingType="circular"
                                indeterminate
                                compact={false}
                            />
                        )}
                        {displayEditNotesModal && (
                            <EditNotesModal
                                isVisible={true}
                                setIsVisible={setDisplayEditNotesModal}
                                onEdit={(value) => {
                                    editNotes(value);
                                }}
                                name={selectedAllocationNameRef.current}
                                currentNotes={
                                    data.find(
                                        (item) =>
                                            item.id ===
                                            selectedAllocationIdRef.current
                                    )?.notes || ''
                                }
                                editNotesModalTitle={l('_allocation')}
                            />
                        )}
                    </Panel>
                </div>
            ) : (
                <NoAllocations />
            )}
        </>
    );
};

export default AllocationHistoryTable;
