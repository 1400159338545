/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * PwC Career Coach Allocator API
 * OpenAPI spec version: 1.0
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
    MutationFunction,
    QueryFunction,
    QueryKey,
    UseMutationOptions,
    UseMutationResult,
    UseQueryOptions,
    UseQueryResult,
} from '@tanstack/react-query';
import { useCallback } from 'react';
import type {
    Allocation,
    AllocationData,
    AllocationDataResponse,
    AllocationDetailResponse,
    AllocationResponse,
    AllocationStatusResponse,
    ApplicationVersionResponse,
    AssetsResponse,
    BusinessRuleResponse,
    BusinessUnitConfigurationResponse,
    BusinessUnitContentResponse,
    BusinessUnitResponse,
    CareerCoachCapacityResponse,
    ContactPersonResponse,
    CreateBusinessRuleRequest,
    CreateBusinessUnitRequest,
    CreateContactPersonRequest,
    CreateUserRequest,
    EditBusinessRuleRequest,
    EditBusinessUnitLandingPageContentRequest,
    EditBusinessUnitReportRequest,
    EditBusinessUnitRequest,
    EditBusinessUnitUploadRulesRequest,
    EditCareerCoachCapacityRequest,
    EditContactPersonRequest,
    EditPolicyRequest,
    EditUserRequest,
    EmbedResponse,
    EngagementsResponse,
    FileUploadResponse,
    GetV1AllocationGetAllocationsParams,
    GetV1AllocationParams,
    GetV1BusinessMetricsSuperAdminUserLoginsParams,
    GetV1BusinessRuleParams,
    GetV1BusinessUnitSuperAdminParams,
    GetV1ContactPersonSuperAdminParams,
    GetV1UserAdminParams,
    OptimisedAllocationDataResponse,
    OptimisedAllocationResponse,
    PaginatedBusinessRuleResponse,
    PaginatedBusinessUnitResponse,
    PaginatedUserLoginsResponse,
    PaginatedUserResponse,
    PolicyResponse,
    PolicyVersionResponse,
    PostV1AllocationUploadBody,
    RoleResponse,
    RunAllocationRequest,
    UpdateBusinessUnitContactPersonsRequest,
    UpdateNotesRequest,
    UserInfoResponse,
    UserLoginsSummaryResponse,
    UserPreferencesRequest,
    UserPreferencesResponse,
    UserResponse,
} from './models';
import { useCustomAxios } from '../mutator/custom-instance';
import type { ErrorType } from '../mutator/custom-instance';

/**
 * @summary Returns a list of all allocations for the requesting user's business unit
Super Admins are given allocations from all business units
 */
export const useGetV1AllocationHook = () => {
    const getV1Allocation = useCustomAxios<AllocationResponse>();

    return useCallback(
        (params?: GetV1AllocationParams) => {
            return getV1Allocation({
                url: `/v1/Allocation`,
                method: 'GET',
                params,
            });
        },
        [getV1Allocation]
    );
};

export const getGetV1AllocationQueryKey = (params?: GetV1AllocationParams) => {
    return [`/v1/Allocation`, ...(params ? [params] : [])] as const;
};

export const useGetV1AllocationQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1AllocationParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1AllocationQueryKey(params);

    const getV1Allocation = useGetV1AllocationHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>
    > = () => getV1Allocation(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1AllocationQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>
>;
export type GetV1AllocationQueryError = ErrorType<void>;

/**
 * @summary Returns a list of all allocations for the requesting user's business unit
Super Admins are given allocations from all business units
 */
export const useGetV1Allocation = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1AllocationParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1AllocationHook>>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1AllocationQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1AllocationAllocationAllocationIdHook = () => {
    const getV1AllocationAllocationAllocationId = useCustomAxios<Allocation>();

    return useCallback(
        (allocationId: number) => {
            return getV1AllocationAllocationAllocationId({
                url: `/v1/Allocation/allocation/${allocationId}`,
                method: 'GET',
            });
        },
        [getV1AllocationAllocationAllocationId]
    );
};

export const getGetV1AllocationAllocationAllocationIdQueryKey = (
    allocationId: number
) => {
    return [`/v1/Allocation/allocation/${allocationId}`] as const;
};

export const useGetV1AllocationAllocationAllocationIdQueryOptions = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationAllocationAllocationIdHook>
        >
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1AllocationAllocationAllocationIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1AllocationAllocationAllocationIdQueryKey(allocationId);

    const getV1AllocationAllocationAllocationId =
        useGetV1AllocationAllocationAllocationIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1AllocationAllocationAllocationIdHook>
            >
        >
    > = () => getV1AllocationAllocationAllocationId(allocationId);

    return {
        queryKey,
        queryFn,
        enabled: !!allocationId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1AllocationAllocationAllocationIdHook>
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1AllocationAllocationAllocationIdQueryResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationAllocationAllocationIdHook>
        >
    >
>;
export type GetV1AllocationAllocationAllocationIdQueryError = ErrorType<void>;

export const useGetV1AllocationAllocationAllocationId = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationAllocationAllocationIdHook>
        >
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1AllocationAllocationAllocationIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1AllocationAllocationAllocationIdQueryOptions(
        allocationId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns the allocation status for the given allocationId
 */
export const useGetV1AllocationStatusAllocationIdHook = () => {
    const getV1AllocationStatusAllocationId =
        useCustomAxios<AllocationStatusResponse>();

    return useCallback(
        (allocationId: number) => {
            return getV1AllocationStatusAllocationId({
                url: `/v1/Allocation/status/${allocationId}`,
                method: 'GET',
            });
        },
        [getV1AllocationStatusAllocationId]
    );
};

export const getGetV1AllocationStatusAllocationIdQueryKey = (
    allocationId: number
) => {
    return [`/v1/Allocation/status/${allocationId}`] as const;
};

export const useGetV1AllocationStatusAllocationIdQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>>
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1AllocationStatusAllocationIdQueryKey(allocationId);

    const getV1AllocationStatusAllocationId =
        useGetV1AllocationStatusAllocationIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>
            >
        >
    > = () => getV1AllocationStatusAllocationId(allocationId);

    return {
        queryKey,
        queryFn,
        enabled: !!allocationId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1AllocationStatusAllocationIdQueryResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>>
    >
>;
export type GetV1AllocationStatusAllocationIdQueryError = ErrorType<void>;

/**
 * @summary Returns the allocation status for the given allocationId
 */
export const useGetV1AllocationStatusAllocationId = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>>
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1AllocationStatusAllocationIdHook>
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1AllocationStatusAllocationIdQueryOptions(
        allocationId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Attempts to retrieve the pre-optimised allocation data for a given allocationId
 */
export const useGetV1AllocationPreoptimisedAllocationIdHook = () => {
    const getV1AllocationPreoptimisedAllocationId =
        useCustomAxios<AllocationData>();

    return useCallback(
        (allocationId: number) => {
            return getV1AllocationPreoptimisedAllocationId({
                url: `/v1/Allocation/preoptimised/${allocationId}`,
                method: 'GET',
            });
        },
        [getV1AllocationPreoptimisedAllocationId]
    );
};

export const getGetV1AllocationPreoptimisedAllocationIdQueryKey = (
    allocationId: number
) => {
    return [`/v1/Allocation/preoptimised/${allocationId}`] as const;
};

export const useGetV1AllocationPreoptimisedAllocationIdQueryOptions = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationPreoptimisedAllocationIdHook>
        >
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1AllocationPreoptimisedAllocationIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1AllocationPreoptimisedAllocationIdQueryKey(allocationId);

    const getV1AllocationPreoptimisedAllocationId =
        useGetV1AllocationPreoptimisedAllocationIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1AllocationPreoptimisedAllocationIdHook
                >
            >
        >
    > = () => getV1AllocationPreoptimisedAllocationId(allocationId);

    return {
        queryKey,
        queryFn,
        enabled: !!allocationId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1AllocationPreoptimisedAllocationIdHook
                >
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1AllocationPreoptimisedAllocationIdQueryResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationPreoptimisedAllocationIdHook>
        >
    >
>;
export type GetV1AllocationPreoptimisedAllocationIdQueryError = ErrorType<void>;

/**
 * @summary Attempts to retrieve the pre-optimised allocation data for a given allocationId
 */
export const useGetV1AllocationPreoptimisedAllocationId = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationPreoptimisedAllocationIdHook>
        >
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1AllocationPreoptimisedAllocationIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1AllocationPreoptimisedAllocationIdQueryOptions(
        allocationId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Attempts to retrieve the optimised allocation data for a given allocationId
 */
export const useGetV1AllocationOptimisedAllocationIdHook = () => {
    const getV1AllocationOptimisedAllocationId =
        useCustomAxios<OptimisedAllocationResponse>();

    return useCallback(
        (allocationId: number) => {
            return getV1AllocationOptimisedAllocationId({
                url: `/v1/Allocation/optimised/${allocationId}`,
                method: 'GET',
            });
        },
        [getV1AllocationOptimisedAllocationId]
    );
};

export const getGetV1AllocationOptimisedAllocationIdQueryKey = (
    allocationId: number
) => {
    return [`/v1/Allocation/optimised/${allocationId}`] as const;
};

export const useGetV1AllocationOptimisedAllocationIdQueryOptions = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationOptimisedAllocationIdHook>
        >
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1AllocationOptimisedAllocationIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1AllocationOptimisedAllocationIdQueryKey(allocationId);

    const getV1AllocationOptimisedAllocationId =
        useGetV1AllocationOptimisedAllocationIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1AllocationOptimisedAllocationIdHook>
            >
        >
    > = () => getV1AllocationOptimisedAllocationId(allocationId);

    return {
        queryKey,
        queryFn,
        enabled: !!allocationId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useGetV1AllocationOptimisedAllocationIdHook>
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1AllocationOptimisedAllocationIdQueryResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationOptimisedAllocationIdHook>
        >
    >
>;
export type GetV1AllocationOptimisedAllocationIdQueryError = ErrorType<void>;

/**
 * @summary Attempts to retrieve the optimised allocation data for a given allocationId
 */
export const useGetV1AllocationOptimisedAllocationId = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1AllocationOptimisedAllocationIdHook>
        >
    >,
    TError = ErrorType<void>
>(
    allocationId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1AllocationOptimisedAllocationIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1AllocationOptimisedAllocationIdQueryOptions(
        allocationId,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePostV1AllocationUploadHook = () => {
    const postV1AllocationUpload = useCustomAxios<FileUploadResponse>();

    return useCallback(
        (postV1AllocationUploadBody: PostV1AllocationUploadBody) => {
            const formData = new FormData();
            if (postV1AllocationUploadBody.file !== undefined) {
                formData.append('file', postV1AllocationUploadBody.file);
            }

            return postV1AllocationUpload({
                url: `/v1/Allocation/Upload`,
                method: 'POST',
                headers: { 'Content-Type': 'multipart/form-data' },
                data: formData,
            });
        },
        [postV1AllocationUpload]
    );
};

export const usePostV1AllocationUploadMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1AllocationUploadHook>>>,
        TError,
        { data: PostV1AllocationUploadBody },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1AllocationUploadHook>>>,
    TError,
    { data: PostV1AllocationUploadBody },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1AllocationUpload = usePostV1AllocationUploadHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1AllocationUploadHook>>>,
        { data: PostV1AllocationUploadBody }
    > = (props) => {
        const { data } = props ?? {};

        return postV1AllocationUpload(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1AllocationUploadMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1AllocationUploadHook>>>
>;
export type PostV1AllocationUploadMutationBody = PostV1AllocationUploadBody;
export type PostV1AllocationUploadMutationError = ErrorType<void>;

export const usePostV1AllocationUpload = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1AllocationUploadHook>>>,
        TError,
        { data: PostV1AllocationUploadBody },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1AllocationUploadHook>>>,
    TError,
    { data: PostV1AllocationUploadBody },
    TContext
> => {
    const mutationOptions = usePostV1AllocationUploadMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePostV1AllocationUploadAllocationIdHook = () => {
    const postV1AllocationUploadAllocationId =
        useCustomAxios<FileUploadResponse>();

    return useCallback(
        (allocationId: number) => {
            return postV1AllocationUploadAllocationId({
                url: `/v1/Allocation/Upload/${allocationId}`,
                method: 'POST',
            });
        },
        [postV1AllocationUploadAllocationId]
    );
};

export const usePostV1AllocationUploadAllocationIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePostV1AllocationUploadAllocationIdHook>
            >
        >,
        TError,
        { allocationId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1AllocationUploadAllocationIdHook>>
    >,
    TError,
    { allocationId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1AllocationUploadAllocationId =
        usePostV1AllocationUploadAllocationIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof usePostV1AllocationUploadAllocationIdHook>
            >
        >,
        { allocationId: number }
    > = (props) => {
        const { allocationId } = props ?? {};

        return postV1AllocationUploadAllocationId(allocationId);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1AllocationUploadAllocationIdMutationResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1AllocationUploadAllocationIdHook>>
    >
>;

export type PostV1AllocationUploadAllocationIdMutationError = ErrorType<void>;

export const usePostV1AllocationUploadAllocationId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePostV1AllocationUploadAllocationIdHook>
            >
        >,
        TError,
        { allocationId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1AllocationUploadAllocationIdHook>>
    >,
    TError,
    { allocationId: number },
    TContext
> => {
    const mutationOptions =
        usePostV1AllocationUploadAllocationIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePostV1AllocationRunAllocationIdHook = () => {
    const postV1AllocationRunAllocationId = useCustomAxios<void>();

    return useCallback(
        (allocationId: number, runAllocationRequest: RunAllocationRequest) => {
            return postV1AllocationRunAllocationId({
                url: `/v1/Allocation/Run/${allocationId}`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: runAllocationRequest,
            });
        },
        [postV1AllocationRunAllocationId]
    );
};

export const usePostV1AllocationRunAllocationIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePostV1AllocationRunAllocationIdHook>
            >
        >,
        TError,
        { allocationId: number; data: RunAllocationRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1AllocationRunAllocationIdHook>>
    >,
    TError,
    { allocationId: number; data: RunAllocationRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1AllocationRunAllocationId =
        usePostV1AllocationRunAllocationIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof usePostV1AllocationRunAllocationIdHook>
            >
        >,
        { allocationId: number; data: RunAllocationRequest }
    > = (props) => {
        const { allocationId, data } = props ?? {};

        return postV1AllocationRunAllocationId(allocationId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1AllocationRunAllocationIdMutationResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1AllocationRunAllocationIdHook>>
    >
>;
export type PostV1AllocationRunAllocationIdMutationBody = RunAllocationRequest;
export type PostV1AllocationRunAllocationIdMutationError = ErrorType<void>;

export const usePostV1AllocationRunAllocationId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePostV1AllocationRunAllocationIdHook>
            >
        >,
        TError,
        { allocationId: number; data: RunAllocationRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1AllocationRunAllocationIdHook>>
    >,
    TError,
    { allocationId: number; data: RunAllocationRequest },
    TContext
> => {
    const mutationOptions =
        usePostV1AllocationRunAllocationIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1AllocationGetAllocationsHook = () => {
    const getV1AllocationGetAllocations =
        useCustomAxios<AllocationDetailResponse>();

    return useCallback(
        (params?: GetV1AllocationGetAllocationsParams) => {
            return getV1AllocationGetAllocations({
                url: `/v1/Allocation/GetAllocations`,
                method: 'GET',
                params,
            });
        },
        [getV1AllocationGetAllocations]
    );
};

export const getGetV1AllocationGetAllocationsQueryKey = (
    params?: GetV1AllocationGetAllocationsParams
) => {
    return [
        `/v1/Allocation/GetAllocations`,
        ...(params ? [params] : []),
    ] as const;
};

export const useGetV1AllocationGetAllocationsQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1AllocationGetAllocationsHook>>
    >,
    TError = ErrorType<void>
>(
    params?: GetV1AllocationGetAllocationsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1AllocationGetAllocationsHook>
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1AllocationGetAllocationsQueryKey(params);

    const getV1AllocationGetAllocations =
        useGetV1AllocationGetAllocationsHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1AllocationGetAllocationsHook>>
        >
    > = () => getV1AllocationGetAllocations(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1AllocationGetAllocationsHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1AllocationGetAllocationsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1AllocationGetAllocationsHook>>>
>;
export type GetV1AllocationGetAllocationsQueryError = ErrorType<void>;

export const useGetV1AllocationGetAllocations = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1AllocationGetAllocationsHook>>
    >,
    TError = ErrorType<void>
>(
    params?: GetV1AllocationGetAllocationsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1AllocationGetAllocationsHook>
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1AllocationGetAllocationsQueryOptions(
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useDeleteV1AllocationDeleteAllocationsHook = () => {
    const deleteV1AllocationDeleteAllocations = useCustomAxios<void>();

    return useCallback(
        (deleteV1AllocationDeleteAllocationsBody: number[]) => {
            return deleteV1AllocationDeleteAllocations({
                url: `/v1/Allocation/DeleteAllocations`,
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
                data: deleteV1AllocationDeleteAllocationsBody,
            });
        },
        [deleteV1AllocationDeleteAllocations]
    );
};

export const useDeleteV1AllocationDeleteAllocationsMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1AllocationDeleteAllocationsHook>
            >
        >,
        TError,
        { data: number[] },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof useDeleteV1AllocationDeleteAllocationsHook>
        >
    >,
    TError,
    { data: number[] },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1AllocationDeleteAllocations =
        useDeleteV1AllocationDeleteAllocationsHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1AllocationDeleteAllocationsHook>
            >
        >,
        { data: number[] }
    > = (props) => {
        const { data } = props ?? {};

        return deleteV1AllocationDeleteAllocations(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1AllocationDeleteAllocationsMutationResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof useDeleteV1AllocationDeleteAllocationsHook>
        >
    >
>;
export type DeleteV1AllocationDeleteAllocationsMutationBody = number[];
export type DeleteV1AllocationDeleteAllocationsMutationError = ErrorType<void>;

export const useDeleteV1AllocationDeleteAllocations = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1AllocationDeleteAllocationsHook>
            >
        >,
        TError,
        { data: number[] },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof useDeleteV1AllocationDeleteAllocationsHook>
        >
    >,
    TError,
    { data: number[] },
    TContext
> => {
    const mutationOptions =
        useDeleteV1AllocationDeleteAllocationsMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1AllocationAllocationUpdateNameAllocationIdHook = () => {
    const putV1AllocationAllocationUpdateNameAllocationId =
        useCustomAxios<AllocationResponse>();

    return useCallback(
        (
            allocationId: number,
            putV1AllocationAllocationUpdateNameAllocationIdBody: string
        ) => {
            return putV1AllocationAllocationUpdateNameAllocationId({
                url: `/v1/Allocation/Allocation/UpdateName/${allocationId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: putV1AllocationAllocationUpdateNameAllocationIdBody,
            });
        },
        [putV1AllocationAllocationUpdateNameAllocationId]
    );
};

export const usePutV1AllocationAllocationUpdateNameAllocationIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateNameAllocationIdHook
                    >
                >
            >,
            TError,
            { allocationId: number; data: string },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateNameAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: string },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1AllocationAllocationUpdateNameAllocationId =
            usePutV1AllocationAllocationUpdateNameAllocationIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateNameAllocationIdHook
                    >
                >
            >,
            { allocationId: number; data: string }
        > = (props) => {
            const { allocationId, data } = props ?? {};

            return putV1AllocationAllocationUpdateNameAllocationId(
                allocationId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1AllocationAllocationUpdateNameAllocationIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateNameAllocationIdHook
                >
            >
        >
    >;
export type PutV1AllocationAllocationUpdateNameAllocationIdMutationBody =
    string;
export type PutV1AllocationAllocationUpdateNameAllocationIdMutationError =
    ErrorType<void>;

export const usePutV1AllocationAllocationUpdateNameAllocationId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateNameAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: string },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1AllocationAllocationUpdateNameAllocationIdHook
            >
        >
    >,
    TError,
    { allocationId: number; data: string },
    TContext
> => {
    const mutationOptions =
        usePutV1AllocationAllocationUpdateNameAllocationIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const usePutV1AllocationAllocationUpdateNotesAllocationIdHook = () => {
    const putV1AllocationAllocationUpdateNotesAllocationId =
        useCustomAxios<AllocationResponse>();

    return useCallback(
        (
            allocationId: number,
            putV1AllocationAllocationUpdateNotesAllocationIdBody: string
        ) => {
            return putV1AllocationAllocationUpdateNotesAllocationId({
                url: `/v1/Allocation/Allocation/UpdateNotes/${allocationId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: putV1AllocationAllocationUpdateNotesAllocationIdBody,
            });
        },
        [putV1AllocationAllocationUpdateNotesAllocationId]
    );
};

export const usePutV1AllocationAllocationUpdateNotesAllocationIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateNotesAllocationIdHook
                    >
                >
            >,
            TError,
            { allocationId: number; data: string },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateNotesAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: string },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1AllocationAllocationUpdateNotesAllocationId =
            usePutV1AllocationAllocationUpdateNotesAllocationIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateNotesAllocationIdHook
                    >
                >
            >,
            { allocationId: number; data: string }
        > = (props) => {
            const { allocationId, data } = props ?? {};

            return putV1AllocationAllocationUpdateNotesAllocationId(
                allocationId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1AllocationAllocationUpdateNotesAllocationIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateNotesAllocationIdHook
                >
            >
        >
    >;
export type PutV1AllocationAllocationUpdateNotesAllocationIdMutationBody =
    string;
export type PutV1AllocationAllocationUpdateNotesAllocationIdMutationError =
    ErrorType<void>;

export const usePutV1AllocationAllocationUpdateNotesAllocationId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateNotesAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: string },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1AllocationAllocationUpdateNotesAllocationIdHook
            >
        >
    >,
    TError,
    { allocationId: number; data: string },
    TContext
> => {
    const mutationOptions =
        usePutV1AllocationAllocationUpdateNotesAllocationIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook =
    () => {
        const putV1AllocationAllocationUpdatePreAllocationNotesAllocationId =
            useCustomAxios<AllocationDataResponse>();

        return useCallback(
            (allocationId: number, updateNotesRequest: UpdateNotesRequest) => {
                return putV1AllocationAllocationUpdatePreAllocationNotesAllocationId(
                    {
                        url: `/v1/Allocation/Allocation/UpdatePreAllocationNotes/${allocationId}`,
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        data: updateNotesRequest,
                    }
                );
            },
            [putV1AllocationAllocationUpdatePreAllocationNotesAllocationId]
        );
    };

export const usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook
                    >
                >
            >,
            TError,
            { allocationId: number; data: UpdateNotesRequest },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: UpdateNotesRequest },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1AllocationAllocationUpdatePreAllocationNotesAllocationId =
            usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook
                    >
                >
            >,
            { allocationId: number; data: UpdateNotesRequest }
        > = (props) => {
            const { allocationId, data } = props ?? {};

            return putV1AllocationAllocationUpdatePreAllocationNotesAllocationId(
                allocationId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook
                >
            >
        >
    >;
export type PutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdMutationBody =
    UpdateNotesRequest;
export type PutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdMutationError =
    ErrorType<void>;

export const usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationId =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook
                    >
                >
            >,
            TError,
            { allocationId: number; data: UpdateNotesRequest },
            TContext
        >;
    }): UseMutationResult<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: UpdateNotesRequest },
        TContext
    > => {
        const mutationOptions =
            usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationIdMutationOptions(
                options
            );

        return useMutation(mutationOptions);
    };

export const usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook =
    () => {
        const putV1AllocationAllocationUpdateReviewAllocationNotesAllocationId =
            useCustomAxios<OptimisedAllocationDataResponse>();

        return useCallback(
            (allocationId: number, updateNotesRequest: UpdateNotesRequest) => {
                return putV1AllocationAllocationUpdateReviewAllocationNotesAllocationId(
                    {
                        url: `/v1/Allocation/Allocation/UpdateReviewAllocationNotes/${allocationId}`,
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        data: updateNotesRequest,
                    }
                );
            },
            [putV1AllocationAllocationUpdateReviewAllocationNotesAllocationId]
        );
    };

export const usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook
                    >
                >
            >,
            TError,
            { allocationId: number; data: UpdateNotesRequest },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: UpdateNotesRequest },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1AllocationAllocationUpdateReviewAllocationNotesAllocationId =
            usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook
                    >
                >
            >,
            { allocationId: number; data: UpdateNotesRequest }
        > = (props) => {
            const { allocationId, data } = props ?? {};

            return putV1AllocationAllocationUpdateReviewAllocationNotesAllocationId(
                allocationId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook
                >
            >
        >
    >;
export type PutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdMutationBody =
    UpdateNotesRequest;
export type PutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdMutationError =
    ErrorType<void>;

export const usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationId =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook
                    >
                >
            >,
            TError,
            { allocationId: number; data: UpdateNotesRequest },
            TContext
        >;
    }): UseMutationResult<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdHook
                >
            >
        >,
        TError,
        { allocationId: number; data: UpdateNotesRequest },
        TContext
    > => {
        const mutationOptions =
            usePutV1AllocationAllocationUpdateReviewAllocationNotesAllocationIdMutationOptions(
                options
            );

        return useMutation(mutationOptions);
    };

export const usePostV1AuthLogoutHook = () => {
    const postV1AuthLogout = useCustomAxios<void>();

    return useCallback(() => {
        return postV1AuthLogout({ url: `/v1/Auth/Logout`, method: 'POST' });
    }, [postV1AuthLogout]);
};

export const usePostV1AuthLogoutMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
        TError,
        void,
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
    TError,
    void,
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1AuthLogout = usePostV1AuthLogoutHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
        void
    > = () => {
        return postV1AuthLogout();
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1AuthLogoutMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>
>;

export type PostV1AuthLogoutMutationError = ErrorType<void>;

export const usePostV1AuthLogout = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
        TError,
        void,
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1AuthLogoutHook>>>,
    TError,
    void,
    TContext
> => {
    const mutationOptions = usePostV1AuthLogoutMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePostV1BusinessRuleHook = () => {
    const postV1BusinessRule = useCustomAxios<BusinessRuleResponse>();

    return useCallback(
        (createBusinessRuleRequest: CreateBusinessRuleRequest) => {
            return postV1BusinessRule({
                url: `/v1/BusinessRule`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: createBusinessRuleRequest,
            });
        },
        [postV1BusinessRule]
    );
};

export const usePostV1BusinessRuleMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1BusinessRuleHook>>>,
        TError,
        { data: CreateBusinessRuleRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1BusinessRuleHook>>>,
    TError,
    { data: CreateBusinessRuleRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1BusinessRule = usePostV1BusinessRuleHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1BusinessRuleHook>>>,
        { data: CreateBusinessRuleRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1BusinessRule(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1BusinessRuleMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1BusinessRuleHook>>>
>;
export type PostV1BusinessRuleMutationBody = CreateBusinessRuleRequest;
export type PostV1BusinessRuleMutationError = ErrorType<void>;

export const usePostV1BusinessRule = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1BusinessRuleHook>>>,
        TError,
        { data: CreateBusinessRuleRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1BusinessRuleHook>>>,
    TError,
    { data: CreateBusinessRuleRequest },
    TContext
> => {
    const mutationOptions = usePostV1BusinessRuleMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1BusinessRuleHook = () => {
    const getV1BusinessRule = useCustomAxios<PaginatedBusinessRuleResponse>();

    return useCallback(
        (params?: GetV1BusinessRuleParams) => {
            return getV1BusinessRule({
                url: `/v1/BusinessRule`,
                method: 'GET',
                params,
            });
        },
        [getV1BusinessRule]
    );
};

export const getGetV1BusinessRuleQueryKey = (
    params?: GetV1BusinessRuleParams
) => {
    return [`/v1/BusinessRule`, ...(params ? [params] : [])] as const;
};

export const useGetV1BusinessRuleQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1BusinessRuleParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1BusinessRuleQueryKey(params);

    const getV1BusinessRule = useGetV1BusinessRuleHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>
    > = () => getV1BusinessRule(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1BusinessRuleQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>
>;
export type GetV1BusinessRuleQueryError = ErrorType<void>;

export const useGetV1BusinessRule = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1BusinessRuleParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1BusinessRuleHook>>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1BusinessRuleQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePutV1BusinessRuleBusinessRuleIdHook = () => {
    const putV1BusinessRuleBusinessRuleId =
        useCustomAxios<BusinessRuleResponse>();

    return useCallback(
        (
            businessRuleId: number,
            editBusinessRuleRequest: EditBusinessRuleRequest
        ) => {
            return putV1BusinessRuleBusinessRuleId({
                url: `/v1/BusinessRule/${businessRuleId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editBusinessRuleRequest,
            });
        },
        [putV1BusinessRuleBusinessRuleId]
    );
};

export const usePutV1BusinessRuleBusinessRuleIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1BusinessRuleBusinessRuleIdHook>
            >
        >,
        TError,
        { businessRuleId: number; data: EditBusinessRuleRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1BusinessRuleBusinessRuleIdHook>>
    >,
    TError,
    { businessRuleId: number; data: EditBusinessRuleRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1BusinessRuleBusinessRuleId =
        usePutV1BusinessRuleBusinessRuleIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1BusinessRuleBusinessRuleIdHook>
            >
        >,
        { businessRuleId: number; data: EditBusinessRuleRequest }
    > = (props) => {
        const { businessRuleId, data } = props ?? {};

        return putV1BusinessRuleBusinessRuleId(businessRuleId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1BusinessRuleBusinessRuleIdMutationResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1BusinessRuleBusinessRuleIdHook>>
    >
>;
export type PutV1BusinessRuleBusinessRuleIdMutationBody =
    EditBusinessRuleRequest;
export type PutV1BusinessRuleBusinessRuleIdMutationError = ErrorType<void>;

export const usePutV1BusinessRuleBusinessRuleId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof usePutV1BusinessRuleBusinessRuleIdHook>
            >
        >,
        TError,
        { businessRuleId: number; data: EditBusinessRuleRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1BusinessRuleBusinessRuleIdHook>>
    >,
    TError,
    { businessRuleId: number; data: EditBusinessRuleRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1BusinessRuleBusinessRuleIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useDeleteV1BusinessRuleBusinessRuleIdHook = () => {
    const deleteV1BusinessRuleBusinessRuleId = useCustomAxios<void>();

    return useCallback(
        (businessRuleId: number) => {
            return deleteV1BusinessRuleBusinessRuleId({
                url: `/v1/BusinessRule/${businessRuleId}`,
                method: 'DELETE',
            });
        },
        [deleteV1BusinessRuleBusinessRuleId]
    );
};

export const useDeleteV1BusinessRuleBusinessRuleIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1BusinessRuleBusinessRuleIdHook>
            >
        >,
        TError,
        { businessRuleId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof useDeleteV1BusinessRuleBusinessRuleIdHook>>
    >,
    TError,
    { businessRuleId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1BusinessRuleBusinessRuleId =
        useDeleteV1BusinessRuleBusinessRuleIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1BusinessRuleBusinessRuleIdHook>
            >
        >,
        { businessRuleId: number }
    > = (props) => {
        const { businessRuleId } = props ?? {};

        return deleteV1BusinessRuleBusinessRuleId(businessRuleId);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1BusinessRuleBusinessRuleIdMutationResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof useDeleteV1BusinessRuleBusinessRuleIdHook>>
    >
>;

export type DeleteV1BusinessRuleBusinessRuleIdMutationError = ErrorType<void>;

export const useDeleteV1BusinessRuleBusinessRuleId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<typeof useDeleteV1BusinessRuleBusinessRuleIdHook>
            >
        >,
        TError,
        { businessRuleId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof useDeleteV1BusinessRuleBusinessRuleIdHook>>
    >,
    TError,
    { businessRuleId: number },
    TContext
> => {
    const mutationOptions =
        useDeleteV1BusinessRuleBusinessRuleIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1BusinessUnitHook = () => {
    const getV1BusinessUnit = useCustomAxios<BusinessUnitResponse>();

    return useCallback(() => {
        return getV1BusinessUnit({ url: `/v1/BusinessUnit`, method: 'GET' });
    }, [getV1BusinessUnit]);
};

export const getGetV1BusinessUnitQueryKey = () => {
    return [`/v1/BusinessUnit`] as const;
};

export const useGetV1BusinessUnitQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1BusinessUnitQueryKey();

    const getV1BusinessUnit = useGetV1BusinessUnitHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>
    > = () => getV1BusinessUnit();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1BusinessUnitQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>
>;
export type GetV1BusinessUnitQueryError = ErrorType<void>;

export const useGetV1BusinessUnit = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1BusinessUnitQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1BusinessUnitContentHook = () => {
    const getV1BusinessUnitContent =
        useCustomAxios<BusinessUnitContentResponse>();

    return useCallback(() => {
        return getV1BusinessUnitContent({
            url: `/v1/BusinessUnit/content`,
            method: 'GET',
        });
    }, [getV1BusinessUnitContent]);
};

export const getGetV1BusinessUnitContentQueryKey = () => {
    return [`/v1/BusinessUnit/content`] as const;
};

export const useGetV1BusinessUnitContentQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1BusinessUnitContentQueryKey();

    const getV1BusinessUnitContent = useGetV1BusinessUnitContentHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>>
    > = () => getV1BusinessUnitContent();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1BusinessUnitContentQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>>
>;
export type GetV1BusinessUnitContentQueryError = ErrorType<void>;

export const useGetV1BusinessUnitContent = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitContentHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1BusinessUnitContentQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns the career coach capacity for the given careerCoachCapacityId
 */
export const useGetV1CareerCoachCapacityCareerCoachCapacityIdHook = () => {
    const getV1CareerCoachCapacityCareerCoachCapacityId =
        useCustomAxios<CareerCoachCapacityResponse>();

    return useCallback(
        (careerCoachCapacityId: number) => {
            return getV1CareerCoachCapacityCareerCoachCapacityId({
                url: `/v1/CareerCoachCapacity/${careerCoachCapacityId}`,
                method: 'GET',
            });
        },
        [getV1CareerCoachCapacityCareerCoachCapacityId]
    );
};

export const getGetV1CareerCoachCapacityCareerCoachCapacityIdQueryKey = (
    careerCoachCapacityId: number
) => {
    return [`/v1/CareerCoachCapacity/${careerCoachCapacityId}`] as const;
};

export const useGetV1CareerCoachCapacityCareerCoachCapacityIdQueryOptions = <
    TData = Awaited<
        ReturnType<
            ReturnType<
                typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
            >
        >
    >,
    TError = ErrorType<void>
>(
    careerCoachCapacityId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1CareerCoachCapacityCareerCoachCapacityIdQueryKey(
            careerCoachCapacityId
        );

    const getV1CareerCoachCapacityCareerCoachCapacityId =
        useGetV1CareerCoachCapacityCareerCoachCapacityIdHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >
    > = () =>
        getV1CareerCoachCapacityCareerCoachCapacityId(careerCoachCapacityId);

    return {
        queryKey,
        queryFn,
        enabled: !!careerCoachCapacityId,
        ...queryOptions,
    } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1CareerCoachCapacityCareerCoachCapacityIdQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >
    >;
export type GetV1CareerCoachCapacityCareerCoachCapacityIdQueryError =
    ErrorType<void>;

/**
 * @summary Returns the career coach capacity for the given careerCoachCapacityId
 */
export const useGetV1CareerCoachCapacityCareerCoachCapacityId = <
    TData = Awaited<
        ReturnType<
            ReturnType<
                typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
            >
        >
    >,
    TError = ErrorType<void>
>(
    careerCoachCapacityId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1CareerCoachCapacityCareerCoachCapacityIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1CareerCoachCapacityCareerCoachCapacityIdQueryOptions(
            careerCoachCapacityId,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Edit career coach capacity
 */
export const usePutV1CareerCoachCapacityCareerCoachCapacityIdHook = () => {
    const putV1CareerCoachCapacityCareerCoachCapacityId =
        useCustomAxios<CareerCoachCapacityResponse>();

    return useCallback(
        (
            careerCoachCapacityId: number,
            editCareerCoachCapacityRequest: EditCareerCoachCapacityRequest
        ) => {
            return putV1CareerCoachCapacityCareerCoachCapacityId({
                url: `/v1/CareerCoachCapacity/${careerCoachCapacityId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editCareerCoachCapacityRequest,
            });
        },
        [putV1CareerCoachCapacityCareerCoachCapacityId]
    );
};

export const usePutV1CareerCoachCapacityCareerCoachCapacityIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >,
        TError,
        { careerCoachCapacityId: number; data: EditCareerCoachCapacityRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1CareerCoachCapacityCareerCoachCapacityIdHook
            >
        >
    >,
    TError,
    { careerCoachCapacityId: number; data: EditCareerCoachCapacityRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1CareerCoachCapacityCareerCoachCapacityId =
        usePutV1CareerCoachCapacityCareerCoachCapacityIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >,
        { careerCoachCapacityId: number; data: EditCareerCoachCapacityRequest }
    > = (props) => {
        const { careerCoachCapacityId, data } = props ?? {};

        return putV1CareerCoachCapacityCareerCoachCapacityId(
            careerCoachCapacityId,
            data
        );
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1CareerCoachCapacityCareerCoachCapacityIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >
    >;
export type PutV1CareerCoachCapacityCareerCoachCapacityIdMutationBody =
    EditCareerCoachCapacityRequest;
export type PutV1CareerCoachCapacityCareerCoachCapacityIdMutationError =
    ErrorType<void>;

/**
 * @summary Edit career coach capacity
 */
export const usePutV1CareerCoachCapacityCareerCoachCapacityId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1CareerCoachCapacityCareerCoachCapacityIdHook
                >
            >
        >,
        TError,
        { careerCoachCapacityId: number; data: EditCareerCoachCapacityRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1CareerCoachCapacityCareerCoachCapacityIdHook
            >
        >
    >,
    TError,
    { careerCoachCapacityId: number; data: EditCareerCoachCapacityRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1CareerCoachCapacityCareerCoachCapacityIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const useGetV1ContactPersonHook = () => {
    const getV1ContactPerson = useCustomAxios<ContactPersonResponse[]>();

    return useCallback(() => {
        return getV1ContactPerson({ url: `/v1/ContactPerson`, method: 'GET' });
    }, [getV1ContactPerson]);
};

export const getGetV1ContactPersonQueryKey = () => {
    return [`/v1/ContactPerson`] as const;
};

export const useGetV1ContactPersonQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1ContactPersonQueryKey();

    const getV1ContactPerson = useGetV1ContactPersonHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>
    > = () => getV1ContactPerson();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1ContactPersonQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>
>;
export type GetV1ContactPersonQueryError = ErrorType<void>;

export const useGetV1ContactPerson = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1ContactPersonQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1ContactPersonApplicationHook = () => {
    const getV1ContactPersonApplication =
        useCustomAxios<ContactPersonResponse[]>();

    return useCallback(() => {
        return getV1ContactPersonApplication({
            url: `/v1/ContactPerson/Application`,
            method: 'GET',
        });
    }, [getV1ContactPersonApplication]);
};

export const getGetV1ContactPersonApplicationQueryKey = () => {
    return [`/v1/ContactPerson/Application`] as const;
};

export const useGetV1ContactPersonApplicationQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
    >,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1ContactPersonApplicationQueryKey();

    const getV1ContactPersonApplication =
        useGetV1ContactPersonApplicationHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >
    > = () => getV1ContactPersonApplication();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1ContactPersonApplicationQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>>
>;
export type GetV1ContactPersonApplicationQueryError = ErrorType<unknown>;

export const useGetV1ContactPersonApplication = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
    >,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonApplicationHook>>
        >,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1ContactPersonApplicationQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1DashboardEngagementsHook = () => {
    const getV1DashboardEngagements = useCustomAxios<EngagementsResponse>();

    return useCallback(() => {
        return getV1DashboardEngagements({
            url: `/v1/Dashboard/Engagements`,
            method: 'GET',
        });
    }, [getV1DashboardEngagements]);
};

export const getGetV1DashboardEngagementsQueryKey = () => {
    return [`/v1/Dashboard/Engagements`] as const;
};

export const useGetV1DashboardEngagementsQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>
        >,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1DashboardEngagementsQueryKey();

    const getV1DashboardEngagements = useGetV1DashboardEngagementsHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>>
    > = () => getV1DashboardEngagements();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1DashboardEngagementsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>>
>;
export type GetV1DashboardEngagementsQueryError = ErrorType<void>;

export const useGetV1DashboardEngagements = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsHook>>
        >,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1DashboardEngagementsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook = () => {
    const getV1DashboardEngagementsEngagementIdAssetsAssetId =
        useCustomAxios<AssetsResponse>();

    return useCallback(
        (engagementId: string, assetId: string) => {
            return getV1DashboardEngagementsEngagementIdAssetsAssetId({
                url: `/v1/Dashboard/Engagements/${engagementId}/Assets/${assetId}`,
                method: 'GET',
            });
        },
        [getV1DashboardEngagementsEngagementIdAssetsAssetId]
    );
};

export const getGetV1DashboardEngagementsEngagementIdAssetsAssetIdQueryKey = (
    engagementId: string,
    assetId: string
) => {
    return [
        `/v1/Dashboard/Engagements/${engagementId}/Assets/${assetId}`,
    ] as const;
};

export const useGetV1DashboardEngagementsEngagementIdAssetsAssetIdQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
                >
            >
        >,
        TError = ErrorType<void>
    >(
        engagementId: string,
        assetId: string,
        options?: {
            query?: UseQueryOptions<
                Awaited<
                    ReturnType<
                        ReturnType<
                            typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
                        >
                    >
                >,
                TError,
                TData
            >;
        }
    ) => {
        const { query: queryOptions } = options ?? {};

        const queryKey =
            queryOptions?.queryKey ??
            getGetV1DashboardEngagementsEngagementIdAssetsAssetIdQueryKey(
                engagementId,
                assetId
            );

        const getV1DashboardEngagementsEngagementIdAssetsAssetId =
            useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook();

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
                    >
                >
            >
        > = () =>
            getV1DashboardEngagementsEngagementIdAssetsAssetId(
                engagementId,
                assetId
            );

        return {
            queryKey,
            queryFn,
            enabled: !!(engagementId && assetId),
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
                    >
                >
            >,
            TError,
            TData
        > & { queryKey: QueryKey };
    };

export type GetV1DashboardEngagementsEngagementIdAssetsAssetIdQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
                >
            >
        >
    >;
export type GetV1DashboardEngagementsEngagementIdAssetsAssetIdQueryError =
    ErrorType<void>;

export const useGetV1DashboardEngagementsEngagementIdAssetsAssetId = <
    TData = Awaited<
        ReturnType<
            ReturnType<
                typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
            >
        >
    >,
    TError = ErrorType<void>
>(
    engagementId: string,
    assetId: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1DashboardEngagementsEngagementIdAssetsAssetIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1DashboardEngagementsEngagementIdAssetsAssetIdQueryOptions(
            engagementId,
            assetId,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1DashboardEngagementsEmbedHook = () => {
    const getV1DashboardEngagementsEmbed = useCustomAxios<EmbedResponse>();

    return useCallback(() => {
        return getV1DashboardEngagementsEmbed({
            url: `/v1/Dashboard/Engagements/Embed`,
            method: 'GET',
        });
    }, [getV1DashboardEngagementsEmbed]);
};

export const getGetV1DashboardEngagementsEmbedQueryKey = () => {
    return [`/v1/Dashboard/Engagements/Embed`] as const;
};

export const useGetV1DashboardEngagementsEmbedQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
        >,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1DashboardEngagementsEmbedQueryKey();

    const getV1DashboardEngagementsEmbed =
        useGetV1DashboardEngagementsEmbedHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
        >
    > = () => getV1DashboardEngagementsEmbed();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1DashboardEngagementsEmbedQueryResult = NonNullable<
    Awaited<
        ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
    >
>;
export type GetV1DashboardEngagementsEmbedQueryError = ErrorType<void>;

export const useGetV1DashboardEngagementsEmbed = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1DashboardEngagementsEmbedHook>>
        >,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1DashboardEngagementsEmbedQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns list of the claims for default authentication schema
 */
export const useGetV1IdentityUserInfoHook = () => {
    const getV1IdentityUserInfo = useCustomAxios<UserInfoResponse>();

    return useCallback(() => {
        return getV1IdentityUserInfo({
            url: `/v1/Identity/UserInfo`,
            method: 'GET',
        });
    }, [getV1IdentityUserInfo]);
};

export const getGetV1IdentityUserInfoQueryKey = () => {
    return [`/v1/Identity/UserInfo`] as const;
};

export const useGetV1IdentityUserInfoQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1IdentityUserInfoQueryKey();

    const getV1IdentityUserInfo = useGetV1IdentityUserInfoHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>
    > = () => getV1IdentityUserInfo();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1IdentityUserInfoQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>
>;
export type GetV1IdentityUserInfoQueryError = ErrorType<void>;

/**
 * @summary Returns list of the claims for default authentication schema
 */
export const useGetV1IdentityUserInfo = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1IdentityUserInfoHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1IdentityUserInfoQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyPrivacyHook = () => {
    const getV1PolicyPrivacy = useCustomAxios<PolicyResponse>();

    return useCallback(() => {
        return getV1PolicyPrivacy({ url: `/v1/Policy/Privacy`, method: 'GET' });
    }, [getV1PolicyPrivacy]);
};

export const getGetV1PolicyPrivacyQueryKey = () => {
    return [`/v1/Policy/Privacy`] as const;
};

export const useGetV1PolicyPrivacyQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1PolicyPrivacyQueryKey();

    const getV1PolicyPrivacy = useGetV1PolicyPrivacyHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>
    > = () => getV1PolicyPrivacy();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyPrivacyQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>
>;
export type GetV1PolicyPrivacyQueryError = ErrorType<void>;

export const useGetV1PolicyPrivacy = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyPrivacyHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyPrivacyQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyCookiesHook = () => {
    const getV1PolicyCookies = useCustomAxios<PolicyResponse>();

    return useCallback(() => {
        return getV1PolicyCookies({ url: `/v1/Policy/Cookies`, method: 'GET' });
    }, [getV1PolicyCookies]);
};

export const getGetV1PolicyCookiesQueryKey = () => {
    return [`/v1/Policy/Cookies`] as const;
};

export const useGetV1PolicyCookiesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1PolicyCookiesQueryKey();

    const getV1PolicyCookies = useGetV1PolicyCookiesHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>
    > = () => getV1PolicyCookies();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyCookiesQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>
>;
export type GetV1PolicyCookiesQueryError = ErrorType<void>;

export const useGetV1PolicyCookies = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyCookiesHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyCookiesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyTermsHook = () => {
    const getV1PolicyTerms = useCustomAxios<PolicyResponse>();

    return useCallback(() => {
        return getV1PolicyTerms({ url: `/v1/Policy/Terms`, method: 'GET' });
    }, [getV1PolicyTerms]);
};

export const getGetV1PolicyTermsQueryKey = () => {
    return [`/v1/Policy/Terms`] as const;
};

export const useGetV1PolicyTermsQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1PolicyTermsQueryKey();

    const getV1PolicyTerms = useGetV1PolicyTermsHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>
    > = () => getV1PolicyTerms();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyTermsQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>
>;
export type GetV1PolicyTermsQueryError = ErrorType<void>;

export const useGetV1PolicyTerms = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyTermsQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1PolicyTermsVersionHook = () => {
    const getV1PolicyTermsVersion = useCustomAxios<PolicyVersionResponse>();

    return useCallback(() => {
        return getV1PolicyTermsVersion({
            url: `/v1/Policy/terms/version`,
            method: 'GET',
        });
    }, [getV1PolicyTermsVersion]);
};

export const getGetV1PolicyTermsVersionQueryKey = () => {
    return [`/v1/Policy/terms/version`] as const;
};

export const useGetV1PolicyTermsVersionQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1PolicyTermsVersionQueryKey();

    const getV1PolicyTermsVersion = useGetV1PolicyTermsVersionHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>
    > = () => getV1PolicyTermsVersion();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1PolicyTermsVersionQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>
>;
export type GetV1PolicyTermsVersionQueryError = ErrorType<void>;

export const useGetV1PolicyTermsVersion = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>
    >,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1PolicyTermsVersionHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1PolicyTermsVersionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1RoleAdminAllHook = () => {
    const getV1RoleAdminAll = useCustomAxios<RoleResponse[]>();

    return useCallback(() => {
        return getV1RoleAdminAll({ url: `/v1/Role/Admin/All`, method: 'GET' });
    }, [getV1RoleAdminAll]);
};

export const getGetV1RoleAdminAllQueryKey = () => {
    return [`/v1/Role/Admin/All`] as const;
};

export const useGetV1RoleAdminAllQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1RoleAdminAllQueryKey();

    const getV1RoleAdminAll = useGetV1RoleAdminAllHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>
    > = () => getV1RoleAdminAll();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1RoleAdminAllQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>
>;
export type GetV1RoleAdminAllQueryError = ErrorType<unknown>;

export const useGetV1RoleAdminAll = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1RoleAdminAllHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1RoleAdminAllQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns the list of total login count and most recent login for a user.
 */
export const useGetV1BusinessMetricsSuperAdminUserLoginsHook = () => {
    const getV1BusinessMetricsSuperAdminUserLogins =
        useCustomAxios<PaginatedUserLoginsResponse>();

    return useCallback(
        (params?: GetV1BusinessMetricsSuperAdminUserLoginsParams) => {
            return getV1BusinessMetricsSuperAdminUserLogins({
                url: `/v1/BusinessMetrics/SuperAdmin/UserLogins`,
                method: 'GET',
                params,
            });
        },
        [getV1BusinessMetricsSuperAdminUserLogins]
    );
};

export const getGetV1BusinessMetricsSuperAdminUserLoginsQueryKey = (
    params?: GetV1BusinessMetricsSuperAdminUserLoginsParams
) => {
    return [
        `/v1/BusinessMetrics/SuperAdmin/UserLogins`,
        ...(params ? [params] : []),
    ] as const;
};

export const useGetV1BusinessMetricsSuperAdminUserLoginsQueryOptions = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook>
        >
    >,
    TError = ErrorType<unknown>
>(
    params?: GetV1BusinessMetricsSuperAdminUserLoginsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1BusinessMetricsSuperAdminUserLoginsQueryKey(params);

    const getV1BusinessMetricsSuperAdminUserLogins =
        useGetV1BusinessMetricsSuperAdminUserLoginsHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                >
            >
        >
    > = () => getV1BusinessMetricsSuperAdminUserLogins(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                >
            >
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1BusinessMetricsSuperAdminUserLoginsQueryResult = NonNullable<
    Awaited<
        ReturnType<
            ReturnType<typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook>
        >
    >
>;
export type GetV1BusinessMetricsSuperAdminUserLoginsQueryError =
    ErrorType<unknown>;

/**
 * @summary Returns the list of total login count and most recent login for a user.
 */
export const useGetV1BusinessMetricsSuperAdminUserLogins = <
    TData = Awaited<
        ReturnType<
            ReturnType<typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook>
        >
    >,
    TError = ErrorType<unknown>
>(
    params?: GetV1BusinessMetricsSuperAdminUserLoginsParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1BusinessMetricsSuperAdminUserLoginsQueryOptions(
            params,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Returns the summary of user logins
 */
export const useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook =
    () => {
        const getV1BusinessMetricsSuperAdminUserLoginsSummaryContext =
            useCustomAxios<UserLoginsSummaryResponse>();

        return useCallback(
            (context: string) => {
                return getV1BusinessMetricsSuperAdminUserLoginsSummaryContext({
                    url: `/v1/BusinessMetrics/SuperAdmin/UserLoginsSummary/${context}`,
                    method: 'GET',
                });
            },
            [getV1BusinessMetricsSuperAdminUserLoginsSummaryContext]
        );
    };

export const getGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryKey =
    (context: string) => {
        return [
            `/v1/BusinessMetrics/SuperAdmin/UserLoginsSummary/${context}`,
        ] as const;
    };

export const useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                >
            >
        >,
        TError = ErrorType<void>
    >(
        context: string,
        options?: {
            query?: UseQueryOptions<
                Awaited<
                    ReturnType<
                        ReturnType<
                            typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                        >
                    >
                >,
                TError,
                TData
            >;
        }
    ) => {
        const { query: queryOptions } = options ?? {};

        const queryKey =
            queryOptions?.queryKey ??
            getGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryKey(
                context
            );

        const getV1BusinessMetricsSuperAdminUserLoginsSummaryContext =
            useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook();

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                    >
                >
            >
        > = () =>
            getV1BusinessMetricsSuperAdminUserLoginsSummaryContext(context);

        return {
            queryKey,
            queryFn,
            enabled: !!context,
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                    >
                >
            >,
            TError,
            TData
        > & { queryKey: QueryKey };
    };

export type GetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                >
            >
        >
    >;
export type GetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryError =
    ErrorType<void>;

/**
 * @summary Returns the summary of user logins
 */
export const useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContext = <
    TData = Awaited<
        ReturnType<
            ReturnType<
                typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
            >
        >
    >,
    TError = ErrorType<void>
>(
    context: string,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1BusinessMetricsSuperAdminUserLoginsSummaryContextQueryOptions(
            context,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const useGetV1BusinessUnitSuperAdminHook = () => {
    const getV1BusinessUnitSuperAdmin =
        useCustomAxios<PaginatedBusinessUnitResponse>();

    return useCallback(
        (params?: GetV1BusinessUnitSuperAdminParams) => {
            return getV1BusinessUnitSuperAdmin({
                url: `/v1/BusinessUnit/SuperAdmin`,
                method: 'GET',
                params,
            });
        },
        [getV1BusinessUnitSuperAdmin]
    );
};

export const getGetV1BusinessUnitSuperAdminQueryKey = (
    params?: GetV1BusinessUnitSuperAdminParams
) => {
    return [
        `/v1/BusinessUnit/SuperAdmin`,
        ...(params ? [params] : []),
    ] as const;
};

export const useGetV1BusinessUnitSuperAdminQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>>
    >,
    TError = ErrorType<void>
>(
    params?: GetV1BusinessUnitSuperAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1BusinessUnitSuperAdminQueryKey(params);

    const getV1BusinessUnitSuperAdmin = useGetV1BusinessUnitSuperAdminHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>>
        >
    > = () => getV1BusinessUnitSuperAdmin(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1BusinessUnitSuperAdminQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>>>
>;
export type GetV1BusinessUnitSuperAdminQueryError = ErrorType<void>;

export const useGetV1BusinessUnitSuperAdmin = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>>
    >,
    TError = ErrorType<void>
>(
    params?: GetV1BusinessUnitSuperAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1BusinessUnitSuperAdminHook>
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1BusinessUnitSuperAdminQueryOptions(
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePostV1BusinessUnitSuperAdminHook = () => {
    const postV1BusinessUnitSuperAdmin = useCustomAxios<BusinessUnitResponse>();

    return useCallback(
        (createBusinessUnitRequest: CreateBusinessUnitRequest) => {
            return postV1BusinessUnitSuperAdmin({
                url: `/v1/BusinessUnit/SuperAdmin`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: createBusinessUnitRequest,
            });
        },
        [postV1BusinessUnitSuperAdmin]
    );
};

export const usePostV1BusinessUnitSuperAdminMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1BusinessUnitSuperAdminHook>>
        >,
        TError,
        { data: CreateBusinessUnitRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1BusinessUnitSuperAdminHook>>>,
    TError,
    { data: CreateBusinessUnitRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1BusinessUnitSuperAdmin = usePostV1BusinessUnitSuperAdminHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1BusinessUnitSuperAdminHook>>
        >,
        { data: CreateBusinessUnitRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1BusinessUnitSuperAdmin(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1BusinessUnitSuperAdminMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1BusinessUnitSuperAdminHook>>>
>;
export type PostV1BusinessUnitSuperAdminMutationBody =
    CreateBusinessUnitRequest;
export type PostV1BusinessUnitSuperAdminMutationError = ErrorType<void>;

export const usePostV1BusinessUnitSuperAdmin = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1BusinessUnitSuperAdminHook>>
        >,
        TError,
        { data: CreateBusinessUnitRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1BusinessUnitSuperAdminHook>>>,
    TError,
    { data: CreateBusinessUnitRequest },
    TContext
> => {
    const mutationOptions =
        usePostV1BusinessUnitSuperAdminMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook =
    () => {
        const getV1BusinessUnitSuperAdminConfigurationBusinessUnitId =
            useCustomAxios<BusinessUnitConfigurationResponse>();

        return useCallback(
            (businessUnitId: number) => {
                return getV1BusinessUnitSuperAdminConfigurationBusinessUnitId({
                    url: `/v1/BusinessUnit/SuperAdmin/configuration/${businessUnitId}`,
                    method: 'GET',
                });
            },
            [getV1BusinessUnitSuperAdminConfigurationBusinessUnitId]
        );
    };

export const getGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdQueryKey =
    (businessUnitId: number) => {
        return [
            `/v1/BusinessUnit/SuperAdmin/configuration/${businessUnitId}`,
        ] as const;
    };

export const useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdQueryOptions =
    <
        TData = Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
                >
            >
        >,
        TError = ErrorType<void>
    >(
        businessUnitId: number,
        options?: {
            query?: UseQueryOptions<
                Awaited<
                    ReturnType<
                        ReturnType<
                            typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
                        >
                    >
                >,
                TError,
                TData
            >;
        }
    ) => {
        const { query: queryOptions } = options ?? {};

        const queryKey =
            queryOptions?.queryKey ??
            getGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdQueryKey(
                businessUnitId
            );

        const getV1BusinessUnitSuperAdminConfigurationBusinessUnitId =
            useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook();

        const queryFn: QueryFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
                    >
                >
            >
        > = () =>
            getV1BusinessUnitSuperAdminConfigurationBusinessUnitId(
                businessUnitId
            );

        return {
            queryKey,
            queryFn,
            enabled: !!businessUnitId,
            ...queryOptions,
        } as UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
                    >
                >
            >,
            TError,
            TData
        > & { queryKey: QueryKey };
    };

export type GetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdQueryResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
                >
            >
        >
    >;
export type GetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdQueryError =
    ErrorType<void>;

export const useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitId = <
    TData = Awaited<
        ReturnType<
            ReturnType<
                typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
            >
        >
    >,
    TError = ErrorType<void>
>(
    businessUnitId: number,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdHook
                    >
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
        useGetV1BusinessUnitSuperAdminConfigurationBusinessUnitIdQueryOptions(
            businessUnitId,
            options
        );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePutV1BusinessUnitSuperAdminBusinessUnitIdHook = () => {
    const putV1BusinessUnitSuperAdminBusinessUnitId =
        useCustomAxios<BusinessUnitResponse>();

    return useCallback(
        (
            businessUnitId: number,
            editBusinessUnitRequest: EditBusinessUnitRequest
        ) => {
            return putV1BusinessUnitSuperAdminBusinessUnitId({
                url: `/v1/BusinessUnit/SuperAdmin/${businessUnitId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editBusinessUnitRequest,
            });
        },
        [putV1BusinessUnitSuperAdminBusinessUnitId]
    );
};

export const usePutV1BusinessUnitSuperAdminBusinessUnitIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number; data: EditBusinessUnitRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1BusinessUnitSuperAdminBusinessUnitIdHook>
        >
    >,
    TError,
    { businessUnitId: number; data: EditBusinessUnitRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1BusinessUnitSuperAdminBusinessUnitId =
        usePutV1BusinessUnitSuperAdminBusinessUnitIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >,
        { businessUnitId: number; data: EditBusinessUnitRequest }
    > = (props) => {
        const { businessUnitId, data } = props ?? {};

        return putV1BusinessUnitSuperAdminBusinessUnitId(businessUnitId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1BusinessUnitSuperAdminBusinessUnitIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >
    >;
export type PutV1BusinessUnitSuperAdminBusinessUnitIdMutationBody =
    EditBusinessUnitRequest;
export type PutV1BusinessUnitSuperAdminBusinessUnitIdMutationError =
    ErrorType<void>;

export const usePutV1BusinessUnitSuperAdminBusinessUnitId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number; data: EditBusinessUnitRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof usePutV1BusinessUnitSuperAdminBusinessUnitIdHook>
        >
    >,
    TError,
    { businessUnitId: number; data: EditBusinessUnitRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1BusinessUnitSuperAdminBusinessUnitIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook = () => {
    const deleteV1BusinessUnitSuperAdminBusinessUnitId = useCustomAxios<void>();

    return useCallback(
        (businessUnitId: number) => {
            return deleteV1BusinessUnitSuperAdminBusinessUnitId({
                url: `/v1/BusinessUnit/SuperAdmin/${businessUnitId}`,
                method: 'DELETE',
            });
        },
        [deleteV1BusinessUnitSuperAdminBusinessUnitId]
    );
};

export const useDeleteV1BusinessUnitSuperAdminBusinessUnitIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<
                typeof useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook
            >
        >
    >,
    TError,
    { businessUnitId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1BusinessUnitSuperAdminBusinessUnitId =
        useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >,
        { businessUnitId: number }
    > = (props) => {
        const { businessUnitId } = props ?? {};

        return deleteV1BusinessUnitSuperAdminBusinessUnitId(businessUnitId);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1BusinessUnitSuperAdminBusinessUnitIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >
    >;

export type DeleteV1BusinessUnitSuperAdminBusinessUnitIdMutationError =
    ErrorType<void>;

export const useDeleteV1BusinessUnitSuperAdminBusinessUnitId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof useDeleteV1BusinessUnitSuperAdminBusinessUnitIdHook
            >
        >
    >,
    TError,
    { businessUnitId: number },
    TContext
> => {
    const mutationOptions =
        useDeleteV1BusinessUnitSuperAdminBusinessUnitIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook =
    () => {
        const putV1BusinessUnitSuperAdminLandingpageBusinessUnitId =
            useCustomAxios<void>();

        return useCallback(
            (
                businessUnitId: number,
                editBusinessUnitLandingPageContentRequest: EditBusinessUnitLandingPageContentRequest
            ) => {
                return putV1BusinessUnitSuperAdminLandingpageBusinessUnitId({
                    url: `/v1/BusinessUnit/SuperAdmin/landingpage/${businessUnitId}`,
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    data: editBusinessUnitLandingPageContentRequest,
                });
            },
            [putV1BusinessUnitSuperAdminLandingpageBusinessUnitId]
        );
    };

export const usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook
                    >
                >
            >,
            TError,
            {
                businessUnitId: number;
                data: EditBusinessUnitLandingPageContentRequest;
            },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook
                >
            >
        >,
        TError,
        {
            businessUnitId: number;
            data: EditBusinessUnitLandingPageContentRequest;
        },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1BusinessUnitSuperAdminLandingpageBusinessUnitId =
            usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook
                    >
                >
            >,
            {
                businessUnitId: number;
                data: EditBusinessUnitLandingPageContentRequest;
            }
        > = (props) => {
            const { businessUnitId, data } = props ?? {};

            return putV1BusinessUnitSuperAdminLandingpageBusinessUnitId(
                businessUnitId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook
                >
            >
        >
    >;
export type PutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdMutationBody =
    EditBusinessUnitLandingPageContentRequest;
export type PutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdMutationError =
    ErrorType<void>;

export const usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook
                >
            >
        >,
        TError,
        {
            businessUnitId: number;
            data: EditBusinessUnitLandingPageContentRequest;
        },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdHook
            >
        >
    >,
    TError,
    { businessUnitId: number; data: EditBusinessUnitLandingPageContentRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1BusinessUnitSuperAdminLandingpageBusinessUnitIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook = () => {
    const putV1BusinessUnitSuperAdminReportBusinessUnitId =
        useCustomAxios<void>();

    return useCallback(
        (
            businessUnitId: number,
            editBusinessUnitReportRequest: EditBusinessUnitReportRequest
        ) => {
            return putV1BusinessUnitSuperAdminReportBusinessUnitId({
                url: `/v1/BusinessUnit/SuperAdmin/report/${businessUnitId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editBusinessUnitReportRequest,
            });
        },
        [putV1BusinessUnitSuperAdminReportBusinessUnitId]
    );
};

export const usePutV1BusinessUnitSuperAdminReportBusinessUnitIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook
                    >
                >
            >,
            TError,
            { businessUnitId: number; data: EditBusinessUnitReportRequest },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number; data: EditBusinessUnitReportRequest },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1BusinessUnitSuperAdminReportBusinessUnitId =
            usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook
                    >
                >
            >,
            { businessUnitId: number; data: EditBusinessUnitReportRequest }
        > = (props) => {
            const { businessUnitId, data } = props ?? {};

            return putV1BusinessUnitSuperAdminReportBusinessUnitId(
                businessUnitId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1BusinessUnitSuperAdminReportBusinessUnitIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook
                >
            >
        >
    >;
export type PutV1BusinessUnitSuperAdminReportBusinessUnitIdMutationBody =
    EditBusinessUnitReportRequest;
export type PutV1BusinessUnitSuperAdminReportBusinessUnitIdMutationError =
    ErrorType<void>;

export const usePutV1BusinessUnitSuperAdminReportBusinessUnitId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number; data: EditBusinessUnitReportRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1BusinessUnitSuperAdminReportBusinessUnitIdHook
            >
        >
    >,
    TError,
    { businessUnitId: number; data: EditBusinessUnitReportRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1BusinessUnitSuperAdminReportBusinessUnitIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook = () => {
    const putV1BusinessUnitSuperAdminRulesBusinessUnitId =
        useCustomAxios<void>();

    return useCallback(
        (
            businessUnitId: number,
            editBusinessUnitUploadRulesRequest: EditBusinessUnitUploadRulesRequest
        ) => {
            return putV1BusinessUnitSuperAdminRulesBusinessUnitId({
                url: `/v1/BusinessUnit/SuperAdmin/rules/${businessUnitId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editBusinessUnitUploadRulesRequest,
            });
        },
        [putV1BusinessUnitSuperAdminRulesBusinessUnitId]
    );
};

export const usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook
                    >
                >
            >,
            TError,
            {
                businessUnitId: number;
                data: EditBusinessUnitUploadRulesRequest;
            },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number; data: EditBusinessUnitUploadRulesRequest },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1BusinessUnitSuperAdminRulesBusinessUnitId =
            usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook
                    >
                >
            >,
            { businessUnitId: number; data: EditBusinessUnitUploadRulesRequest }
        > = (props) => {
            const { businessUnitId, data } = props ?? {};

            return putV1BusinessUnitSuperAdminRulesBusinessUnitId(
                businessUnitId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1BusinessUnitSuperAdminRulesBusinessUnitIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook
                >
            >
        >
    >;
export type PutV1BusinessUnitSuperAdminRulesBusinessUnitIdMutationBody =
    EditBusinessUnitUploadRulesRequest;
export type PutV1BusinessUnitSuperAdminRulesBusinessUnitIdMutationError =
    ErrorType<void>;

export const usePutV1BusinessUnitSuperAdminRulesBusinessUnitId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook
                >
            >
        >,
        TError,
        { businessUnitId: number; data: EditBusinessUnitUploadRulesRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdHook
            >
        >
    >,
    TError,
    { businessUnitId: number; data: EditBusinessUnitUploadRulesRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1BusinessUnitSuperAdminRulesBusinessUnitIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

/**
 * @summary Returns all contact persons who match the value of the searchString
In case of no searchString, returns all contact persons
 */
export const useGetV1ContactPersonSuperAdminHook = () => {
    const getV1ContactPersonSuperAdmin =
        useCustomAxios<ContactPersonResponse[]>();

    return useCallback(
        (params?: GetV1ContactPersonSuperAdminParams) => {
            return getV1ContactPersonSuperAdmin({
                url: `/v1/ContactPerson/SuperAdmin`,
                method: 'GET',
                params,
            });
        },
        [getV1ContactPersonSuperAdmin]
    );
};

export const getGetV1ContactPersonSuperAdminQueryKey = (
    params?: GetV1ContactPersonSuperAdminParams
) => {
    return [
        `/v1/ContactPerson/SuperAdmin`,
        ...(params ? [params] : []),
    ] as const;
};

export const useGetV1ContactPersonSuperAdminQueryOptions = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonSuperAdminHook>>
    >,
    TError = ErrorType<unknown>
>(
    params?: GetV1ContactPersonSuperAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1ContactPersonSuperAdminHook>
                >
            >,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ??
        getGetV1ContactPersonSuperAdminQueryKey(params);

    const getV1ContactPersonSuperAdmin = useGetV1ContactPersonSuperAdminHook();

    const queryFn: QueryFunction<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonSuperAdminHook>>
        >
    > = () => getV1ContactPersonSuperAdmin(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<
            ReturnType<ReturnType<typeof useGetV1ContactPersonSuperAdminHook>>
        >,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1ContactPersonSuperAdminQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1ContactPersonSuperAdminHook>>>
>;
export type GetV1ContactPersonSuperAdminQueryError = ErrorType<unknown>;

/**
 * @summary Returns all contact persons who match the value of the searchString
In case of no searchString, returns all contact persons
 */
export const useGetV1ContactPersonSuperAdmin = <
    TData = Awaited<
        ReturnType<ReturnType<typeof useGetV1ContactPersonSuperAdminHook>>
    >,
    TError = ErrorType<unknown>
>(
    params?: GetV1ContactPersonSuperAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<
                ReturnType<
                    ReturnType<typeof useGetV1ContactPersonSuperAdminHook>
                >
            >,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1ContactPersonSuperAdminQueryOptions(
        params,
        options
    );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Create a new business unit contact person
 */
export const usePostV1ContactPersonSuperAdminHook = () => {
    const postV1ContactPersonSuperAdmin =
        useCustomAxios<ContactPersonResponse>();

    return useCallback(
        (createContactPersonRequest: CreateContactPersonRequest) => {
            return postV1ContactPersonSuperAdmin({
                url: `/v1/ContactPerson/SuperAdmin`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: createContactPersonRequest,
            });
        },
        [postV1ContactPersonSuperAdmin]
    );
};

export const usePostV1ContactPersonSuperAdminMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1ContactPersonSuperAdminHook>>
        >,
        TError,
        { data: CreateContactPersonRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1ContactPersonSuperAdminHook>>
    >,
    TError,
    { data: CreateContactPersonRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1ContactPersonSuperAdmin =
        usePostV1ContactPersonSuperAdminHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1ContactPersonSuperAdminHook>>
        >,
        { data: CreateContactPersonRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1ContactPersonSuperAdmin(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1ContactPersonSuperAdminMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1ContactPersonSuperAdminHook>>>
>;
export type PostV1ContactPersonSuperAdminMutationBody =
    CreateContactPersonRequest;
export type PostV1ContactPersonSuperAdminMutationError = ErrorType<void>;

/**
 * @summary Create a new business unit contact person
 */
export const usePostV1ContactPersonSuperAdmin = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePostV1ContactPersonSuperAdminHook>>
        >,
        TError,
        { data: CreateContactPersonRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePostV1ContactPersonSuperAdminHook>>
    >,
    TError,
    { data: CreateContactPersonRequest },
    TContext
> => {
    const mutationOptions =
        usePostV1ContactPersonSuperAdminMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1ContactPersonSuperAdminHook = () => {
    const putV1ContactPersonSuperAdmin = useCustomAxios<void>();

    return useCallback(
        (
            updateBusinessUnitContactPersonsRequest: UpdateBusinessUnitContactPersonsRequest
        ) => {
            return putV1ContactPersonSuperAdmin({
                url: `/v1/ContactPerson/SuperAdmin`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: updateBusinessUnitContactPersonsRequest,
            });
        },
        [putV1ContactPersonSuperAdmin]
    );
};

export const usePutV1ContactPersonSuperAdminMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1ContactPersonSuperAdminHook>>
        >,
        TError,
        { data: UpdateBusinessUnitContactPersonsRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1ContactPersonSuperAdminHook>>>,
    TError,
    { data: UpdateBusinessUnitContactPersonsRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1ContactPersonSuperAdmin = usePutV1ContactPersonSuperAdminHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1ContactPersonSuperAdminHook>>
        >,
        { data: UpdateBusinessUnitContactPersonsRequest }
    > = (props) => {
        const { data } = props ?? {};

        return putV1ContactPersonSuperAdmin(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1ContactPersonSuperAdminMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1ContactPersonSuperAdminHook>>>
>;
export type PutV1ContactPersonSuperAdminMutationBody =
    UpdateBusinessUnitContactPersonsRequest;
export type PutV1ContactPersonSuperAdminMutationError = ErrorType<void>;

export const usePutV1ContactPersonSuperAdmin = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1ContactPersonSuperAdminHook>>
        >,
        TError,
        { data: UpdateBusinessUnitContactPersonsRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePutV1ContactPersonSuperAdminHook>>>,
    TError,
    { data: UpdateBusinessUnitContactPersonsRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1ContactPersonSuperAdminMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Create a new application contact
 */
export const usePostV1ContactPersonSuperAdminApplicationHook = () => {
    const postV1ContactPersonSuperAdminApplication =
        useCustomAxios<ContactPersonResponse>();

    return useCallback(
        (createContactPersonRequest: CreateContactPersonRequest) => {
            return postV1ContactPersonSuperAdminApplication({
                url: `/v1/ContactPerson/SuperAdmin/Application`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: createContactPersonRequest,
            });
        },
        [postV1ContactPersonSuperAdminApplication]
    );
};

export const usePostV1ContactPersonSuperAdminApplicationMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >,
        TError,
        { data: CreateContactPersonRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<typeof usePostV1ContactPersonSuperAdminApplicationHook>
        >
    >,
    TError,
    { data: CreateContactPersonRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1ContactPersonSuperAdminApplication =
        usePostV1ContactPersonSuperAdminApplicationHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >,
        { data: CreateContactPersonRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1ContactPersonSuperAdminApplication(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1ContactPersonSuperAdminApplicationMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >
    >;
export type PostV1ContactPersonSuperAdminApplicationMutationBody =
    CreateContactPersonRequest;
export type PostV1ContactPersonSuperAdminApplicationMutationError =
    ErrorType<void>;

/**
 * @summary Create a new application contact
 */
export const usePostV1ContactPersonSuperAdminApplication = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePostV1ContactPersonSuperAdminApplicationHook
                >
            >
        >,
        TError,
        { data: CreateContactPersonRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<typeof usePostV1ContactPersonSuperAdminApplicationHook>
        >
    >,
    TError,
    { data: CreateContactPersonRequest },
    TContext
> => {
    const mutationOptions =
        usePostV1ContactPersonSuperAdminApplicationMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Edit an existing application contact
 */
export const usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook =
    () => {
        const putV1ContactPersonSuperAdminApplicationApplicationContactId =
            useCustomAxios<ContactPersonResponse>();

        return useCallback(
            (
                applicationContactId: number,
                editContactPersonRequest: EditContactPersonRequest
            ) => {
                return putV1ContactPersonSuperAdminApplicationApplicationContactId(
                    {
                        url: `/v1/ContactPerson/SuperAdmin/Application/${applicationContactId}`,
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        data: editContactPersonRequest,
                    }
                );
            },
            [putV1ContactPersonSuperAdminApplicationApplicationContactId]
        );
    };

export const usePutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook
                    >
                >
            >,
            TError,
            { applicationContactId: number; data: EditContactPersonRequest },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook
                >
            >
        >,
        TError,
        { applicationContactId: number; data: EditContactPersonRequest },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const putV1ContactPersonSuperAdminApplicationApplicationContactId =
            usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook
                    >
                >
            >,
            { applicationContactId: number; data: EditContactPersonRequest }
        > = (props) => {
            const { applicationContactId, data } = props ?? {};

            return putV1ContactPersonSuperAdminApplicationApplicationContactId(
                applicationContactId,
                data
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type PutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook
                >
            >
        >
    >;
export type PutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationBody =
    EditContactPersonRequest;
export type PutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationError =
    ErrorType<void>;

/**
 * @summary Edit an existing application contact
 */
export const usePutV1ContactPersonSuperAdminApplicationApplicationContactId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook
                >
            >
        >,
        TError,
        { applicationContactId: number; data: EditContactPersonRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1ContactPersonSuperAdminApplicationApplicationContactIdHook
            >
        >
    >,
    TError,
    { applicationContactId: number; data: EditContactPersonRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1ContactPersonSuperAdminApplicationApplicationContactIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const useDeleteV1ContactPersonSuperAdminContactPersonIdHook = () => {
    const deleteV1ContactPersonSuperAdminContactPersonId =
        useCustomAxios<void>();

    return useCallback(
        (contactPersonId: number) => {
            return deleteV1ContactPersonSuperAdminContactPersonId({
                url: `/v1/ContactPerson/SuperAdmin/${contactPersonId}`,
                method: 'DELETE',
            });
        },
        [deleteV1ContactPersonSuperAdminContactPersonId]
    );
};

export const useDeleteV1ContactPersonSuperAdminContactPersonIdMutationOptions =
    <TError = ErrorType<void>, TContext = unknown>(options?: {
        mutation?: UseMutationOptions<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useDeleteV1ContactPersonSuperAdminContactPersonIdHook
                    >
                >
            >,
            TError,
            { contactPersonId: number },
            TContext
        >;
    }): UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >,
        TError,
        { contactPersonId: number },
        TContext
    > => {
        const { mutation: mutationOptions } = options ?? {};

        const deleteV1ContactPersonSuperAdminContactPersonId =
            useDeleteV1ContactPersonSuperAdminContactPersonIdHook();

        const mutationFn: MutationFunction<
            Awaited<
                ReturnType<
                    ReturnType<
                        typeof useDeleteV1ContactPersonSuperAdminContactPersonIdHook
                    >
                >
            >,
            { contactPersonId: number }
        > = (props) => {
            const { contactPersonId } = props ?? {};

            return deleteV1ContactPersonSuperAdminContactPersonId(
                contactPersonId
            );
        };

        return { mutationFn, ...mutationOptions };
    };

export type DeleteV1ContactPersonSuperAdminContactPersonIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >
    >;

export type DeleteV1ContactPersonSuperAdminContactPersonIdMutationError =
    ErrorType<void>;

export const useDeleteV1ContactPersonSuperAdminContactPersonId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof useDeleteV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >,
        TError,
        { contactPersonId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof useDeleteV1ContactPersonSuperAdminContactPersonIdHook
            >
        >
    >,
    TError,
    { contactPersonId: number },
    TContext
> => {
    const mutationOptions =
        useDeleteV1ContactPersonSuperAdminContactPersonIdMutationOptions(
            options
        );

    return useMutation(mutationOptions);
};

export const usePutV1ContactPersonSuperAdminContactPersonIdHook = () => {
    const putV1ContactPersonSuperAdminContactPersonId = useCustomAxios<void>();

    return useCallback(
        (
            contactPersonId: number,
            editContactPersonRequest: EditContactPersonRequest
        ) => {
            return putV1ContactPersonSuperAdminContactPersonId({
                url: `/v1/ContactPerson/SuperAdmin/${contactPersonId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editContactPersonRequest,
            });
        },
        [putV1ContactPersonSuperAdminContactPersonId]
    );
};

export const usePutV1ContactPersonSuperAdminContactPersonIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >,
        TError,
        { contactPersonId: number; data: EditContactPersonRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1ContactPersonSuperAdminContactPersonIdHook
            >
        >
    >,
    TError,
    { contactPersonId: number; data: EditContactPersonRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1ContactPersonSuperAdminContactPersonId =
        usePutV1ContactPersonSuperAdminContactPersonIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >,
        { contactPersonId: number; data: EditContactPersonRequest }
    > = (props) => {
        const { contactPersonId, data } = props ?? {};

        return putV1ContactPersonSuperAdminContactPersonId(
            contactPersonId,
            data
        );
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1ContactPersonSuperAdminContactPersonIdMutationResult =
    NonNullable<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >
    >;
export type PutV1ContactPersonSuperAdminContactPersonIdMutationBody =
    EditContactPersonRequest;
export type PutV1ContactPersonSuperAdminContactPersonIdMutationError =
    ErrorType<void>;

export const usePutV1ContactPersonSuperAdminContactPersonId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<
                ReturnType<
                    typeof usePutV1ContactPersonSuperAdminContactPersonIdHook
                >
            >
        >,
        TError,
        { contactPersonId: number; data: EditContactPersonRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<
            ReturnType<
                typeof usePutV1ContactPersonSuperAdminContactPersonIdHook
            >
        >
    >,
    TError,
    { contactPersonId: number; data: EditContactPersonRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1ContactPersonSuperAdminContactPersonIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const usePutV1PolicySuperAdminPolicyIdHook = () => {
    const putV1PolicySuperAdminPolicyId = useCustomAxios<PolicyResponse>();

    return useCallback(
        (policyId: number, editPolicyRequest: EditPolicyRequest) => {
            return putV1PolicySuperAdminPolicyId({
                url: `/v1/Policy/SuperAdmin/${policyId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editPolicyRequest,
            });
        },
        [putV1PolicySuperAdminPolicyId]
    );
};

export const usePutV1PolicySuperAdminPolicyIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
        >,
        TError,
        { policyId: number; data: EditPolicyRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
    >,
    TError,
    { policyId: number; data: EditPolicyRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1PolicySuperAdminPolicyId =
        usePutV1PolicySuperAdminPolicyIdHook();

    const mutationFn: MutationFunction<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
        >,
        { policyId: number; data: EditPolicyRequest }
    > = (props) => {
        const { policyId, data } = props ?? {};

        return putV1PolicySuperAdminPolicyId(policyId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1PolicySuperAdminPolicyIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>>
>;
export type PutV1PolicySuperAdminPolicyIdMutationBody = EditPolicyRequest;
export type PutV1PolicySuperAdminPolicyIdMutationError = ErrorType<void>;

export const usePutV1PolicySuperAdminPolicyId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<
            ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
        >,
        TError,
        { policyId: number; data: EditPolicyRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<
        ReturnType<ReturnType<typeof usePutV1PolicySuperAdminPolicyIdHook>>
    >,
    TError,
    { policyId: number; data: EditPolicyRequest },
    TContext
> => {
    const mutationOptions =
        usePutV1PolicySuperAdminPolicyIdMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Returns a list of all users for the requesting user's business unit
Super Admins are able to specify a businessUnitId from which the users are retrieved
 */
export const useGetV1UserAdminHook = () => {
    const getV1UserAdmin = useCustomAxios<PaginatedUserResponse>();

    return useCallback(
        (params?: GetV1UserAdminParams) => {
            return getV1UserAdmin({
                url: `/v1/User/Admin`,
                method: 'GET',
                params,
            });
        },
        [getV1UserAdmin]
    );
};

export const getGetV1UserAdminQueryKey = (params?: GetV1UserAdminParams) => {
    return [`/v1/User/Admin`, ...(params ? [params] : [])] as const;
};

export const useGetV1UserAdminQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1UserAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
            TError,
            TData
        >;
    }
) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1UserAdminQueryKey(params);

    const getV1UserAdmin = useGetV1UserAdminHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>
    > = () => getV1UserAdmin(params);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1UserAdminQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>
>;
export type GetV1UserAdminQueryError = ErrorType<void>;

/**
 * @summary Returns a list of all users for the requesting user's business unit
Super Admins are able to specify a businessUnitId from which the users are retrieved
 */
export const useGetV1UserAdmin = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
    TError = ErrorType<void>
>(
    params?: GetV1UserAdminParams,
    options?: {
        query?: UseQueryOptions<
            Awaited<ReturnType<ReturnType<typeof useGetV1UserAdminHook>>>,
            TError,
            TData
        >;
    }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1UserAdminQueryOptions(params, options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

/**
 * @summary Create a new user
 */
export const usePostV1UserAdminHook = () => {
    const postV1UserAdmin = useCustomAxios<UserResponse>();

    return useCallback(
        (createUserRequest: CreateUserRequest) => {
            return postV1UserAdmin({
                url: `/v1/User/Admin`,
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: createUserRequest,
            });
        },
        [postV1UserAdmin]
    );
};

export const usePostV1UserAdminMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
        TError,
        { data: CreateUserRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
    TError,
    { data: CreateUserRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const postV1UserAdmin = usePostV1UserAdminHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
        { data: CreateUserRequest }
    > = (props) => {
        const { data } = props ?? {};

        return postV1UserAdmin(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PostV1UserAdminMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>
>;
export type PostV1UserAdminMutationBody = CreateUserRequest;
export type PostV1UserAdminMutationError = ErrorType<void>;

/**
 * @summary Create a new user
 */
export const usePostV1UserAdmin = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
        TError,
        { data: CreateUserRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePostV1UserAdminHook>>>,
    TError,
    { data: CreateUserRequest },
    TContext
> => {
    const mutationOptions = usePostV1UserAdminMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Edit an existing user
 */
export const usePutV1UserAdminUserIdHook = () => {
    const putV1UserAdminUserId = useCustomAxios<UserResponse>();

    return useCallback(
        (userId: number, editUserRequest: EditUserRequest) => {
            return putV1UserAdminUserId({
                url: `/v1/User/Admin/${userId}`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: editUserRequest,
            });
        },
        [putV1UserAdminUserId]
    );
};

export const usePutV1UserAdminUserIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
        TError,
        { userId: number; data: EditUserRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
    TError,
    { userId: number; data: EditUserRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1UserAdminUserId = usePutV1UserAdminUserIdHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
        { userId: number; data: EditUserRequest }
    > = (props) => {
        const { userId, data } = props ?? {};

        return putV1UserAdminUserId(userId, data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1UserAdminUserIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>
>;
export type PutV1UserAdminUserIdMutationBody = EditUserRequest;
export type PutV1UserAdminUserIdMutationError = ErrorType<void>;

/**
 * @summary Edit an existing user
 */
export const usePutV1UserAdminUserId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
        TError,
        { userId: number; data: EditUserRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserAdminUserIdHook>>>,
    TError,
    { userId: number; data: EditUserRequest },
    TContext
> => {
    const mutationOptions = usePutV1UserAdminUserIdMutationOptions(options);

    return useMutation(mutationOptions);
};

/**
 * @summary Soft delete an existing user
 */
export const useDeleteV1UserAdminUserIdHook = () => {
    const deleteV1UserAdminUserId = useCustomAxios<void>();

    return useCallback(
        (userId: number) => {
            return deleteV1UserAdminUserId({
                url: `/v1/User/Admin/${userId}`,
                method: 'DELETE',
            });
        },
        [deleteV1UserAdminUserId]
    );
};

export const useDeleteV1UserAdminUserIdMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
        TError,
        { userId: number },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
    TError,
    { userId: number },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const deleteV1UserAdminUserId = useDeleteV1UserAdminUserIdHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
        { userId: number }
    > = (props) => {
        const { userId } = props ?? {};

        return deleteV1UserAdminUserId(userId);
    };

    return { mutationFn, ...mutationOptions };
};

export type DeleteV1UserAdminUserIdMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>
>;

export type DeleteV1UserAdminUserIdMutationError = ErrorType<void>;

/**
 * @summary Soft delete an existing user
 */
export const useDeleteV1UserAdminUserId = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
        TError,
        { userId: number },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof useDeleteV1UserAdminUserIdHook>>>,
    TError,
    { userId: number },
    TContext
> => {
    const mutationOptions = useDeleteV1UserAdminUserIdMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1UserPreferencesHook = () => {
    const getV1UserPreferences = useCustomAxios<UserPreferencesResponse>();

    return useCallback(() => {
        return getV1UserPreferences({
            url: `/v1/User/Preferences`,
            method: 'GET',
        });
    }, [getV1UserPreferences]);
};

export const getGetV1UserPreferencesQueryKey = () => {
    return [`/v1/User/Preferences`] as const;
};

export const useGetV1UserPreferencesQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
        queryOptions?.queryKey ?? getGetV1UserPreferencesQueryKey();

    const getV1UserPreferences = useGetV1UserPreferencesHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>
    > = () => getV1UserPreferences();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1UserPreferencesQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>
>;
export type GetV1UserPreferencesQueryError = ErrorType<void>;

export const useGetV1UserPreferences = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
    TError = ErrorType<void>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1UserPreferencesHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1UserPreferencesQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};

export const usePutV1UserPreferencesHook = () => {
    const putV1UserPreferences = useCustomAxios<UserPreferencesResponse>();

    return useCallback(
        (userPreferencesRequest: UserPreferencesRequest) => {
            return putV1UserPreferences({
                url: `/v1/User/Preferences`,
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                data: userPreferencesRequest,
            });
        },
        [putV1UserPreferences]
    );
};

export const usePutV1UserPreferencesMutationOptions = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
        TError,
        { data: UserPreferencesRequest },
        TContext
    >;
}): UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
    TError,
    { data: UserPreferencesRequest },
    TContext
> => {
    const { mutation: mutationOptions } = options ?? {};

    const putV1UserPreferences = usePutV1UserPreferencesHook();

    const mutationFn: MutationFunction<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
        { data: UserPreferencesRequest }
    > = (props) => {
        const { data } = props ?? {};

        return putV1UserPreferences(data);
    };

    return { mutationFn, ...mutationOptions };
};

export type PutV1UserPreferencesMutationResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>
>;
export type PutV1UserPreferencesMutationBody = UserPreferencesRequest;
export type PutV1UserPreferencesMutationError = ErrorType<void>;

export const usePutV1UserPreferences = <
    TError = ErrorType<void>,
    TContext = unknown
>(options?: {
    mutation?: UseMutationOptions<
        Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
        TError,
        { data: UserPreferencesRequest },
        TContext
    >;
}): UseMutationResult<
    Awaited<ReturnType<ReturnType<typeof usePutV1UserPreferencesHook>>>,
    TError,
    { data: UserPreferencesRequest },
    TContext
> => {
    const mutationOptions = usePutV1UserPreferencesMutationOptions(options);

    return useMutation(mutationOptions);
};

export const useGetV1VersionHook = () => {
    const getV1Version = useCustomAxios<ApplicationVersionResponse>();

    return useCallback(() => {
        return getV1Version({ url: `/v1/Version`, method: 'GET' });
    }, [getV1Version]);
};

export const getGetV1VersionQueryKey = () => {
    return [`/v1/Version`] as const;
};

export const useGetV1VersionQueryOptions = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
        TError,
        TData
    >;
}) => {
    const { query: queryOptions } = options ?? {};

    const queryKey = queryOptions?.queryKey ?? getGetV1VersionQueryKey();

    const getV1Version = useGetV1VersionHook();

    const queryFn: QueryFunction<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>
    > = () => getV1Version();

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
        TError,
        TData
    > & { queryKey: QueryKey };
};

export type GetV1VersionQueryResult = NonNullable<
    Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>
>;
export type GetV1VersionQueryError = ErrorType<unknown>;

export const useGetV1Version = <
    TData = Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
    TError = ErrorType<unknown>
>(options?: {
    query?: UseQueryOptions<
        Awaited<ReturnType<ReturnType<typeof useGetV1VersionHook>>>,
        TError,
        TData
    >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions = useGetV1VersionQueryOptions(options);

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
        queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
};
