import React, { useState, useEffect } from 'react';
import {
    Table,
    Column,
    Checkbox,
    Tooltip,
    Badge,
    Panel,
} from '@appkit4/react-components';
import { FC } from 'react';
import { IndexedCoacheeData } from '../../../models/indexedCoacheeData';
import useLocale from '../../../locale/useLocale';
import GlobalSearch from '../../GlobalSearch';
import { usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationId } from '../../../api/generated/endpoint';
import useToast from '../../../helpers/useToast';
import GlobalPagination from '../../GlobalPagination';

import './CoacheesTable.scss';
import EditNotesModal from '../../EditNotesModal';

type ValueType = string | number;
type SelectValue = ValueType | ValueType[];

interface CoacheesTableProps {
    coachees: IndexedCoacheeData[];
    onSelectedCoacheesChange: (selectedCoachees: IndexedCoacheeData[]) => void;
    allocationId: number;
    onUpdateNotes: (coacheeId: string, notes: string) => void;
}

const CoacheesTable: FC<CoacheesTableProps> = ({
    coachees,
    onSelectedCoacheesChange,
    allocationId,
    onUpdateNotes,
}) => {
    const { l } = useLocale();

    const showToast = useToast();

    const [selectedCoachees, setSelectedCoachees] = useState<
        IndexedCoacheeData[]
    >([]);

    const updateNotes =
        usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationId();

    const [selectedCoachee, setSelectedCoachee] =
        useState<IndexedCoacheeData>();

    const [displayEditNotesModal, setDisplayEditNotesModal] = useState(false);

    const [checkValue, setCheckValue] = React.useState(false);

    const [searchString, setSearchString] = useState<string>('');

    const [dropdownValue, setDropdownValue] = React.useState<SelectValue>(10);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(10);

    const [showWithoutCareerCoach, setShowWithoutCareerCoach] = useState(false);
    const [showWithWarning, setShowWithWarning] = useState(false);

    const [filteredData, setFilteredData] = useState(coachees);
    const [total, setTotal] = useState(0);

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const onPageSizeSelect = (value: SelectValue) => {
        const selectedPageSize = Number(value);

        setItemsPerPage(selectedPageSize);
        setCurrentPage(1);
    };

    const renderNameCell = (row: IndexedCoacheeData, field: string) => {
        const onCheckboxChange = (value: boolean) => {
            if (value) {
                setSelectedCoachees((prev: IndexedCoacheeData[]) => [
                    ...prev,
                    row,
                ]);
            } else {
                setSelectedCoachees((prev: IndexedCoacheeData[]) =>
                    prev.filter((coachee) => coachee.index !== row.index)
                );
            }

            onSelectedCoacheesChange(selectedCoachees);
        };

        const renderCoacheeWarnings = (row: IndexedCoacheeData) => {
            return (
                <span>
                    {row.warnings && row.warnings.length > 0 && (
                        <Tooltip
                            content={() => (
                                <ul>
                                    {row.warnings?.map((warning, index) => (
                                        <li key={index}>{warning}</li>
                                    ))}
                                </ul>
                            )}
                        >
                            <button
                                className="Appkit4-icon icon-circle-warning-outline"
                                style={{ color: '#D04A02' }}
                            ></button>
                        </Tooltip>
                    )}
                </span>
            );
        };

        if (!(field in row)) {
            return '';
        }

        return (
            <span>
                <Checkbox
                    value={String(row[field as keyof IndexedCoacheeData])}
                    checked={selectedCoachees.some(
                        (coachee) => coachee.index === row.index
                    )}
                    onChange={(value) => onCheckboxChange(value)}
                >
                    <div>
                        <span>
                            {String(row[field as keyof IndexedCoacheeData])}
                            {renderCoacheeWarnings(row)}
                        </span>
                    </div>
                </Checkbox>
            </span>
        );
    };

    const renderTooltip = (
        row: any | IndexedCoacheeData,
        field: keyof IndexedCoacheeData
    ) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-${field}`}
                content={`${row[field]}`}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row[field]}</span>
            </Tooltip>
        );
    };

    const filterData = () => {
        let updatedFilteredData = coachees;

        if (showWithoutCareerCoach) {
            updatedFilteredData = updatedFilteredData.filter(
                (item) => !item.careerCoach?.name
            );
        }

        if (showWithWarning) {
            updatedFilteredData = updatedFilteredData.filter(
                (item) => item.warnings && item.warnings.length > 0
            );
        }

        if (searchString) {
            const lowercaseSearch = searchString.toLowerCase();
            updatedFilteredData = updatedFilteredData.filter(
                (item) =>
                    item.name?.toLowerCase().includes(lowercaseSearch) ||
                    item.costCenter?.toLowerCase().includes(lowercaseSearch) ||
                    item.grade?.toLowerCase().includes(lowercaseSearch)
            );
        }

        setFilteredData(updatedFilteredData);

        const selectedCoachees = updatedFilteredData.map((coachee) => ({
            index: coachee.index,
            data: coachee,
        }));

        setSelectedCoachees(selectedCoachees);

        // On change of filters, set to page 1
        setCurrentPage(1);
    };

    useEffect(() => {
        const selectedCoachees = coachees.map((coachee) => ({
            index: coachee.index,
            data: coachee,
        }));
        setSelectedCoachees(selectedCoachees);
    }, [coachees]);

    useEffect(() => {
        filterData();
    }, [showWithoutCareerCoach, showWithWarning, searchString, coachees]);

    const handleWithoutCareerCoachClick = () => {
        if (!showWithoutCareerCoach && filteredData.length === 0) {
            return;
        }

        setShowWithoutCareerCoach(!showWithoutCareerCoach);
        filterData();
    };

    const handleWithWarningClick = () => {
        if (!showWithWarning && filteredData.length === 0) {
            return;
        }

        setShowWithWarning(!showWithWarning);
        filterData();
    };

    const onCheckTotalChange = (
        value: boolean,
        event: React.SyntheticEvent
    ) => {
        if (
            (
                (event.target as HTMLElement).parentNode as HTMLElement
            ).className.indexOf('ap-checkbox-label') > -1
        ) {
            return;
        }

        if (value) {
            const selectedCoachees = filteredData.map((item) => ({
                index: item.index,
                data: item,
            }));
            setSelectedCoachees(selectedCoachees);
        } else {
            setSelectedCoachees([]);
        }

        setCheckValue(value);
    };

    useEffect(() => {
        if (selectedCoachees && filteredData) {
            if (selectedCoachees.length === filteredData.length) {
                setCheckValue(true);
            } else {
                setCheckValue(false);
            }
        }
    }, [selectedCoachees, filteredData]);

    useEffect(() => {
        onSelectedCoacheesChange(selectedCoachees);
    }, [selectedCoachees, onSelectedCoacheesChange]);

    useEffect(() => {
        const maxPageNum = filteredData
            ? Math.ceil(filteredData.length / itemsPerPage)
            : null;

        if (filteredData && maxPageNum && currentPage > maxPageNum) {
            setCurrentPage(maxPageNum);
        }

        setTotal(
            filteredData ? Math.ceil(filteredData.length / itemsPerPage) : 0
        );
    }, [filteredData, itemsPerPage, currentPage]);

    const renderNotesCell = (row: IndexedCoacheeData) => {
        return (
            <div style={{ display: 'flex', maxWidth: '12rem' }}>
                <div
                    className="notes-container"
                    style={{
                        maxWidth: '10rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {row.notes}
                </div>
                <span
                    className="Appkit4-icon icon-edit-outline"
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        color: '#D04A02',
                    }}
                    onClick={() => {
                        setDisplayEditNotesModal(true);
                        setSelectedCoachee(row);
                    }}
                />
            </div>
        );
    };

    const editNotes = async (value: string) => {
        await updateNotes.mutate(
            {
                allocationId: allocationId,
                data: {
                    id: selectedCoachee?.id,
                    notes: value,
                    isCoachee: true,
                },
            },
            {
                onSuccess: async () => {
                    showToast(l('_success'), '', 'success');
                    setDisplayEditNotesModal(false);

                    const updatedCoachees = filteredData.map((coachee) =>
                        coachee.id === selectedCoachee?.id
                            ? { ...coachee, notes: value }
                            : coachee
                    );

                    setFilteredData(updatedCoachees);
                    onSelectedCoacheesChange(updatedCoachees);
                    onUpdateNotes(selectedCoachee?.id || '', value);
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    return (
        <div>
            <Panel className="preallocation-panel-wrapper">
                <div className="ap-table-coachees">
                    <div>
                        <div className="search-sample">
                            <GlobalSearch
                                onSearch={(searchString) => {
                                    setSearchString(searchString);
                                    filterData();
                                }}
                            />
                            <div className="filter-pills">
                                <Badge
                                    className={`preallocation-pill-one ${
                                        showWithoutCareerCoach
                                            ? 'clicked'
                                            : filteredData.length === 0
                                            ? 'pill-disabled'
                                            : ''
                                    }`}
                                    role="button"
                                    onClick={handleWithoutCareerCoachClick}
                                    value={l('_withoutCareerCoachFilter')}
                                ></Badge>
                                <Badge
                                    className={`preallocation-pill-two ${
                                        showWithWarning
                                            ? 'clicked'
                                            : filteredData.length === 0
                                            ? 'pill-disabled'
                                            : ''
                                    }`}
                                    role="button"
                                    onClick={handleWithWarningClick}
                                    value={l('_withWarningFilter')}
                                >
                                    <button
                                        onClick={handleWithWarningClick}
                                    ></button>
                                </Badge>
                            </div>
                        </div>
                    </div>
                    <div className="coachees-checkboxes-component">
                        <h4>
                            Selected: {selectedCoachees?.length} of{' '}
                            {filteredData.length}
                        </h4>
                    </div>
                    <div className="coachees-table-container">
                        <Table
                            className="ap-table-checkable-coachees"
                            originalData={filteredData}
                            hasTitle
                            selectedIndexs={selectedCoachees.map(
                                (item) => item.index
                            )}
                            currentPage={currentPage}
                            pageSize={itemsPerPage}
                        >
                            <Column
                                field="name"
                                sortKey="name"
                                sortFunc1={(a: any, b: any) => {
                                    return a.name.localeCompare(b.name);
                                }}
                                sortFunc2={(a: any, b: any) => {
                                    return b.name.localeCompare(a.name);
                                }}
                                renderCell={renderNameCell}
                            >
                                <Checkbox
                                    checked={checkValue}
                                    onChange={(value, event) =>
                                        onCheckTotalChange(value, event)
                                    }
                                >
                                    <span>{l('_name')}</span>
                                </Checkbox>
                            </Column>
                            <Column
                                field="careerCoach"
                                sortKey="careerCoach"
                                sortFunc1={(a: any, b: any) => {
                                    return a.careerCoach.name.localeCompare(
                                        b.careerCoach.name
                                    );
                                }}
                                sortFunc2={(a: any, b: any) => {
                                    return b.careerCoach.name.localeCompare(
                                        a.careerCoach.name
                                    );
                                }}
                                renderCell={(row) => {
                                    if (row.careerCoach.name == 0) {
                                        return (
                                            <span className="Appkit4-icon icon-minus-fill" />
                                        );
                                    }
                                    return row.careerCoach.name;
                                }}
                            >
                                {l('_currentCoach')}
                            </Column>
                            <Column
                                field="relationshipLeader"
                                sortKey="relationshipLeader"
                                sortFunc1={(a: any, b: any) => {
                                    return a.relationshipLeader.localeCompare(
                                        b.relationshipLeader
                                    );
                                }}
                                sortFunc2={(a: any, b: any) => {
                                    return b.relationshipLeader.localeCompare(
                                        a.relationshipLeader
                                    );
                                }}
                            >
                                {l('_relationshipLeader')}
                            </Column>
                            <Column
                                field="grade"
                                sortKey="grade"
                                renderCell={(row) =>
                                    renderTooltip(row, 'grade')
                                }
                            >
                                {l('_coacheeGrade')}
                            </Column>
                            <Column
                                field="costCenter"
                                sortKey="costCenter"
                                renderCell={(row) =>
                                    renderTooltip(row, 'costCenter')
                                }
                            >
                                {l('_costCentre')}
                            </Column>
                            <Column
                                field="email"
                                sortKey="email"
                                renderCell={(row) =>
                                    renderTooltip(row, 'email')
                                }
                            >
                                {l('_coacheeEmail')}
                            </Column>
                            <Column field="id" sortKey="id">
                                {l('_workdayId')}
                            </Column>
                            <Column field="notes" renderCell={renderNotesCell}>
                                {l('_notes')}
                            </Column>
                        </Table>
                    </div>
                </div>
                {filteredData.length == 0 && (
                    <h5 className="text-center mt-2 ap-text-states-error-03">
                        {l('_noSearchResults')}
                    </h5>
                )}
            </Panel>
            <GlobalPagination
                dropdownValue={dropdownValue}
                currentPage={currentPage}
                totalPages={total}
                onPageChange={onPageChange}
                onDropdownSelect={(selectedValue) => {
                    setDropdownValue(selectedValue);
                    onPageSizeSelect(selectedValue);
                }}
            />
            {displayEditNotesModal && (
                <EditNotesModal
                    isVisible={true}
                    setIsVisible={setDisplayEditNotesModal}
                    onEdit={(value) => {
                        editNotes(value);
                    }}
                    name={selectedCoachee?.name || ''}
                    currentNotes={selectedCoachee?.notes || ''}
                    editNotesModalTitle={l('_coachee')}
                />
            )}
        </div>
    );
};

export default CoacheesTable;
