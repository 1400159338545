import { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { Loading, Notification, toaster } from '@appkit4/react-components';
import useLocale from '../../locale/useLocale';
import {
    useGetV1AllocationStatusAllocationId,
    useGetV1AllocationAllocationAllocationId,
} from '../../api/generated/endpoint';
import {
    AllocationStatus,
    AllocationStatusResponse,
} from '../../api/generated/models';
import { AppRoutes } from '../../Constants';
import AllocationLoadingNotification from '../../components/AllocationLoadingNotification';

const AllocationLoading = () => {
    const { l } = useLocale();
    const location = useLocation();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const hasNavigatedRef = useRef(false);
    const fileNameRef = useRef('');

    // Check if accessed without allocationId
    if (!location.state.allocationId) {
        navigate(AppRoutes.HomePage, { replace: true });
    }

    useEffect(() => {
        return () => {
            if (
                location.pathname === AppRoutes.AllocatingLoading &&
                !hasNavigatedRef.current
            ) {
                toaster.notify(
                    <AllocationLoadingNotification
                        allocationId={location.state.allocationId}
                        fileName={fileNameRef.current}
                        queryClient={queryClient}
                        successNotification={successNotification}
                        failedNotification={failedNotification}
                    />,
                    {
                        position: 'bottomCenter',
                    }
                );
            }
        };
    }, []);

    useGetV1AllocationStatusAllocationId<AllocationStatusResponse>(
        location.state.allocationId,
        {
            query: {
                staleTime: Infinity,
                enabled: true,
                refetchInterval: 30000,
                onSuccess: (response) => {
                    // NUMBER_3 = Completed
                    if (response.status == AllocationStatus.NUMBER_3) {
                        hasNavigatedRef.current = true;
                        navigate(AppRoutes.ReviewAllocation, {
                            replace: true,
                            state: {
                                allocationId: response.id,
                                allocationPublicId: response.publicId,
                            },
                        });
                    } else {
                        // NUMBER_4 = Failed
                        if (response.status == AllocationStatus.NUMBER_4) {
                            hasNavigatedRef.current = true;
                            navigate(AppRoutes.AllocationHistory, {
                                replace: true,
                            });
                        }
                    }
                },
            },
        }
    );

    const fetchAllocationName = useGetV1AllocationAllocationAllocationId(
        location.state.allocationId,
        {
            query: {
                onSuccess: (response) => {
                    const newFileName =
                        response.name !== null && response.name !== undefined
                            ? response.name
                            : l('_defaultFileName');

                    fileNameRef.current = newFileName;
                },
            },
        }
    );

    const successNotification = (response: AllocationStatusResponse) => {
        return (
            <Notification
                closeable={true}
                onClose={() => toaster.closeAll()}
                message={
                    <div>
                        Allocation for {fileNameRef.current} has completed. View
                        in{' '}
                        <a
                            style={{
                                color: '#D04A02',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                navigate(AppRoutes.ReviewAllocation, {
                                    replace: true,
                                    state: {
                                        allocationId: response.id,
                                        allocationPublicId: response.publicId,
                                    },
                                }),
                                    toaster.closeAll();
                            }}
                        >
                            {l('_reviewAllocations')}
                        </a>
                    </div>
                }
                status="success"
            ></Notification>
        );
    };

    const failedNotification = () => {
        return (
            <Notification
                closeable={true}
                onClose={() => toaster.closeAll()}
                message={
                    <div>
                        Allocation for {fileNameRef.current} has failed. View in{' '}
                        <a
                            style={{
                                color: '#D04A02',
                                textDecoration: 'underline',
                                cursor: 'pointer',
                            }}
                            onClick={() => {
                                navigate(AppRoutes.AllocationHistory, {
                                    replace: true,
                                }),
                                    toaster.closeAll();
                            }}
                        >
                            {l('_allocationHistory')}
                        </a>
                    </div>
                }
                status="error"
            ></Notification>
        );
    };

    return (
        <div className="loading-content">
            <h6>{l('_resultsLoading')}</h6>
            <div style={{ maxWidth: '400px', margin: 'auto' }}>
                {l('_allocationContinueToRun')}
            </div>
            <Loading
                style={{ margin: '1rem auto auto auto' }}
                loadingType="linear"
                indeterminate={true}
                compact={false}
            ></Loading>
        </div>
    );
};

export default AllocationLoading;
