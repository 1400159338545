import React from 'react';
import { Column, Table } from '@appkit4/react-components/table';
import { Checkbox } from '@appkit4/react-components/checkbox';
import { ContactPersonResponse } from '../../api/generated/models/contactPersonResponse';
import ContactPersonsManagementActionsCell from '../ContactPersonsManagementActionsCell';
import useLocale from '../../locale/useLocale';

interface SuperAdminBusinessUnitContactPersonTableProps {
    contactPersons: ContactPersonResponse[];
    // Function to manage the state of the Edit Contact Person modal
    setDisplayEditContactPersonModalForId: React.Dispatch<
        React.SetStateAction<number | null>
    >;
    // Function to manage the state of the Delete Contact Person confirmation
    setShowDeleteContactPersonConfirmationForId: React.Dispatch<
        React.SetStateAction<number | null>
    >;
    selectedContactIds: number[];
    updateSelectedContactIds: (selectedContactIds: number[]) => void;
}

const SuperAdminBusinessUnitContactPersonTable = (
    props: SuperAdminBusinessUnitContactPersonTableProps
) => {
    const { l } = useLocale();

    const actionsColumnStyle: React.CSSProperties = {
        width: '6rem',
    };

    const renderCheckboxCell = (row: ContactPersonResponse) => {
        const onCheckboxChange = (value: boolean) => {
            if (value) {
                props.updateSelectedContactIds([
                    ...props.selectedContactIds,
                    row.id,
                ]);
            } else {
                props.updateSelectedContactIds(
                    props.selectedContactIds.filter((id) => id != row.id)
                );
            }
        };

        return (
            <Checkbox
                checked={props.selectedContactIds.includes(row.id)}
                onChange={onCheckboxChange}
            />
        );
    };

    return (
        <Table
            className="contacts-table-container admin-table-container"
            originalData={props.contactPersons}
            condensed
            striped
            hasTitle
        >
            <Column field="id" renderCell={renderCheckboxCell}></Column>
            <Column field="forenames">{l('_forename(s)')}</Column>
            <Column field="surname">{l('_surname')}</Column>
            <Column field="email">{l('_email')}</Column>
            <Column
                renderCell={(row: ContactPersonResponse) => (
                    <ContactPersonsManagementActionsCell
                        user={row}
                        displayEditContactModal={() =>
                            props.setDisplayEditContactPersonModalForId(row.id)
                        }
                        displayDeleteContactModal={() =>
                            props.setShowDeleteContactPersonConfirmationForId(
                                row.id
                            )
                        }
                    />
                )}
                field="id"
                style={actionsColumnStyle}
            >
                {l('_actions')}
            </Column>
        </Table>
    );
};

export default SuperAdminBusinessUnitContactPersonTable;
