import React, { useState } from 'react';
import { Button } from '@appkit4/react-components';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { ContactPersonResponse } from '../../api/generated/models';
import useLocale from '../../locale/useLocale';
import SuperAdminCreateContactPersonModal from './SuperAdminCreateContactPersonModal';
import SuperAdminBusinessUnitContactPersonTable from './SuperAdminBusinessUnitContactPersonTable';

interface SuperAdminCreateBusinessUnitFormProps {
    submitFunc: (values: FormValues) => void;
    contactPersons: ContactPersonResponse[];
    setDisplayEditContactPersonModalForId: React.Dispatch<
        React.SetStateAction<number | null>
    >;
    setShowDeleteContactPersonConfirmationForId: React.Dispatch<
        React.SetStateAction<number | null>
    >;
}

export interface FormValues {
    name: string;
    contactPersonIds: number[];
}

const SuperAdminCreateBusinessUnitForm = (
    props: SuperAdminCreateBusinessUnitFormProps
) => {
    const { l } = useLocale();

    const [
        displayCreateContactPersonModal,
        setDisplayCreateContactPersonModal,
    ] = useState(false);

    const NewBusinessUnitSchema = Yup.object({
        name: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterABusinessUnitName')),
        contactPersonIds: Yup.array().of(Yup.number()),
    });

    return (
        <Formik
            initialValues={{
                name: '',
                contactPersonIds: [],
            }}
            validationSchema={NewBusinessUnitSchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
                values,
            }) => (
                <Form>
                    <Input
                        type="text"
                        title={l('_businessUnitName')}
                        placeholder={l('_businessUnitName')}
                        required
                        onChange={(value: string) => {
                            setFieldTouched('name');
                            validateField('name');
                            setFieldValue('name', value);
                        }}
                    />
                    {touched.name && errors.name && (
                        <div className="ap-text-states-error-04">
                            {errors.name}
                        </div>
                    )}
                    <div className="row mt-3">
                        <div className="col">
                            <h2 className="ap-typography-heading-s">
                                {l('_contactPersons')}
                            </h2>
                        </div>
                        <div className="col">
                            <Button
                                kind="secondary"
                                icon="icon-plus-outline"
                                type="button"
                                role="button"
                                onClick={() =>
                                    setDisplayCreateContactPersonModal(true)
                                }
                                className="ms-auto align-top h-75"
                                style={{ paddingTop: '12px' }}
                            >
                                {l('_newContactPerson')}
                            </Button>
                        </div>
                    </div>

                    <div className="row" style={{ alignItems: 'center' }}>
                        <div className="col">
                            <SuperAdminBusinessUnitContactPersonTable
                                contactPersons={props.contactPersons}
                                setDisplayEditContactPersonModalForId={
                                    props.setDisplayEditContactPersonModalForId
                                }
                                setShowDeleteContactPersonConfirmationForId={
                                    props.setShowDeleteContactPersonConfirmationForId
                                }
                                selectedContactIds={values.contactPersonIds}
                                updateSelectedContactIds={(
                                    selectedContactIds: number[]
                                ) =>
                                    setFieldValue(
                                        'contactPersonIds',
                                        selectedContactIds
                                    )
                                }
                            />
                        </div>
                    </div>
                    <Button className="ms-auto mt-3" type="submit">
                        {l('_createNewBusinessUnit')}
                    </Button>

                    {displayCreateContactPersonModal && (
                        <SuperAdminCreateContactPersonModal
                            isVisible={displayCreateContactPersonModal}
                            setNotVisible={() =>
                                setDisplayCreateContactPersonModal(false)
                            }
                            onCreationSuccess={(
                                newContact: ContactPersonResponse
                            ) => {
                                setFieldValue('contactPersonIds', [
                                    ...values.contactPersonIds,
                                    newContact.id,
                                ]);
                            }}
                        />
                    )}
                </Form>
            )}
        </Formik>
    );
};

export default SuperAdminCreateBusinessUnitForm;
