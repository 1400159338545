import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import useLocale from '../../locale/useLocale';

interface SuperAdminRenameBusinessUnitFormProps {
    submitFunc: (values: FormValues) => void;
    initialName: string;
}

export interface FormValues {
    name: string;
}

const SuperAdminRenameBusinessUnitForm = (
    props: SuperAdminRenameBusinessUnitFormProps
) => {
    const { l } = useLocale();

    const RenameBusinessUnitSchema = Yup.object({
        name: Yup.string()
            .trim()
            .max(100, l('_pleaseEnterFewerCharacters'))
            .required(l('_pleaseEnterABusinessUnitName')),
    });

    return (
        <Formik
            initialValues={{
                name: props.initialName || '',
            }}
            validationSchema={RenameBusinessUnitSchema}
            onSubmit={(values) => props.submitFunc(values)}
        >
            {({
                values,
                errors,
                touched,
                setFieldTouched,
                setFieldValue,
                validateField,
            }) => (
                <Form>
                    <div className="row focus-margin">
                        <div className="col">
                            <Input
                                type="text"
                                title={l('_businessUnitName')}
                                placeholder={l('_businessUnitName')}
                                required
                                value={values.name}
                                onChange={(value: string) => {
                                    setFieldTouched('name');
                                    validateField('name');
                                    setFieldValue('name', value);
                                }}
                            />
                            {touched.name && errors.name && (
                                <div className="errorText">{errors.name}</div>
                            )}
                        </div>

                        <div className="col-auto">
                            <Button
                                className="ms-auto align-top h-100"
                                style={{ paddingTop: '12px' }}
                                type="submit"
                            >
                                {l('_renameBusinessUnit')}
                            </Button>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SuperAdminRenameBusinessUnitForm;
