import React, { useState, FC } from 'react';
import {
    Table,
    Column,
    Loading,
    Search,
    Button,
    Tooltip,
    Pagination,
    Select,
} from '@appkit4/react-components';
import useGenericErrorToast from '../../helpers/useGenericErrorToast';
import {
    UserResponse,
    PaginatedBusinessUnitResponse,
} from '../../api/generated/models';
import AdminConfirmDeleteUserModal from '../../components/AdminConfirmDeleteUserModal';
import AdminCreateUserModal from '../../components/AdminCreateUserModal';
import Roles from '../../auth/roles';
import AdminEditUserModal from '../../components/AdminEditUserModal';
import UserManagementActionsCell from '../../components/UserManagementActionsCell';
import './AdminPanel.scss';
import useLocale from '../../locale/useLocale';
import {
    useGetV1IdentityUserInfo,
    useGetV1BusinessUnitSuperAdmin,
    useGetV1UserAdmin,
} from '../../api/generated/endpoint';
import usePaginationControl from '../../helpers/usePaginationControl';
import useTitle from '../../helpers/useTitle';

interface UserManagementProps {
    selectedBusinessUnitId: number | undefined;
    setSelectedBusinessUnitId: (value: number) => void;
}

const UserManagement: FC<UserManagementProps> = (props) => {
    const { l } = useLocale();
    useTitle(l('_userManagementTitleCase'));

    const [showDeleteConfirmationForId, setShowDeleteConfirmationForId] =
        useState<number | null>(null);
    const [displayCreateUserModal, setDisplayCreateUserModal] = useState(false);
    const [displayEditUserModalForId, setDisplayEditUserModalForId] = useState<
        number | null
    >(null);
    const [searchString, setSearchString] = useState<string>('');
    const pageSize = 20;
    const [pageNum, setPageNum] = useState<number>(1);
    const skip = (pageNum - 1) * pageSize;
    const take = pageSize;
    const showGenericErrorToast = useGenericErrorToast();

    /* Load current user info */
    const { data: userInfo } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    /* Load list of business unit for super admin users only */
    const { data: paginatedBusinessUnits } = useGetV1BusinessUnitSuperAdmin(
        {},
        {
            query: {
                enabled: userInfo?.roleId === Roles.SuperAdminId,
                onSuccess: (data: PaginatedBusinessUnitResponse) => {
                    const firstBusinessUnitId = data.businessUnits.at(0)?.id;
                    if (
                        firstBusinessUnitId &&
                        data.totalBusinessUnitCount === 1
                    ) {
                        props.setSelectedBusinessUnitId(firstBusinessUnitId);
                    }
                },
                onError: showGenericErrorToast,
            },
        }
    );

    /* Load info for all users of current business unit (or selected business unit in case of super admin) */
    const { data: paginatedUserResponse, isLoading: loadingUsers } =
        useGetV1UserAdmin(
            {
                skip,
                take,
                searchString,
                businessUnitId: props.selectedBusinessUnitId,
            },
            {
                query: {
                    enabled: !(
                        userInfo?.roleId === Roles.SuperAdminId &&
                        props.selectedBusinessUnitId === undefined
                    ),
                    onError: showGenericErrorToast,
                },
            }
        );

    /* prevent current page number being invalid */
    usePaginationControl(
        { totalCount: paginatedUserResponse?.totalUserCount },
        pageSize,
        setPageNum,
        pageNum
    );

    let typingTimer: NodeJS.Timeout; //timer identifier
    const doneTypingIntervalms = 1000; //time in ms

    const renderTooltip = (row: UserResponse, field: keyof UserResponse) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-${field}`}
                content={`${row[field]}`}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row[field]}</span>
            </Tooltip>
        );
    };

    const businessUnitSelectItemTemplate = (label: React.ReactNode) => (
        <button className="dropdown-button">{label}</button>
    );

    const actionsColumnStyle: React.CSSProperties = {
        width: '6rem',
    };

    return (
        <>
            {userInfo?.roleId === Roles.AdminId && (
                <h1 aria-label="user-management-title">
                    {l('_userManagementTitleCase')}
                </h1>
            )}
            {userInfo?.roleId === Roles.SuperAdminId && (
                <div className="row mt-4">
                    <div className="col">
                        <Select
                            id="businessunit-select"
                            data={paginatedBusinessUnits?.businessUnits}
                            value={props.selectedBusinessUnitId || ''}
                            disabled={
                                paginatedBusinessUnits != undefined &&
                                paginatedBusinessUnits.totalBusinessUnitCount <
                                    2
                            }
                            onSelect={(value) =>
                                props.setSelectedBusinessUnitId(value as number)
                            }
                            itemTemplate={businessUnitSelectItemTemplate}
                            valueKey="id"
                            labelKey="name"
                            placeholder={l('_selectBusinessUnit')}
                            searchable
                            noResultFound={l('_noBusinessUnitsFound')}
                            dropdownMatchWidth={false}
                            style={{
                                maxWidth: '50%',
                                minWidth: '200px',
                            }}
                        />
                    </div>
                    <div className="col-auto">
                        {/* super admin must select a business unit before displaying table of users*/}
                        {userInfo?.roleId === Roles.SuperAdminId &&
                            props.selectedBusinessUnitId === undefined && (
                                <div className="d-flex pt-2">
                                    <span className="pwc-brand-icon icon-lightbulb icons" />
                                    <p className="p-0 mb-0 ps-1">
                                        {l(
                                            '_mustSelectBusinessUnitToViewUsers'
                                        )}
                                    </p>
                                </div>
                            )}
                    </div>
                </div>
            )}
            {/* Only display search and add user components once a business unit has been selected (for super admin only)*/}
            {(userInfo?.roleId != Roles.SuperAdminId ||
                props.selectedBusinessUnitId != undefined) && (
                <div className="row mt-2">
                    <div className="col">
                        {!displayEditUserModalForId &&
                            !showDeleteConfirmationForId && (
                                <Search
                                    id="user-search"
                                    searchType="primary"
                                    placeholder={l('_search')}
                                    disabled={
                                        loadingUsers ||
                                        (userInfo?.roleId ===
                                            Roles.SuperAdminId &&
                                            props.selectedBusinessUnitId ===
                                                undefined)
                                    }
                                    onSearch={(searchString) =>
                                        setSearchString(searchString)
                                    }
                                    onChange={(query: string, event) => {
                                        clearTimeout(typingTimer);
                                        if (!query) {
                                            setSearchString('');
                                        } else {
                                            typingTimer = setTimeout(() => {
                                                // simulate pressing enter on the search box
                                                // so the onSearch method handles all the actual searching
                                                event.target.dispatchEvent(
                                                    new KeyboardEvent(
                                                        'keydown',
                                                        {
                                                            code: 'Enter',
                                                            key: 'Enter',
                                                            charCode: 13,
                                                            keyCode: 13,
                                                            view: window,
                                                            bubbles: true,
                                                        }
                                                    )
                                                );
                                            }, doneTypingIntervalms);
                                        }
                                    }}
                                    onClear={() => setSearchString('')}
                                    className="m-0 p-0"
                                />
                            )}
                    </div>
                    <div className="col">
                        <Button
                            kind="primary"
                            icon="icon-plus-outline"
                            type="button"
                            role="button"
                            disabled={
                                loadingUsers ||
                                (userInfo?.roleId === Roles.SuperAdminId &&
                                    props.selectedBusinessUnitId === undefined)
                            }
                            onClick={() => setDisplayCreateUserModal(true)}
                            className="ms-auto align-top h-100"
                            style={{ paddingTop: '12px' }}
                        >
                            {l('_addUser')}
                        </Button>
                    </div>
                </div>
            )}
            <div>
                <Table
                    className="ap-mt-spacing-4 admin-table-container"
                    originalData={paginatedUserResponse?.users ?? []}
                    condensed
                    striped
                    hasTitle
                >
                    <Column
                        field="forenames"
                        renderCell={(row) => renderTooltip(row, 'forenames')}
                    >
                        {l('_forename(s)')}
                    </Column>
                    <Column
                        field="surname"
                        renderCell={(row) => renderTooltip(row, 'surname')}
                    >
                        {l('_surname')}
                    </Column>
                    <Column
                        field="email"
                        renderCell={(row) => renderTooltip(row, 'email')}
                    >
                        {l('_email')}
                    </Column>
                    <Column
                        field="roleName"
                        renderCell={(row) => renderTooltip(row, 'roleName')}
                    >
                        {l('_role')}
                    </Column>
                    <Column
                        renderCell={(row: UserResponse) => (
                            <UserManagementActionsCell
                                user={row}
                                displayEditUserModal={() =>
                                    setDisplayEditUserModalForId(row.id)
                                }
                                displayDeleteUserModal={() =>
                                    setShowDeleteConfirmationForId(row.id)
                                }
                            />
                        )}
                        field="id"
                        style={actionsColumnStyle}
                    >
                        {l('_actions')}
                    </Column>
                </Table>
                {props.selectedBusinessUnitId != undefined && loadingUsers && (
                    <Loading
                        loadingType="circular"
                        indeterminate
                        compact={false}
                        className="mt-2"
                    />
                )}

                {/* Display message if no users found from search */}
                {!loadingUsers &&
                    (!paginatedUserResponse ||
                        paginatedUserResponse?.users.length == 0) && (
                        <h5 className="text-center mt-2 ap-text-states-error-04">
                            {l('_noUsersFound')}
                        </h5>
                    )}
                {!loadingUsers &&
                    paginatedUserResponse &&
                    paginatedUserResponse.users.length > 0 && (
                        <Pagination
                            data-testid="pagination"
                            total={Math.ceil(
                                paginatedUserResponse.totalUserCount / pageSize
                            )}
                            current={pageNum}
                            onPageChange={(page: number) => setPageNum(page)}
                            style={{ float: 'right' }}
                        />
                    )}
            </div>

            {displayCreateUserModal && (
                <AdminCreateUserModal
                    isVisible={displayCreateUserModal}
                    setIsVisible={setDisplayCreateUserModal}
                    businessUnits={
                        paginatedBusinessUnits?.businessUnits ?? null
                    }
                    currentBusinessUnitId={
                        props.selectedBusinessUnitId ?? userInfo?.businessUnitId
                    }
                />
            )}

            {displayEditUserModalForId && (
                <AdminEditUserModal
                    isVisible={displayEditUserModalForId != null}
                    setNotVisible={() => setDisplayEditUserModalForId(null)}
                    user={paginatedUserResponse?.users.find(
                        (u) => u.id == displayEditUserModalForId
                    )}
                />
            )}

            {showDeleteConfirmationForId && (
                <AdminConfirmDeleteUserModal
                    visible={showDeleteConfirmationForId != null}
                    userInfo={paginatedUserResponse?.users.find(
                        (u) => u.id == showDeleteConfirmationForId
                    )}
                    setNotVisible={() => setShowDeleteConfirmationForId(null)}
                />
            )}
        </>
    );
};

export default UserManagement;
