import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const ai: ApplicationInsights = new ApplicationInsights({
    config: {
        connectionString:
            process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory },
        },
        enableAutoRouteTracking: true,
    },
});

const appInsightsEnabled =
    process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING &&
    process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION_STRING.trim() !== '';

if (appInsightsEnabled) {
    ai.loadAppInsights();
    ai.context.session.id = undefined;
}

const setSessionId = (newId: string | undefined) => {
    if (appInsightsEnabled) {
        ai.context.session.id = newId;
    }
};

export const appInsights = ai.appInsights;
export { reactPlugin, setSessionId };
