import React from 'react';
import { useAuth } from 'react-oidc-context';
import { AppRoutes, LoginRedirectStorageKey } from '../../Constants';
import { Navigate } from 'react-router-dom';
import { Loading } from '@appkit4/react-components/loading';
import useToast from '../../helpers/useToast';
import useLocale from '../../locale/useLocale';

const LoginCallback = () => {
    const auth = useAuth();
    const showToast = useToast();
    const { l } = useLocale();

    if (auth.isLoading) {
        return <Loading loadingType="circular" indeterminate compact={false} />;
    }

    if (auth.error) {
        showToast(
            l('_error'),
            l('_authenticationErrorPleaseLoginAgain'),
            'error'
        );
        return <Navigate to={AppRoutes.LogoutPage} replace />;
    }

    if (!auth.isAuthenticated) {
        return <Navigate to={AppRoutes.LandingPage} replace />;
    }

    const returnRoute = window.sessionStorage.getItem(LoginRedirectStorageKey);
    if (returnRoute) {
        return <Navigate to={returnRoute} />;
    }
    return <Navigate to={AppRoutes.HomePage} replace />;
};

export default LoginCallback;
