import React, { FC, useState } from 'react';
import { Loading } from '@appkit4/react-components/loading';
import { Outlet, useNavigate } from 'react-router-dom';
import { AppRoutes } from '../Constants';
import { GenericError } from '../pages';
import CookiesModal from '../components/CookiesModal';
import TandCModal from '../components/TandCModal';
import { useAuth } from 'react-oidc-context';
import useToast from './useToast';
import { useGetV1IdentityUserInfo } from '../api/generated/endpoint';

type UserInfoWrapperProps = { children?: JSX.Element };

// executes before each page request to ensure that user is authenticated
const UserInfoWrapper: FC<UserInfoWrapperProps> = ({ children }) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const showToast = useToast();

    const [tandcVisible, setTandcVisible] = useState(false);
    const [tandcReady, setTandcReady] = useState(false); //this is needed to check if tandc modal has downloaded all data and is ready to be displayed, only if tandc is ready and not visible, cookies modal should be visible
    const [cookiesVisible, setCookiesVisible] = useState(false);

    const {
        data: userData,
        error: userError,
        isLoading: userDataLoading,
    } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    if (userError) {
        switch (userError.response?.status) {
            case 403:
                navigate(AppRoutes.Unauthorized);
                break;
            case 401:
                showToast(
                    'Authentication error',
                    'Your login session is invalid',
                    'error'
                );
                // manually remove user as unable to access api to revoke
                // (so normal logout flow doesnt apply)
                auth.removeUser();
                navigate(AppRoutes.LandingPage);
                break;
            default:
                return (
                    <GenericError
                        message={`The following error occurred while fetching user info:
                                ${
                                    userError.response?.data ||
                                    'server unreachable'
                                }`}
                    />
                );
        }
    }

    if (userDataLoading) {
        return <Loading loadingType="circular" indeterminate compact={false} />;
    }

    return (
        <>
            <TandCModal
                visible={tandcVisible}
                setVisible={(newValue: boolean) => {
                    setTandcVisible(newValue);
                }}
                setTandcReady={(newValue: boolean) => {
                    setTandcReady(newValue);
                }}
            />
            <CookiesModal
                visible={cookiesVisible && tandcReady && !tandcVisible}
                setVisible={(newValue: boolean) => {
                    setCookiesVisible(newValue);
                }}
            />
            {userData && (children || <Outlet />)}
        </>
    );
};

export default UserInfoWrapper;
