import { FC } from 'react';
import { Pagination, Select } from '@appkit4/react-components';
import { SelectValue } from '@appkit4/react-components/esm/select/Select';
import useLocale from '../locale/useLocale';

import './GlobalPagination.scss';

interface GlobalPaginationProps {
    dropdownValue: SelectValue;
    currentPage: number;
    totalPages: number;
    onPageChange: (page: number) => void;
    onDropdownSelect: (selectValue: SelectValue) => void;
}

const GlobalPagination: FC<GlobalPaginationProps> = ({
    dropdownValue,
    currentPage,
    totalPages,
    onPageChange,
    onDropdownSelect,
}) => {
    const { l } = useLocale();

    const pageSize = [
        { value: 10, label: '10' },
        { value: 20, label: '20' },
        { value: 30, label: '30' },
        { value: 40, label: '40' },
        { value: 50, label: '50' },
    ];

    return (
        <div className="global-pagination-container">
            <div style={{ display: 'table-cell' }}>
                <div className="global-pagination-dropdown">
                    <Select
                        data={pageSize}
                        value={dropdownValue}
                        searchable={false}
                        onSelect={(selectedValue) => {
                            onDropdownSelect(selectedValue);
                        }}
                    ></Select>
                    <div style={{ marginLeft: '10px' }}>
                        {l('_paginationDropdownPlaceholder')}
                    </div>
                </div>
            </div>
            <div style={{ display: 'table-cell' }}>
                <Pagination
                    className="pagination-component"
                    current={currentPage}
                    total={totalPages}
                    onPageChange={(page) => {
                        onPageChange(page);
                    }}
                />
            </div>
        </div>
    );
};

export default GlobalPagination;
