import React from 'react';
import { Button } from '@appkit4/react-components/button';
import { useNavigate } from 'react-router-dom';
import { AppRoutes } from '../../Constants';
import NotFoundImg from '../../assets/illustrations/404NotFound.png';
import './styles.scss';
import useLocale from '../../locale/useLocale';
import useTitle from '../../helpers/useTitle';

export const NotFound = () => {
    const navigate = useNavigate();
    const { l } = useLocale();
    useTitle(l('_error'));

    return (
        <div className="h-100 position-relative">
            <div className="d-flex align-items-center flex-column justify-content-center text-center">
                <img
                    src={NotFoundImg as string}
                    alt={l('_laptopNotWorking')}
                    className="main-image"
                />
                <h1 className="px-2 py-1 mt-2">{l('_errorPageNotFound')}</h1>
                <p>
                    {l('_thePage')} <em>{window.location.pathname}</em>{' '}
                    {l('_wasNotFound')}
                </p>
                <div className="d-flex">
                    {/* eslint-disable-next-line no-restricted-globals  */}
                    <Button className="me-4" onClick={() => history.back()}>
                        <i className="Appkit4-icon icon-arrow-left-fill" />
                        {l('_back')}
                    </Button>
                    <Button onClick={() => navigate(AppRoutes.HomePage)}>
                        <i className="Appkit4-icon icon-home-fill" />
                        {l('_home')}
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default NotFound;
