/* eslint-disable @typescript-eslint/no-explicit-any */
import { useTranslation } from 'react-i18next';
import { translationKeys } from '../locale/i18nSetup';

// This is basically just a wrapper around the react-i18next hook but with added "type" safety.
export default function useLocale(): { l: typeof localise; i18n: typeof i18n } {
    const { t, i18n } = useTranslation();

    const localise = (
        keys: translationKeys | translationKeys[],
        opts?: any
    ): any => {
        if (opts === undefined) {
            return t(keys);
        } else {
            return t(keys, opts);
        }
    };

    return {
        l: localise,
        i18n,
    };
}
