import React, { useState, useEffect, FC } from 'react';
import {
    Table,
    Column,
    Checkbox,
    Tooltip,
    Button,
    Panel,
} from '@appkit4/react-components';
import { IndexedCoachData } from '../../../models/indexedCoachData';
import useLocale from '../../../locale/useLocale';
import GlobalSearch from '../../GlobalSearch';
import GlobalPagination from '../../GlobalPagination';
import { usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationId } from '../../../api/generated/endpoint';
import useToast from '../../../helpers/useToast';

import './CoachesTable.scss';
import EditNotesModal from '../../EditNotesModal';

type ValueType = string | number;
type SelectValue = ValueType | ValueType[];

interface CoachesTableProps {
    coaches: IndexedCoachData[];
    loadedUserBusinessUnitInfo: boolean;
    setDisplayAdjustCareerCoachCapacityModal: (visible: boolean) => void;
    onSelectedCoachesChange: (selectedCoachIndexes: IndexedCoachData[]) => void;
    allocationId: number;
    onUpdateNotes: (coacheeId: string, notes: string) => void;
}

const CoachesTable: FC<CoachesTableProps> = ({
    coaches,
    loadedUserBusinessUnitInfo,
    setDisplayAdjustCareerCoachCapacityModal,
    onSelectedCoachesChange,
    allocationId,
    onUpdateNotes,
}) => {
    const { l } = useLocale();

    const showToast = useToast();

    const updateNotes =
        usePutV1AllocationAllocationUpdatePreAllocationNotesAllocationId();

    const [selectedCoaches, setSelectedCoaches] = useState<IndexedCoachData[]>(
        []
    );

    const [displayEditNotesModal, setDisplayEditNotesModal] = useState(false);

    const [selectedCoach, setSelectedCoach] = useState<IndexedCoachData>();

    const [searchString, setSearchString] = useState<string>('');

    const [checkValue, setCheckValue] = React.useState(false);

    const [dropdownValue, setDropdownValue] = React.useState<SelectValue>(10);

    const [filteredData, setFilteredData] = useState(coaches);

    const [currentPage, setCurrentPage] = React.useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = React.useState<number>(10);
    const [total, setTotal] = useState(0);

    const onPageChange = (page: number) => {
        setCurrentPage(page);
    };

    const onPageSizeSelect = (value: SelectValue) => {
        const selectedPageSize = Number(value);

        setItemsPerPage(selectedPageSize);
        setCurrentPage(1);
    };

    const renderNameCell = (row: IndexedCoachData, field: string) => {
        const onCheckboxChange = (value: boolean) => {
            if (value) {
                setSelectedCoaches((prev: IndexedCoachData[]) => [
                    ...prev,
                    row,
                ]);
            } else {
                setSelectedCoaches((prev: IndexedCoachData[]) =>
                    prev.filter((coachee) => coachee.index !== row.index)
                );
            }

            onSelectedCoachesChange(selectedCoaches);
        };

        if (!(field in row)) {
            return '';
        }

        return (
            <span>
                <Checkbox
                    value={String(row[field as keyof IndexedCoachData])}
                    checked={selectedCoaches.some(
                        (coach) => coach.index === row.index
                    )}
                    onChange={(value) => onCheckboxChange(value)}
                >
                    <div>
                        <span>
                            {String(row[field as keyof IndexedCoachData])}
                        </span>
                    </div>
                </Checkbox>
            </span>
        );
    };

    const renderEmploymentTypeCell = (row: IndexedCoachData) => {
        // If no Employment Type, represent as minus symbol
        if (row.employmentType?.length == 0 || !row.employmentType) {
            return <span className="Appkit4-icon icon-minus-fill" />;
        }

        return <span>{row.employmentType}</span>;
    };

    const renderCoacheesCell = (row: IndexedCoachData) => {
        // If no coachees, represent as minus symbol
        if (row.coachees.length == 0) {
            return <span className="Appkit4-icon icon-minus-fill" />;
        }

        // Else render the coachees in a list, with any warnings
        return (
            <>
                {row.coachees?.map((coachee, index) => (
                    <div key={index}>
                        <span>{coachee?.name}</span>
                        {coachee.warnings && coachee.warnings.length > 0 && (
                            <Tooltip
                                content={() => (
                                    <ul>
                                        {coachee.warnings?.map(
                                            (warning, index) => (
                                                <li key={index}>{warning}</li>
                                            )
                                        )}
                                    </ul>
                                )}
                            >
                                <button
                                    className="Appkit4-icon icon-circle-warning-outline"
                                    style={{ color: '#D04A02' }}
                                ></button>
                            </Tooltip>
                        )}
                    </div>
                ))}
            </>
        );
    };

    const renderTooltip = (
        row: any | IndexedCoachData,
        field: keyof IndexedCoachData
    ) => {
        return (
            <Tooltip
                trigger="hover"
                position="top"
                distance={5}
                id={`tooltip-${field}`}
                content={`${row[field]}`}
                style={{
                    wordBreak: 'break-word',
                    overflowWrap: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                <span>{row[field]}</span>
            </Tooltip>
        );
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortFuncForName1 = (a: any, b: any) => {
        return a.name.localeCompare(b?.name);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const sortFuncForName2 = (a: any, b: any) => {
        return b.name.localeCompare(a.name);
    };

    const sortNumberOfCoacheesDesc = (
        a: IndexedCoachData,
        b: IndexedCoachData
    ) => {
        return a.coachees.length < b.coachees.length ? 1 : -1;
    };

    const sortNumberOfCoacheesAsc = (
        a: IndexedCoachData,
        b: IndexedCoachData
    ) => {
        return a.coachees.length > b.coachees.length ? 1 : -1;
    };

    const onCheckTotalChange = (
        value: boolean,
        event: React.SyntheticEvent
    ) => {
        if (
            (
                (event.target as HTMLElement).parentNode as HTMLElement
            ).className.indexOf('ap-checkbox-label') > -1
        ) {
            return;
        }

        if (value) {
            setSelectedCoaches([...filteredData]);
        } else {
            setSelectedCoaches([]);
        }

        setCheckValue(value);
    };

    useEffect(() => {
        onSelectedCoachesChange(selectedCoaches);
    }, [selectedCoaches, onSelectedCoachesChange]);

    useEffect(() => {
        const allSelectedIndexes = filteredData.map((coachee) => coachee);
        setSelectedCoaches(allSelectedIndexes);
    }, [coaches]);

    useEffect(() => {
        if (selectedCoaches && filteredData) {
            if (selectedCoaches.length === filteredData.length) {
                setCheckValue(true);
            } else {
                setCheckValue(false);
            }
        }
    }, [selectedCoaches, filteredData]);

    const filterData = () => {
        let updatedFilteredData = coaches;

        if (searchString) {
            const lowercaseSearch = searchString.toLowerCase();
            updatedFilteredData = updatedFilteredData.filter(
                (item) =>
                    item.name?.toLowerCase().includes(lowercaseSearch) ||
                    item.costCenter?.toLowerCase().includes(lowercaseSearch) ||
                    item.grade?.toLowerCase().includes(lowercaseSearch)
            );
        }

        setFilteredData(updatedFilteredData);

        const selectedCoaches = updatedFilteredData.map((coach) => coach);

        setSelectedCoaches(selectedCoaches);

        // On change of filters, set to page 1
        setCurrentPage(1);
    };

    useEffect(() => {
        filterData();
    }, [coaches, searchString]);

    useEffect(() => {
        const maxPageNum = filteredData
            ? Math.ceil(filteredData.length / itemsPerPage)
            : null;

        if (filteredData && maxPageNum && currentPage > maxPageNum) {
            setCurrentPage(maxPageNum);
        }

        setTotal(
            filteredData ? Math.ceil(filteredData.length / itemsPerPage) : 0
        );
    }, [itemsPerPage, currentPage, filteredData]);

    const renderNotesCell = (row: IndexedCoachData) => {
        return (
            <div style={{ display: 'flex', maxWidth: '12rem' }}>
                <div
                    className="notes-container"
                    style={{
                        maxWidth: '10rem',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {row.notes}
                </div>
                <span
                    className="Appkit4-icon icon-edit-outline"
                    style={{
                        marginLeft: 'auto',
                        cursor: 'pointer',
                        color: '#D04A02',
                    }}
                    onClick={() => {
                        setDisplayEditNotesModal(true);
                        setSelectedCoach(row);
                    }}
                />
            </div>
        );
    };

    const editNotes = async (value: string) => {
        await updateNotes.mutate(
            {
                allocationId: allocationId,
                data: {
                    id: selectedCoach?.id,
                    notes: value,
                    isCoachee: false,
                },
            },
            {
                onSuccess: async () => {
                    showToast(l('_success'), '', 'success');
                    setDisplayEditNotesModal(false);

                    const updatedCoaches = filteredData.map((coach) =>
                        coach.id === selectedCoach?.id
                            ? { ...coach, notes: value }
                            : coach
                    );

                    setFilteredData(updatedCoaches);
                    onSelectedCoachesChange(updatedCoaches);
                    onUpdateNotes(selectedCoach?.id || '', value);
                },
                onError: (error: any) => {
                    showToast(
                        l('_error'),
                        error.response?.data || error.message,
                        'error'
                    );
                },
            }
        );
    };

    return (
        <div>
            <Panel className="preallocation-panel-wrapper">
                <div className="ap-table-coaches">
                    <div>
                        <div className="search-sample">
                            <GlobalSearch
                                onSearch={(searchString) => {
                                    setSearchString(searchString);
                                    filterData();
                                }}
                            />
                            <div className="adjust-coach-capacity-button">
                                <Button
                                    className="adjust-coach-capacity-button-component"
                                    kind="secondary"
                                    icon="icon-audience-outline"
                                    onClick={() => {
                                        setDisplayAdjustCareerCoachCapacityModal(
                                            true
                                        );
                                    }}
                                    loading={!loadedUserBusinessUnitInfo}
                                >
                                    {l('_adjustCoachCapacity')}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className="coaches-checkboxes-component">
                        <h4>
                            Selected: {selectedCoaches?.length} of{' '}
                            {filteredData.length}
                        </h4>
                    </div>
                    <div className="coaches-table-container">
                        <Table
                            className="ap-table-checkable-coaches"
                            originalData={filteredData}
                            hasTitle
                            selectedIndexs={selectedCoaches.map(
                                (item) => item.index
                            )}
                            currentPage={currentPage}
                            pageSize={itemsPerPage}
                        >
                            <Column
                                field="name"
                                sortKey="name"
                                sortFunc1={sortFuncForName1}
                                sortFunc2={sortFuncForName2}
                                renderCell={renderNameCell}
                            >
                                <Checkbox
                                    checked={checkValue}
                                    onChange={(value, event) =>
                                        onCheckTotalChange(value, event)
                                    }
                                >
                                    <span>{l('_name')}</span>
                                </Checkbox>
                            </Column>
                            <Column
                                field="coachees"
                                sortKey="coachees"
                                renderCell={renderCoacheesCell}
                                sortFunc1={sortNumberOfCoacheesDesc}
                                sortFunc2={sortNumberOfCoacheesAsc}
                            >
                                {l('_coachees')}
                            </Column>
                            <Column
                                field="grade"
                                sortKey="grade"
                                renderCell={(row) =>
                                    renderTooltip(row, 'grade')
                                }
                            >
                                {l('_coachGrade')}
                            </Column>
                            <Column
                                field="costCenter"
                                sortKey="costCenter"
                                renderCell={(row) =>
                                    renderTooltip(row, 'costCenter')
                                }
                            >
                                {l('_coachCostCenter')}
                            </Column>
                            <Column
                                field="employmentType"
                                sortKey="employmentType"
                                renderCell={renderEmploymentTypeCell}
                            >
                                {l('_employmentType')}
                            </Column>
                            <Column
                                field="email"
                                sortKey="email"
                                renderCell={(row) =>
                                    renderTooltip(row, 'email')
                                }
                            >
                                {l('_coachEmail')}
                            </Column>
                            <Column field="id" sortKey="id">
                                {l('_coachWorkdayId')}
                            </Column>
                            <Column field="notes" renderCell={renderNotesCell}>
                                {l('_notes')}
                            </Column>
                        </Table>
                    </div>
                </div>
                {filteredData.length == 0 && (
                    <h5 className="text-center mt-2 ap-text-states-error-03">
                        {l('_noSearchResults')}
                    </h5>
                )}
            </Panel>
            <GlobalPagination
                dropdownValue={dropdownValue}
                currentPage={currentPage}
                totalPages={total}
                onPageChange={onPageChange}
                onDropdownSelect={(selectedValue) => {
                    setDropdownValue(selectedValue);
                    onPageSizeSelect(selectedValue);
                }}
            />
            {displayEditNotesModal && (
                <EditNotesModal
                    isVisible={true}
                    setIsVisible={setDisplayEditNotesModal}
                    onEdit={(value) => {
                        editNotes(value);
                    }}
                    name={selectedCoach?.name || ''}
                    currentNotes={selectedCoach?.notes || ''}
                    editNotesModalTitle={l('_careerCoach')}
                />
            )}
        </div>
    );
};

export default CoachesTable;
