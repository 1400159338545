import styled from 'styled-components';
import { Loading, Notification, toaster } from '@appkit4/react-components';
import { useEffect, useState } from 'react';
import { getGetV1AllocationStatusAllocationIdQueryKey } from '../api/generated/endpoint';
import { QueryClient } from '@tanstack/react-query';
import {
    AllocationStatus,
    AllocationStatusResponse,
} from '../api/generated/models';
import useToast from '../helpers/useToast';
import useLocale from '../locale/useLocale';

const StyledAllocationLoading = styled.div`
    .loading-content {
        margin: 10% auto auto auto;
        text-align: center;
    }

    .ap-notification {
        background-color: black;
        width: 45rem;
        text-decoration-color: white;
        border-radius: 0px;
    }

    .ap-notification .ap-notification-description {
        color: white;
        margin-left: 10rem;
    }

    .ap-notification .Appkit4-icon {
        color: white;
    }

    .ap-notifications-bottomCenter {
        bottom: 0rem;
        z-index: 999;
    }

    .ap-notification-icon-close {
        position: absolute;
        right: unset !important;
    }

    .ap-notification-icon-close:hover {
        background-color: #2d2d2d !important;
    }

    .notification-description {
        width: 10rem;
    }

    .ap-loading {
        margin-left: 5.5rem;
    }
`;

interface IAllocationLoadingNotificationProps {
    allocationId: number;
    fileName: string;
    queryClient: QueryClient;
    successNotification: (response: AllocationStatusResponse) => JSX.Element;
    failedNotification: () => JSX.Element;
}

const AllocationLoadingNotification = (
    props: IAllocationLoadingNotificationProps
) => {
    const showToast = useToast();
    const { l } = useLocale();

    const [hideLoadingToast, setHideLoadingToast] = useState(false);

    useEffect(() => {
        const refetchTime = 30000;

        const intervalId = setInterval(async () => {
            try {
                await props.queryClient.refetchQueries(
                    getGetV1AllocationStatusAllocationIdQueryKey(
                        props.allocationId
                    )
                );

                const updatedQueryResult =
                    props.queryClient.getQueryData<AllocationStatusResponse>(
                        getGetV1AllocationStatusAllocationIdQueryKey(
                            props.allocationId
                        )
                    );

                if (updatedQueryResult?.status === AllocationStatus.NUMBER_3) {
                    await handleNotificationClose().then(() => {
                        toaster.notify(
                            props.successNotification(updatedQueryResult),
                            {
                                position: 'bottomRight',
                            }
                        );
                    });
                }

                if (updatedQueryResult?.status === AllocationStatus.NUMBER_4) {
                    await handleNotificationClose().then(() => {
                        toaster.notify(props.failedNotification(), {
                            position: 'bottomRight',
                        });
                    });
                }
            } catch (error: any) {
                showToast(
                    l('_error'),
                    error.response?.data || error.message,
                    'error'
                );
            }
        }, refetchTime);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const handleNotificationClose = async () => {
        toaster.closeAll();
        await new Promise((resolve) => setTimeout(resolve, 1000));
    };

    return (
        <StyledAllocationLoading>
            <Notification
                onClose={() => setHideLoadingToast(true)}
                className={hideLoadingToast ? 'hidden' : ''}
                title=""
                message={
                    <div>
                        <div
                            style={{
                                marginBottom: '0.5rem',
                                marginRight: '0.5rem',
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <p
                                style={{
                                    marginRight: '0.5rem',
                                    marginBottom: 'unset',
                                }}
                            >
                                {`Allocation ${props.fileName} in progress`}
                            </p>
                        </div>
                        <Loading
                            loadingType="linear"
                            indeterminate={true}
                            compact={false}
                        />
                    </div>
                }
            />
        </StyledAllocationLoading>
    );
};

export default AllocationLoadingNotification;
