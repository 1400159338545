// https://medium.com/hypersphere-codes/detecting-system-theme-in-javascript-css-react-f6b961916d48

import { useState, useEffect } from 'react';

export type ThemeMode = 'dark' | 'light';

export function useBrowserDarkMode(): ThemeMode {
    const [browserDark, updateBrowserDark] = useState(() => {
        return window.matchMedia('(prefers-color-scheme: dark)').matches;
    });
    useEffect(() => {
        const mqListener = (e: MediaQueryListEvent) => {
            updateBrowserDark(e.matches);
        };
        const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');
        darkThemeMq.addEventListener('change', mqListener);
        return () => darkThemeMq.removeEventListener('change', mqListener);
    }, []);
    return browserDark ? 'dark' : 'light';
}

export function useStoredDarkMode(): ThemeMode | null {
    const [storedMode, updateStoredMode] = useState(() => {
        return window.localStorage.getItem('data-mode') as ThemeMode | null;
    });
    useEffect(() => {
        const storageListener = (ev: StorageEvent) => {
            if (ev.key == 'data-mode') {
                updateStoredMode(ev.newValue as ThemeMode | null);
            }
        };
        window.addEventListener('storage', storageListener);
        return () => {
            window.removeEventListener('storage', storageListener);
        };
    }, []);
    return storedMode;
}

/**
 * Get the current colour mode
 * @returns the currently active dark/light mode
 */
export function useActiveDarkMode(): ThemeMode {
    const browser = useBrowserDarkMode();
    const stored = useStoredDarkMode();
    return stored ?? browser;
}

/**
 * Get whether the current theme is the dark theme.
 */
export function useIsDarkModeActive(): boolean {
    return useActiveDarkMode() == 'dark';
}

/**
 * Apply the detected or chosen colour theme for Appkit
 */
export function useApplyDarkMode() {
    const activeDarkMode = useActiveDarkMode();
    useEffect(() => {
        document.body.setAttribute('data-mode', activeDarkMode);
        return () => document.body.removeAttribute('data-mode');
    }, [activeDarkMode]);
}

/**
 * A wrapper component to apply dark mode to the body.
 * Used for testing - `useApplyDarkMode` is normally
 * called in `App`, but some tests want to confirm that
 * `useApplyDarkMode` performs the expected operation.
 */
export function AppliedDarkMode({
    children,
}: {
    children: React.ReactElement;
}): React.ReactElement {
    useApplyDarkMode();
    return children;
}
