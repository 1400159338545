import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Navigation,
    NavigationItem,
} from '@appkit4/react-components/navigation';
import { AppRoutes } from '../../Constants';
import { useGetV1IdentityUserInfo } from '../../api/generated/endpoint';

import './Sidebar.scss';

interface ExtendedNavigationItem extends NavigationItem {
    pathname: string;
}

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const navList: ExtendedNavigationItem[] = [
        {
            name: 'Home',
            prefixIcon: 'home',
            pathname: '/',
        },
        {
            name: 'New Allocation',
            prefixIcon: 'cogs',
            pathname: '/allocation/new',
        },
        {
            name: 'Allocation History',
            prefixIcon: 'folder-closed',
            pathname: '/allocation/history',
        },
        {
            name: 'Dashboard',
            prefixIcon: 'bargraph',
            pathname: '/dashboard',
        },
    ];

    const [activeIndex, setActiveIndex] = React.useState(0);

    const [collapsed, setCollapsed] = React.useState(true);

    const onCollapseEvent = (collapsed: boolean) => {
        setCollapsed(collapsed);
    };

    React.useEffect(() => {
        const newActiveIndex = navList.findIndex((item) => {
            return location.pathname === item.pathname;
        });
        setActiveIndex(newActiveIndex !== -1 ? newActiveIndex : 0);
    }, [location.pathname, navList]);

    const redirect = (
        event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item: NavigationItem
    ) => {
        if (item.name === 'Home') {
            navigate(AppRoutes.HomePage, { replace: true });
        } else if (item.name === 'New Allocation') {
            navigate(AppRoutes.NewAllocation, { replace: true });
        } else if (item.name === 'Allocation History') {
            navigate(AppRoutes.AllocationHistory, { replace: true });
        } else if (item.name === 'Dashboard') {
            navigate(AppRoutes.AllocationDashboard, { replace: true });
        }
    };

    const { data: userData } = useGetV1IdentityUserInfo({
        query: {
            staleTime: Infinity,
        },
    });

    return (
        <>
            {userData && (
                <>
                    <div className="ap-navigation-wrapper">
                        <Navigation
                            width={280}
                            hasHeader={false}
                            hasFooter={true}
                            hasLogo={false}
                            showTooltip={true}
                            navList={navList}
                            onClickItem={redirect}
                            collapsed={collapsed}
                            selectedIndex={activeIndex !== -1 ? activeIndex : 0}
                            solid={true}
                            onClickCollapseEvent={onCollapseEvent}
                        ></Navigation>
                    </div>
                </>
            )}
        </>
    );
};

export default Sidebar;
